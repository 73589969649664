import { UserExtended, UserType } from '../../types';

export const getAvatarProps = (userData: UserExtended) => {
  const fullName = `${userData.identity.firstName} ${userData.identity.lastName}`;

  if (userData.type === UserType.CompanyOwner) {
    return {
      avatarUrl: userData.businessDetails?.avatarUrl,
      name: userData.businessDetails?.name ?? fullName,
    };
  }

  return {
    avatarUrl: userData.identity.avatarUrl,
    name: fullName,
  };
};
