import { Box, Group, Menu, rem, Text } from '@mantine/core';
import { IconKey, IconLogout } from '@tabler/icons-react';
import { FC } from 'react';

import UserAvatar from '../../../../components/common/UserAvatar';
import { ButtonId } from '../../../../constants/button-ids';
import { getButtonLabel } from '../../../../i18n/helpers/buttons';
import { vars } from '../../../../theme';
import { trackEvent } from '../../../../utils/analytics';
import { ModalId, useModal } from '../../../../utils/modal';
import { useUser, useUserData } from '../../api/queries/useUser';
import { setUser } from '../../utils/cognito';
import { getAvatarProps } from './helpers';
import classes from './styles.css';

const highlightColor = vars.colors.branding[6];

const UserMenu: FC = () => {
  const { openModal } = useModal();
  const { data: user } = useUser();
  const { data: userFull } = useUserData();

  const menuItems = [
    {
      Icon: IconKey,
      label: getButtonLabel(ButtonId.PasswordChange),
      onClick: () => {
        openModal(ModalId.ChangePassword);
      },
    },
    {
      Icon: IconLogout,
      label: getButtonLabel(ButtonId.Logout),
      onClick: () => {
        trackEvent('Log Out');
        setUser(null, true);
      },
    },
  ];

  if (!user || !userFull) {
    return (
      <Group
        align="center"
        className={classes.wrapper}
        justify="center"
        pos="relative"
      >
        <UserAvatar
          autoContrast={true}
          borderColor={vars.colors.dark[4]}
          color="branding.9"
          firstName=""
          lastName=""
          loading={true}
          pos="relative"
          size={38}
          variant="gradient"
        />
      </Group>
    );
  }

  const avatarProps = getAvatarProps(userFull);

  return (
    <Box mx="auto" pos="relative">
      <Menu keepMounted={false} position="bottom-start" shadow="md">
        <Menu.Target>
          <Group
            align="center"
            className={classes.wrapper}
            justify="center"
            pos="relative"
          >
            <UserAvatar
              autoContrast={true}
              borderColor={vars.colors.branding[8]}
              color="branding.9"
              name={avatarProps.name}
              pos="relative"
              shadowColor={highlightColor}
              size={38}
              src={avatarProps.avatarUrl}
              variant="gradient"
            />
          </Group>
        </Menu.Target>
        <Menu.Dropdown w={rem(200)}>
          {menuItems.map(({ Icon, label, onClick }) => (
            <Menu.Item
              c="dark"
              key={label}
              leftSection={
                <Icon
                  color={vars.colors.dark[6]}
                  style={{ height: rem(18), width: rem(18) }}
                />
              }
              onClick={onClick}
            >
              <Text c="dark" size="sm">
                {label}
              </Text>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};

export default UserMenu;
