import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import http from '../../../../../../config/http';
import { MutationDelete } from '../../../../../../constants/mutation-keys';
import { useParamClientId } from '../../../../../../router/hooks/path-params';
import { getDataById, removeDataById } from '../../../../../../utils/array';
import { ClientRoutineFull, ClientRoutineList } from '../../../types';
import { getClientRoutineFull, getClientRoutines } from '../../cache/getters';
import { setClientRoutineAll } from '../../cache/mutations';
import { setClientRoutineFull, setClientRoutines } from '../../cache/setters';
import {
  cancelClientRoutineQuery,
  invalidateClientRoutine,
} from '../../queries/useClientRoutineFullQuery';
import {
  cancelClientRoutinesQuery,
  invalidateClientRoutines,
} from '../../queries/useClientRoutinesQuery';

interface TContext {
  routine: ClientRoutineFull;
  routinesAll: ClientRoutineList[];
}

interface TApi {
  routineId: string;
  workoutId: string;
}

async function deleteClientWorkout({ workoutId }: TApi) {
  await http.delete(`/client-workouts/${workoutId}`);
}

export const useClientWorkoutDelete = () => {
  const clientId = useParamClientId();

  return useMutation<void, AxiosError, TApi, TContext | undefined>({
    mutationFn: deleteClientWorkout,
    mutationKey: [MutationDelete.ClientWorkout],
    onMutate: async ({ routineId, workoutId }) => {
      const routine = getClientRoutineFull(routineId);

      if (routine === null) {
        return;
      }

      await Promise.all([
        cancelClientRoutineQuery(routineId),
        cancelClientRoutinesQuery(routine.clientId),
      ]);

      const updated = {
        ...routine,
        workouts: removeDataById(routine.workouts, workoutId),
      };

      setClientRoutineAll(routineId, updated);

      const routinesAll =
        getClientRoutines(routine?.clientId ?? clientId) ?? [];

      return { routine, routinesAll };
    },
    // eslint-disable-next-line complexity
    onSettled(_, error, { routineId }, context) {
      const { routine, routinesAll } = context ?? {};

      if (error) {
        if (context === undefined) {
          return;
        }

        const { routine, routinesAll } = context ?? {};

        setClientRoutineFull(routineId, routine);

        if (routine.clientId) {
          setClientRoutines(routine.clientId, routinesAll);
        }
      }

      const _clientId =
        routine?.clientId ??
        getDataById(routinesAll, routineId)?.clientId ??
        clientId;

      if (_clientId) {
        invalidateClientRoutines(_clientId);
      }

      invalidateClientRoutine(routineId);
    },
  });
};
