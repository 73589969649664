import { IconPoint } from '@tabler/icons-react';
import { Location } from 'react-router-dom';

import { PageId } from '../../../../../constants/page-ids';
import { translate } from '../../../../../i18n';
import { getPageLabel } from '../../../../../i18n/helpers/page';
import { getHashWorkoutId } from '../../../../../router/helpers/hash';
import { replacePathFragmentUrl } from '../../../../../router/paths/back-paths';
import { PATH_FRAGMENT__WORKOUT_ID } from '../../../../../router/paths/path-fragments';
import { CLIENT_WORKOUT__STATUS_COLORS } from '../../../@client/constants';
import { WorkoutStatus } from '../../../@client/types';
import { RoutineNavigationPartial } from './types';

const getDrawerWorkoutUrl = (
  currentPath: null | string,
  paramWorkoutId: null | string,
  workoutId: string,
  location: Location,
) => {
  return paramWorkoutId === null
    ? getHashWorkoutId(workoutId)
    : replacePathFragmentUrl(currentPath, location, {
        pathFragment: PATH_FRAGMENT__WORKOUT_ID,
        replaceWith: `${workoutId}`,
      });
};

export const getBackButtonText = (
  paramClientId: null | string,
  paramWorkoutId: null | string,
) => {
  if (paramWorkoutId !== null) {
    return getPageLabel(PageId.ClientsRoutineDetails);
  }

  if (paramClientId !== null) {
    return getPageLabel(PageId.ClientsDetails);
  }

  return translate('common.back');
};

export const getRoutineWorkoutsSidebarItems = (
  currentPath: null | string,
  workouts: RoutineNavigationPartial['workouts'],
  hashWorkoutId: null | string,
  paramWorkoutId: null | string,
  location: Location,
) => {
  return workouts.map(workout => {
    const props = workout.status
      ? {
          iconColor:
            workout.status === WorkoutStatus.None
              ? undefined
              : CLIENT_WORKOUT__STATUS_COLORS[workout.status],
          iconTooltip: translate(`workout.status.${workout.status}.label`),
        }
      : {};

    return {
      Icon: IconPoint,
      iconSize: 14,
      isActive: hashWorkoutId === workout.id || paramWorkoutId === workout.id,
      label: workout.name,
      to: getDrawerWorkoutUrl(
        currentPath,
        paramWorkoutId,
        workout.id,
        location,
      ),
      ...props,
    };
  });
};
