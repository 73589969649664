import { Portal } from '@mantine/core';
import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import CustomBreadcrumbs from '../../../components/common/Breadcrumbs';
import { PORTAL__HEADER_CONTENT } from '../../../constants/portals';
import useIsMobile from '../../../hooks/useIsMobile';
import useBreadcrumbs from './useBreadcrumbs';

const Breadcrumbs: FC = () => {
  const breadcrumbs = useBreadcrumbs();
  return (
    <Portal target={PORTAL__HEADER_CONTENT}>
      <CustomBreadcrumbs items={breadcrumbs} />
    </Portal>
  );
};

const TemplateWorkoutsLayout: FC = () => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile === false && <Breadcrumbs />}
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default TemplateWorkoutsLayout;
