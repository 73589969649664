import { Image, rem } from '@mantine/core';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { RAIL_DRAWER_WIDTH } from '../../../constants/preferences';
import { createLocationState, routes } from '../../../router/routes';

const offset = rem(6);

const negativeMargin = `calc(${offset} * -1)`;
const width = `calc(100% + ${offset} + ${offset})`;

const Logo: FC = () => {
  return (
    <Link
      state={createLocationState()}
      style={{
        marginLeft: negativeMargin,
        marginRight: negativeMargin,
        maxWidth: RAIL_DRAWER_WIDTH,
        textDecoration: 'none',
        width,
      }}
      to={{ pathname: routes.home }}
    >
      <Image
        height="auto"
        maw={52}
        rel="preload"
        src="/assets/logo-4.webp"
        w={52}
      />
    </Link>
  );
};

export default Logo;
