import { EXERCISE_CATEGORY_ORDER } from '../../../constants/preferences';
import {
  isCompanyAdmin,
  isCompanyCoach,
  isCompanyOwner,
} from '../../user/utils';

export const getCategoriesToShow = () => {
  const canViewCompanyExercises =
    isCompanyAdmin() || isCompanyCoach() || isCompanyOwner();

  const categoriesToShow = EXERCISE_CATEGORY_ORDER.filter(category => {
    if (canViewCompanyExercises === false && category === 'company') {
      return false;
    }

    return true;
  });

  return categoriesToShow;
};
