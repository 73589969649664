import {
  browserTracingIntegration,
  init,
  replayIntegration,
  setUser,
} from '@sentry/react';

import envs from '../config/envs';
import { User } from '../features/user/types';
import { gerRelease, isLocal, isProduction } from './environment';

export const initSentry = () => {
  if (isLocal()) {
    return;
  }

  init({
    _experiments: {
      // profilesSampleRate: isDev ? 1.0 : 0.2,
      profilesSampleRate: 1.0,
    },
    // We recommend adjusting this value in production.
    debug: isProduction() === false,
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
    dsn: envs.sentry.dsn,
    environment: isProduction() ? 'production' : 'development',
    integrations: [browserTracingIntegration(), replayIntegration()],
    release: gerRelease(),
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/my.strenco\.app/],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
  });
};

export const setSentryUser = (user: Pick<User, 'id' | 'type'>) => {
  if (isLocal()) {
    return;
  }

  setUser({
    id: user.id,
    type: user.type,
  });
};

export const clearSentryUser = () => {
  setUser(null);
};
