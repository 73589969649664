/** @file Contains setters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { removeDataById, upsertData } from '../../../../../utils/array';
import { Exercise } from '../../../types';
import { getExercises } from './getters';
import { setExercise, setExercises } from './setters';

/**
 * Inserts new or updates exercise if exists
 * @param exercise The exercise we want to set
 * @param qc       QueryClient
 */
export const upsertExercise = (
  exercise: Exercise,
  qc: QueryClient = queryClient,
) => {
  setExercise(exercise);
  const exercises = getExercises(qc);

  if (exercises === null) {
    return;
  }

  const updated = upsertData(exercises, exercise);
  setExercises(updated);
};

/**
 * Removes exercise from cache
 * @param exerciseId Id of exercise we want to remove
 * @param qc         QueryClient
 */
export const removeExercise = (
  exerciseId: string,
  qc: QueryClient = queryClient,
) => {
  const exercisesAll = getExercises(qc);

  if (exercisesAll === null) {
    return;
  }

  qc.setQueryData(QueryKey.Exercise(exerciseId), undefined);
  setExercises(removeDataById(exercisesAll, exerciseId));
};
