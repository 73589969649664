import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { PageId } from '../../constants/page-ids';
import { getPageLabel } from '../../i18n/helpers/page';
import useCurrentPath from '../../router/hooks/useCurrentPath';
import {
  PATH__SETTINGS__BUSINESS,
  PATH__SETTINGS__PROFILE,
  PATH__SETTINGS__SUBSCRIPTIONS,
} from '../../router/paths/paths';

const pagesMap: Record<string, PageId> = {
  [PATH__SETTINGS__BUSINESS]: PageId.SettingsBusiness,
  [PATH__SETTINGS__PROFILE]: PageId.SettingsProfile,
  [PATH__SETTINGS__SUBSCRIPTIONS]: PageId.SettingsSubscriptions,
};

const useBreadcrumbs = (): BreadcrumbItem[] => {
  const currentPath = useCurrentPath();
  const items: BreadcrumbItem[] = [];
  const pageId = currentPath !== null ? pagesMap[currentPath] : null;

  if (pageId !== null && currentPath !== null) {
    items.push({
      content: getPageLabel(pageId),
      href: currentPath,
      isLoading: false,
      // label: null,
    });
  }

  return items;
};

export default useBreadcrumbs;
