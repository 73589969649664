import { Location } from 'react-router-dom';

import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { PageId } from '../../constants/page-ids';
import { translate } from '../../i18n';
import { getPageLabel } from '../../i18n/helpers/page';
import { IdentityList } from '../../types';
import { getBackUrl } from '../paths/back-paths';
import {
  PATH_FRAGMENT__ADMIN_ID,
  PATH_FRAGMENT__COACH_ID,
} from '../paths/path-fragments';
import {
  PATH__BUSINESS__ADMINS,
  PATH__BUSINESS__CLIENTS,
  PATH__BUSINESS__COACHES,
} from '../paths/paths';
import { routes } from '../routes';

export const getBreadcrumbBusiness = (
  currentPath: null | string,
): BreadcrumbItem | null => {
  switch (true) {
    case currentPath?.startsWith(PATH__BUSINESS__ADMINS): {
      return {
        content: getPageLabel(PageId.Admins),
        href: routes.business.admins.root(),
        isLoading: false,
      };
    }
    case currentPath?.startsWith(PATH__BUSINESS__CLIENTS): {
      return {
        content: getPageLabel(PageId.Clients),
        href: routes.business.clients.root(),
        isLoading: false,
      };
    }
    case currentPath?.startsWith(PATH__BUSINESS__COACHES): {
      return {
        content: getPageLabel(PageId.Coaches),
        href: routes.business.coaches.root(),
        isLoading: false,
      };
    }
    default: {
      return null;
    }
  }
};

export const getBreadcrumbBusinessCoach = <
  T extends { id: string; identity: IdentityList },
>(
  coach: null | T | undefined = null,
  currentPath: null | string,
  location: Location | null = null,
) => {
  return {
    content: `${coach?.identity?.firstName} ${coach?.identity.lastName}`,
    href: getBackUrl(currentPath, location, {
      toPathFragment: PATH_FRAGMENT__COACH_ID,
    }),
    isLoading: coach === null,
    label: translate('business.coaches_one'),
  };
};

export const getBreadcrumbBusinessAdmin = <
  T extends { id: string; identity: IdentityList },
>(
  admin: null | T | undefined = null,
  currentPath: null | string,
  location: Location | null = null,
) => {
  return {
    content: `${admin?.identity?.firstName} ${admin?.identity.lastName}`,
    href: getBackUrl(currentPath, location, {
      toPathFragment: PATH_FRAGMENT__ADMIN_ID,
    }),
    isLoading: admin === null,
    label: translate('business.admins_one'),
  };
};

export const getBreadcrumbBusinessFacility = <
  T extends { id: string; name: string },
>(
  facility: null | T | undefined = null,
) => {
  return {
    content: facility?.name,
    href: routes.business.facilities.byId(facility?.id ?? ''),
    isLoading: facility === null,
    label: translate('business.facilities_one'),
  };
};
