/** @file Contains setters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { Exercise } from '../../../types';

/**
 * Sets cached exercise
 * @param exercise Exercise we want to set
 * @param qc       QueryClient
 */
export const setExercise = (
  exercise: Exercise,
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData<Exercise>(QueryKey.Exercise(exercise.id), exercise);
};

/**
 * Sets the exercises for specified
 * @param exercises Exercises we want to set
 * @param qc        QueryClient
 */
export const setExercises = (
  exercises: Exercise[],
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData<Exercise[]>(QueryKey.Exercises(), exercises);
};
