import { UserType } from '../../../features/user/types';

const coachAndBusiness = new Set([
  UserType.Coach,
  UserType.CompanyOwner,
  UserType.CompanyAdmin,
  UserType.CompanyCoach,
]);

const subscriptions = new Set([
  UserType.Personal,
  UserType.Coach,
  UserType.CompanyOwner,
]);

const businessEditors = new Set([UserType.CompanyAdmin, UserType.CompanyOwner]);

const protectedRoutes = {
  business: businessEditors,
  businessClients: businessEditors,
  businessCoaches: businessEditors,
  businessFacilities: businessEditors,
  clients: coachAndBusiness,
  settingsBusiness: new Set([UserType.CompanyOwner]),
  settingsSubscriptions: subscriptions,
  templateRoutines: coachAndBusiness,
};

export default protectedRoutes;
