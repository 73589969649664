import translationsAlert from './alert';
import translationsApiErrors from './api-errors';
import translationsAria from './aria';
import { translationsAuth } from './auth';
import translationsBusiness from './business';
import translationsButtons from './buttons';
import translationsClient from './client';
import translationsCommon from './common';
import translationsConfirmation from './confirm';
import translationsCountries from './country-codes';
import translationsErrorPages from './error-pages';
import translationsExercise from './exercise';
import translationsExerciseInstructions from './exercise-instructions';
import translationForm from './form';
import translationFormField from './form-field';
import translationFormFieldValidationError from './form-field-validation-error';
import translationsPages from './pages';
import translationProduct from './product';
import translationsRoutine from './routine';
import translationsToast from './toasts';
import translationsWorkout from './workout';

const en = {
  alert: translationsAlert,
  aria: translationsAria,
  auth: translationsAuth,
  business: translationsBusiness,
  button: translationsButtons,
  client: translationsClient,
  common: translationsCommon,
  confirm: translationsConfirmation,
  country: translationsCountries,
  errorPages: translationsErrorPages,
  exercise: translationsExercise,
  exerciseInstruction: translationsExerciseInstructions,
  form: translationForm,
  formField: translationFormField,
  formFieldValidationError: translationFormFieldValidationError,
  page: translationsPages,
  product: translationProduct,
  routine: translationsRoutine,
  toast: { ...translationsToast, ...translationsApiErrors },
  workout: translationsWorkout,
};

export default en;
