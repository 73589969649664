import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { UserStatus } from '../../../../types';
import {
  Client,
  ClientListItem,
  CoachingFormat,
  UpdateClientAttributes,
} from '../../../../types/clients';
import { getClientFromList, getClientFull, getClients } from './getters';
import { setClientFull, setClients } from './setters';

const getValues = (
  data: Partial<UpdateClientAttributes>,
  clientFull: Client | null,
  clientFromList: ClientListItem | null,
) => {
  const identity = clientFromList?.identity ?? clientFull?.identity;

  const {
    coachingFormat = clientFromList?.coachingFormat ??
      clientFull?.coachingFormat,
    firstName = identity?.firstName,
    lastName = identity?.lastName,
    status = clientFromList?.status ?? clientFull?.status,
  } = data;

  return { coachingFormat, firstName, lastName, status } as {
    coachingFormat: CoachingFormat;
    firstName: string;
    lastName: string;
    status: UserStatus;
  };
};

export const updateClient = (
  clientId: string,
  data: Partial<UpdateClientAttributes>,
  qc: QueryClient = queryClient,
) => {
  const clientFull = getClientFull(clientId);
  const clientsAll = getClients();
  const clientFromListList = getClientFromList(clientId);

  const { coachingFormat, firstName, lastName, status } = getValues(
    data,
    clientFull,
    clientFromListList,
  );

  if (clientFromListList !== null && clientsAll !== null) {
    setClients(
      clientsAll.map(client => {
        if (client.id === clientId) {
          return {
            ...client,
            coachingFormat,
            firstName,
            fullName: `${firstName} ${lastName}`,
            lastName,
            status,
          };
        }

        return client;
      }),
      qc,
    );
  }

  if (clientFull !== null) {
    setClientFull(
      clientId,
      {
        ...clientFull,
        identity: { ...clientFull.identity, firstName, lastName },
        status,
      },
      qc,
    );
  }
};

export const deleteClient = (
  clientId: string,
  qc: QueryClient = queryClient,
) => {
  const clientsAll = getClients();
  const clientFull = getClientFull(clientId);

  if (clientFull !== null) {
    setClientFull(clientId, undefined);
    qc.removeQueries({ queryKey: QueryKey.Client(clientId) });
  }

  if (clientsAll !== null) {
    setClients(clientsAll.filter(client => client.id !== clientId));
  }
};
