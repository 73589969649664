import { Button, ButtonProps, Center, Stack, Text, Title } from '@mantine/core';
import { IconExclamationCircleFilled } from '@tabler/icons-react';
import { ButtonHTMLAttributes, FC } from 'react';

import { vars } from '../../../theme';

interface Props {
  buttonProps: ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;
  description: string;
  title: string;
}

const ErrorContent: FC<Props> = ({ buttonProps, description, title }) => {
  return (
    <Center component="section" h="100%" py="md">
      <Stack align="center">
        <IconExclamationCircleFilled color={vars.colors.red[6]} size={64} />
        <Title c="dark.6" component="h1" mt="lg" ta="center">
          {title}
        </Title>
        <Text c="dark.2" ta="center">
          {description}
        </Text>
        <Button {...buttonProps} fullWidth mt="md" />
      </Stack>
    </Center>
  );
};

export default ErrorContent;
