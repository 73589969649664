import { useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../config/http';
import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { BusinessUserFull } from '../../../../types/business';
import { canViewBusinessAdmin, canViewBusinessCoach } from '../../permissions';

type UserId = null | string | undefined;

const fetchBusinessAdminUser = async (userId: UserId) => {
  if (!userId) {
    return;
  }

  const { data } = await http.get<ApiResponse<BusinessUserFull>>(
    `/business-admins/${userId}`,
  );

  return data.data;
};

const fetchBusinessCoachUser = async (userId: UserId) => {
  if (!userId) {
    return;
  }

  const { data } = await http.get<ApiResponse<BusinessUserFull>>(
    `/business-coaches/${userId}`,
  );

  return data.data;
};

export const useBusinessAdminQuery = (userId: UserId) => {
  return useQuery({
    enabled: typeof userId === 'string' && canViewBusinessAdmin(),
    queryFn: () => fetchBusinessAdminUser(userId),
    queryKey: QueryKey.BusinessAdminUser(userId),
  });
};

export const useBusinessCoachQuery = (userId: UserId) => {
  return useQuery({
    enabled: typeof userId === 'string' && canViewBusinessCoach(),
    queryFn: () => fetchBusinessCoachUser(userId),
    queryKey: QueryKey.BusinessCoachUser(userId),
  });
};

// Cache utils

export const invalidateBusinessAdmin = (userId: UserId) =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.BusinessAdminUser(userId),
  });

export const cancelBusinessAdminQueries = (userId: UserId) =>
  queryClient.cancelQueries({
    queryKey: QueryKey.BusinessAdminUser(userId),
  });

export const invalidateBusinessCoach = (userId: UserId) =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.BusinessCoachUser(userId),
  });

export const cancelBusinessCoachQueries = (userId: UserId) =>
  queryClient.cancelQueries({
    queryKey: QueryKey.BusinessCoachUser(userId),
  });
