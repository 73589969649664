import { useEffect } from 'react';

type ErrorEventHandler = (event: ErrorEvent) => boolean | void;
type ChunkErrorEventHandler = (event: Event) => boolean | void;

declare global {
  interface Window {
    onchunkerror?: ChunkErrorEventHandler;
  }
}

const useChunkErrorBoundary = () => {
  useEffect(() => {
    const originalError: ErrorEventHandler | null =
      window.onerror as ErrorEventHandler;
    const originalChunkError: ChunkErrorEventHandler | null =
      window.onchunkerror || null;

    const errorHandler: ErrorEventHandler = (event: ErrorEvent) => {
      // Check if the error is related to chunk loading
      if (
        event.message?.includes('Loading chunk') ||
        event.message?.includes('Loading CSS chunk') ||
        event.message?.includes('Failed to fetch dynamically imported module')
      ) {
        window.location.reload();
        return false; // Prevent default error handling
      }

      if (originalError) {
        return originalError(event);
      }
    };

    // @ts-ignore
    window.onerror = errorHandler;
    window.onchunkerror = (event: Event) => {
      window.location.reload();
      if (originalChunkError) {
        return originalChunkError(event);
      }
    };

    return () => {
      // @ts-ignore
      window.onerror = originalError;
      // @ts-ignore
      window.onchunkerror = originalChunkError;
    };
  }, []);
};

export default useChunkErrorBoundary;
