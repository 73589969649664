import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';

import { ButtonId } from '../../constants/button-ids';
import {
  MutationDelete,
  MutationUpdateConfirm,
} from '../../constants/mutation-keys';
import { translate } from '../../i18n';
import { getButtonLabel } from '../../i18n/helpers/buttons';

interface Props {
  key: MutationDelete | MutationUpdateConfirm;
  onCancel?: () => void;
  onConfirm: () => void;
}

export const confirmDanger = ({
  key,
  onCancel = () => console.log(''),
  onConfirm,
}: Props) => {
  modals.openConfirmModal({
    centered: true,
    children: <Text size="sm">{translate(`confirm.${key}.text`)}</Text>,
    confirmProps: { color: 'red' },
    groupProps: { justify: 'flex-end' },
    labels: {
      cancel: getButtonLabel(ButtonId.Cancel),
      confirm: <>{translate(`confirm.${key}.confirm`)}</>,
    },
    modalId: key,
    onCancel,
    onConfirm,
    size: 'md',
    title: (
      <Text fw="700" size="lg">
        {translate(`confirm.${key}.title`)}
      </Text>
    ),
  });
};
