import { Button, rem } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import useBackNavigation from '../../../hooks/useGetBackUrl';

interface Props {
  backUrl: null | string;
}

const DrawerBackButton: FC<PropsWithChildren<Props>> = ({
  backUrl,
  children,
}) => {
  const navigate = useNavigate();
  const { goBack } = useBackNavigation();

  const handleBack = () => {
    if (backUrl === null) {
      goBack();
    } else {
      navigate(backUrl);
    }
  };

  return (
    <Button
      color="gray.1"
      leftSection={
        <IconArrowLeft style={{ height: rem(16), width: rem(16) }} />
      }
      onClick={handleBack}
      size="compact-sm"
      variant="subtle"
    >
      {children}
    </Button>
  );
};

export default DrawerBackButton;
