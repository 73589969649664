/** @file Contains getters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { Exercise } from '../../../types';

/**
 * Retrieves exercises from the cache
 * @param qc QueryClient
 * @returns  Exercises from cache
 */
export const getExercises = (
  qc: QueryClient = queryClient,
): Exercise[] | null => {
  const data = qc.getQueryData<Exercise[]>(QueryKey.Exercises());
  return data ?? null;
};

/**
 * Retrieves exercise by id from the cache
 * @param exerciseId The id of the exercise
 * @param qc         QueryClient
 * @returns          Exercise full object
 */
export const getExerciseById = (
  exerciseId: null | string,
  qc: QueryClient = queryClient,
): Exercise | null => {
  if (exerciseId === null) {
    return null;
  }

  const data = qc.getQueryData<Exercise>(QueryKey.Exercise(exerciseId));
  return data ?? null;
};
