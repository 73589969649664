import { Group, Menu, MenuProps, rem, Text, Tooltip } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronUp,
  TablerIcon,
} from '@tabler/icons-react';
import React, { FC, Fragment, useState } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';
import { ActionIconButton } from '../../common/ActionIcons';

export interface MenuItem {
  disabled?: boolean;
  Icon?: TablerIcon;
  isLoading?: boolean;
  isSectionLabel?: boolean;
  label: string;
  onClick?: () => void;
}

interface Props {
  isLoading?: boolean;
  label?: string;
  menuItems: MenuItem[];
  position?: MenuProps['position'];
  tooltip?: string;
  trigger?: React.ReactNode;
  withArrows?: boolean;
}

const DefaultTrigger: FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  const isMobile = useIsMobile();

  return (
    <ActionIconButton
      colorVariant="dark"
      icon="dotsHorizontal"
      loading={isLoading}
      size={isMobile ? 'md' : 'lg'}
      tooltip={null}
    />
  );
};

const CustomMenu: FC<Props> = props => {
  const {
    label,
    menuItems,
    position = 'left-start',
    tooltip,
    trigger = <DefaultTrigger isLoading={props.isLoading} />,
    withArrows,
  } = props;

  const [opened, setOpened] = useState(false);

  const arrows = withArrows ? (
    <>
      {opened ? (
        <IconChevronUp size={16} strokeWidth={'1.5'} />
      ) : (
        <IconChevronDown size={16} strokeWidth={'1.5'} />
      )}
    </>
  ) : null;

  const TriggerComponent = trigger ? (
    <Group>
      {trigger}
      {arrows}
    </Group>
  ) : (
    <Group>
      {label && <Text>{label}</Text>}
      {arrows}
    </Group>
  );

  const targetElement = tooltip ? (
    <Tooltip label={tooltip}>
      {<Menu.Target>{TriggerComponent}</Menu.Target>}
    </Tooltip>
  ) : (
    TriggerComponent
  );

  return (
    <Menu
      onClose={() => setOpened(false)}
      onOpen={() => setOpened(true)}
      opened={opened}
      position={position}
      shadow="md"
    >
      <Menu.Target>{targetElement}</Menu.Target>
      <Menu.Dropdown miw={rem(120)}>
        {menuItems.map(
          ({ disabled, Icon = null, isSectionLabel, label, onClick }) =>
            isSectionLabel ? (
              <Fragment key={label}>
                <Menu.Divider />
                <Menu.Label>{label}</Menu.Label>
              </Fragment>
            ) : (
              <Menu.Item
                disabled={disabled}
                key={label}
                leftSection={Icon !== null && <Icon size={16} stroke={1.5} />}
                onClick={e => {
                  e.stopPropagation();
                  onClick?.();
                }}
                px={4}
              >
                {label}
              </Menu.Item>
            ),
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default CustomMenu;
