import { Group, Portal, rem } from '@mantine/core';
import { FC } from 'react';

import HeaderMobile from '../../../../components/layout/HeaderMobile';
import SearchInputUrl from '../../../../components/ui/forms/SearchInputUrl';
import { FieldId } from '../../../../constants/forms/form-fields';
import { PageId } from '../../../../constants/page-ids';
import { PORTAL__HEADER_ACTIONS } from '../../../../constants/portals';
import BusinessSecondaryNavigation from '../../../../features/business/components/BusinessLayout/BusinessSecondaryNavigation';
import BusinessClientsHeaderAction from '../../../../features/business/components/ClientsHeaderAction';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getFieldPlaceholder } from '../../../../i18n/helpers/forms';
import { getPageLabel } from '../../../../i18n/helpers/page';

const BusinessClientsLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={BusinessClientsHeaderAction}
        title={getPageLabel(PageId.Clients)}
      />
    );
  }

  return (
    <>
      <Portal target={PORTAL__HEADER_ACTIONS}>
        <Group flex={1}>
          <SearchInputUrl
            flex={1}
            placeholder={getFieldPlaceholder(FieldId.ClientsSearch)}
            w={rem(300)}
          />
        </Group>
      </Portal>
      <BusinessSecondaryNavigation />
    </>
  );
};

export default BusinessClientsLayout;
