import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { Client, ClientListItem } from '../../../../types/clients';

/** Retrieves full client from the cache
 * @param clientId The ID of the client
 * @param qc       QueryClient
 * @returns        Client or null
 */
export const getClientFull = (
  clientId: string,
  qc: QueryClient = queryClient,
): Client | null => {
  const data = qc.getQueryData<Client>(QueryKey.Client(clientId));
  return data ?? null;
};

/**
 * Retrieves clients for the current user
 * @param qc QueryClient
 * @returns  Client list or null
 */
export const getClients = (
  qc: QueryClient = queryClient,
): ClientListItem[] | null => {
  const data = qc.getQueryData<ClientListItem[]>(QueryKey.Clients());

  return data ?? null;
};

/**
 * Retrieves client list item from the cache
 * @param clientId The client ID we want
 * @returns        Client or null
 */
export const getClientFromList = (clientId: string): ClientListItem | null => {
  const clients = getClients();

  return clients?.find(client => client.id === clientId) ?? null;
};
