import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { PageId } from '../../constants/page-ids';
import { useClientRoutineFullQuery } from '../../features/routine/@client/api/queries/useClientRoutineFullQuery';
import { useClientRoutinesQuery } from '../../features/routine/@client/api/queries/useClientRoutinesQuery';
import { useClientWorkoutQuery } from '../../features/routine/@client/api/queries/useClientWorkoutQuery';
import { getUser } from '../../features/user/api/cache/getters';
import { translate } from '../../i18n';
import { getPageLabel } from '../../i18n/helpers/page';
import {
  getBreadcrumbRoutineItem,
  getBreadcrumbWorkoutLabel,
  getBreadcrumbWorkoutName,
} from '../../router/breadcrumbs/helpers';
import {
  useParamRoutineId,
  useParamWorkoutId,
} from '../../router/hooks/path-params';
import { routes } from '../../router/routes';
import { getDataById } from '../../utils/array';

const useBreadcrumbs = (): BreadcrumbItem[] => {
  const routineId = useParamRoutineId();
  const workoutId = useParamWorkoutId();

  const { data: routines } = useClientRoutinesQuery(getUser()?.id);
  const { data: workout } = useClientWorkoutQuery(workoutId);
  const { data: routineFull } = useClientRoutineFullQuery(routineId);

  const items: BreadcrumbItem[] = [
    { content: getPageLabel(PageId.MyStuff), href: routes.me.root() },
  ];

  const routineListItem = getDataById(routines, routineId);
  const routineItem = getBreadcrumbRoutineItem(routineFull, routineListItem);
  const workoutItem =
    workout ?? getDataById(routineListItem?.workouts, workoutId);

  if (routineId !== null) {
    items.push({
      content: routineItem?.name,
      href: routes.me.routine(routineId),
      isLoading: routineItem === null,
      label: translate('routine.routines_one'),
    });

    if (workoutId !== null) {
      items.push({
        content: getBreadcrumbWorkoutName(workoutItem),
        href: routes.me.workouts(routineId, workoutId),
        isLoading: workoutItem === null || routineItem === null,
        label: getBreadcrumbWorkoutLabel(routineFull, workoutId),
      });
    }
  }

  return items;
};

export default useBreadcrumbs;
