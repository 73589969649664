import { FC } from 'react';

import { ActionIconButton } from '../../../components/common/ActionIcons';
import HeaderMobile from '../../../components/layout/HeaderMobile';
import ClientsSecondaryNavigation from '../../../features/clients/components/ClientsNavigation/ClientsSecondaryNavigation';
import useIsMobile from '../../../hooks/useIsMobile';
import { translate } from '../../../i18n';
import { ModalId, useModal } from '../../../utils/modal';

const ClientsRootPageLayout: FC = () => {
  const isMobile = useIsMobile();
  const { openModal } = useModal();

  const requestAddClient = () => {
    openModal(ModalId.ClientCreate);
  };

  if (isMobile) {
    return (
      <HeaderMobile
        Action={() => (
          <ActionIconButton
            colorVariant="primary"
            icon="plus"
            onClick={requestAddClient}
            size="lg"
          />
        )}
        title={translate('client.label')}
        withBackButton={false}
      />
    );
  }

  return <ClientsSecondaryNavigation />;
};

export default ClientsRootPageLayout;
