import { FC } from 'react';

import HeaderMobile from '../../../components/layout/HeaderMobile';
import { PageId } from '../../../constants/page-ids';
import useIsMobile from '../../../hooks/useIsMobile';
import { getPageLabel } from '../../../i18n/helpers/page';

const SettingsSubscriptionsLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <HeaderMobile title={getPageLabel(PageId.SettingsSubscriptions)} />;
  }

  return null;
};

export default SettingsSubscriptionsLayout;
