import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import DrawerBackButton from '../../../../components/common/DrawerBackButton';
import { PageId } from '../../../../constants/page-ids';
import { getPageLabel } from '../../../../i18n/helpers/page';
import useCurrentPath from '../../../../router/hooks/useCurrentPath';
import { getBackUrl } from '../../../../router/paths/back-paths';
import {
  PATH_FRAGMENT__CLIENTS,
  PATH_FRAGMENT__COACH_ID,
  PATH_FRAGMENT__FACILITY_ID,
  PathFragment,
} from '../../../../router/paths/path-fragments';
import {
  PATH__BUSINESS__CLIENTS__BY_ID,
  PATH__BUSINESS__COACH_CLIENT__BY_ID,
  PATH__BUSINESS__FACILITY_CLIENT__BY_ID,
  PATH__BUSINESS__FACILITY_COACH_CLIENT__BY_ID,
} from '../../../../router/paths/paths';

type Fn = () => { fragment: PathFragment; label: string };

const backButtonMap = new Map<null | string, Fn>([
  [
    PATH__BUSINESS__CLIENTS__BY_ID,
    () => ({
      fragment: PATH_FRAGMENT__CLIENTS,
      label: getPageLabel(PageId.Clients),
    }),
  ],
  [
    PATH__BUSINESS__COACH_CLIENT__BY_ID,
    () => ({
      fragment: PATH_FRAGMENT__COACH_ID,
      label: getPageLabel(PageId.BusinessCoachDetails),
    }),
  ],
  [
    PATH__BUSINESS__FACILITY_CLIENT__BY_ID,
    () => ({
      fragment: PATH_FRAGMENT__FACILITY_ID,
      label: getPageLabel(PageId.Facility),
    }),
  ],
  [
    PATH__BUSINESS__FACILITY_COACH_CLIENT__BY_ID,
    () => ({
      fragment: PATH_FRAGMENT__COACH_ID,
      label: getPageLabel(PageId.BusinessCoachDetails),
    }),
  ],
]);

const BackButton: FC = () => {
  const location = useLocation();
  const currentPath = useCurrentPath();

  const getBackButton = backButtonMap.get(currentPath);

  if (getBackButton === undefined) {
    return null;
  }

  const props = getBackButton();

  return (
    <DrawerBackButton
      backUrl={getBackUrl(currentPath, location, {
        toPathFragment: props.fragment,
      })}
    >
      {props.label}
    </DrawerBackButton>
  );
};

export default BackButton;
