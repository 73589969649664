import { FC, lazy, Suspense } from 'react';

import Layout from './layout';

const Content = lazy(() => import('./content'));

const SettingsSubscriptionPage: FC = () => {
  return (
    <>
      <Layout />
      <Suspense fallback={null}>
        <Content />
      </Suspense>
    </>
  );
};

export default SettingsSubscriptionPage;
