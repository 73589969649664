import { FC } from 'react';

import { ActionIconButton } from '../../../../components/common/ActionIcons';
import {
  useParamCoachId,
  useParamFacilityId,
} from '../../../../router/hooks/path-params';
import { ModalId, useModal } from '../../../../utils/modal';

const BusinessClientsHeaderAction: FC = () => {
  const { openModal } = useModal();
  const paramCoachId = useParamCoachId();
  const paramFacilityId = useParamFacilityId();

  const requestClientCreate = () => {
    openModal(ModalId.BusinessClientCreate, {
      coachId: paramCoachId,
      facilities: paramFacilityId,
    });
  };

  return (
    <ActionIconButton
      colorVariant="primary"
      icon="plus"
      onClick={requestClientCreate}
    />
  );
};

export default BusinessClientsHeaderAction;
