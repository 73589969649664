import { ErrorParam } from '../../types';

const translationsErrorPages: Record<
  ErrorParam,
  { description: string; title: string }
> = {
  [ErrorParam.Forbidden]: {
    description:
      'You don’t have permission to access this page. Please check your account or contact support if you think this is a mistake.',
    title: 'Access Denied',
  },
  [ErrorParam.General]: {
    description: 'Oops! An unexpected error occurred.',
    title: 'Something Went Wrong',
  },
  [ErrorParam.NotFound]: {
    description:
      'We couldn’t find what you’re looking for. The URL might be incorrect.',
    title: 'Not Found',
  },
  [ErrorParam.PageNotFound]: {
    description:
      'We couldn’t find the page you’re looking for. It might have been moved, or the URL could be incorrect.',
    title: 'Page Not Found',
  },
};

export default translationsErrorPages;
