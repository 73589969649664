import {
  ActionIcon,
  Box,
  rem,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import useSidebarContext from '../../../providers/sidebar/useSidebarContext';
import { getIsNavItemActive } from '../../../router/helpers/navigation';
import { RailNavigationItemName } from '../../../router/types';
import { INavItem } from '../../layout/NavigationDesktop/types';

interface Props extends INavItem {
  name: null | RailNavigationItemName;
}

const style = { flexShrink: 0 };

const RailNavItem: FC<Props> = ({ icon: Icon, label, to }) => {
  const location = useLocation();
  const { open, toggle } = useSidebarContext();

  const isActive = getIsNavItemActive(location.pathname, to, []);

  const content = (
    <Stack
      align="center"
      c="white"
      gap={0}
      justify="center"
      mx="auto"
      p="xs"
      style={style}
    >
      <ActionIcon
        component="div"
        mb="xs"
        radius="md"
        size={38}
        variant={isActive ? 'filled' : 'light'}
      >
        <Icon style={{ height: rem(20), width: rem(20) }} />
      </ActionIcon>
      <Text c="gray.0" fw="500" size="12px">
        {label}
      </Text>
    </Stack>
  );

  if (isActive) {
    return (
      <UnstyledButton aria-current="page" onClick={toggle}>
        {content}
      </UnstyledButton>
    );
  }

  return (
    <Box
      aria-current={false}
      component={NavLink}
      onClick={open}
      style={style}
      td="none"
      to={to}
    >
      {content}
    </Box>
  );
};

export default RailNavItem;
