import { MantineColor } from '@mantine/core';

import { translate } from '../../../i18n';
import { vars } from '../../../theme';
import { CoachingFormat } from '../../../types/clients';
import { SetParameter, WorkoutTableColumn } from '../types';
import { RoutineStatus, WorkoutStatus } from './types';

export const ROUTINE__STATUS_ORDERED = [
  RoutineStatus.InProgress,
  RoutineStatus.Ready,
  RoutineStatus.Draft,
  RoutineStatus.Completed,
];

export const ROUTINE__STATUS_ORDERED_READY_EXCLUDED = [
  RoutineStatus.InProgress,
  RoutineStatus.Draft,
  RoutineStatus.Completed,
];

const COLOR__COMPLETED = vars.colors.green[4];
const COLOR__IN_PROGRESS = vars.colors.branding[8];
const COLOR__NO_STATUS = vars.colors.dark[4];

export const ROUTINE__STATUS_COLORS: Record<RoutineStatus, MantineColor> = {
  [RoutineStatus.Completed]: COLOR__COMPLETED,
  [RoutineStatus.Draft]: COLOR__NO_STATUS,
  [RoutineStatus.InProgress]: COLOR__IN_PROGRESS,
  [RoutineStatus.Ready]: vars.colors.orange[6],
};

export const SET_PARAMETER_ORDER = [
  SetParameter.Volume,
  SetParameter.Tempo,
  SetParameter.Rpe,
  SetParameter.Rest,
  SetParameter.Comments,
];

export const CLIENT_WORKOUT__STATUS_COLORS: Record<
  WorkoutStatus,
  MantineColor
> = {
  Completed: COLOR__COMPLETED,
  CompletedWithMissingData: vars.colors.yellow[8],
  InProgress: COLOR__IN_PROGRESS,
  None: COLOR__NO_STATUS,
  Skipped: vars.colors.violet[6],
};

export const CLIENT_WORKOUT__TABLE_COLUMNS: Array<{
  label: string;
  value: WorkoutTableColumn;
}> = [
  {
    label: translate('exerciseInstruction.sets_one'),
    value: 0,
  },
  {
    label: translate('exerciseInstruction.setParameter.volume.label'),
    value: 1,
  },
  {
    label: translate('common.results'),
    value: 2,
  },
  {
    label: translate('exerciseInstruction.setParameter.tempo.label'),
    value: 3,
  },
  {
    label: translate('exerciseInstruction.setParameter.rpe.label'),
    value: 4,
  },
  {
    label: translate('exerciseInstruction.setParameter.rest.label'),
    value: 5,
  },
  {
    label: translate('exerciseInstruction.setParameter.comments.label'),
    value: 6,
  },
  {
    label: '',
    value: 7,
  },
];

export const COACHING_FORMAT__ORDER = [
  CoachingFormat.Virtual,
  CoachingFormat.FaceToFace,
  CoachingFormat.Blended,
];
