/** @file Contains helpers for ClientRoutinesDnd component */
import { translate } from '../../../../i18n';
import { ROUTINE__STATUS_ORDERED } from '../constants';
import { ClientRoutineList, RoutineStatus, WorkoutStatus } from '../types';
import { isWorkoutCompleted } from './workout';

/**
 * Get routines prop
 * @param routines Client routines we want to render
 * @param status   The routine status
 * @returns        Routines prop for ClientRoutinesDnd component
 */
export const getRoutinesByStatus = (
  routines: ClientRoutineList[] | undefined,
  status: ClientRoutineList['status'],
): ClientRoutineList[] => {
  const routinesByStatus = routines?.filter(r => r.status === status) ?? [];

  if (status === RoutineStatus.Completed) {
    routinesByStatus.sort(
      (a, b) =>
        new Date(b.completedAt ?? 0).getTime() -
        new Date(a.completedAt ?? 0).getTime(),
    );
  }

  return routinesByStatus;
};

export const getMappedRoutinesByStatus = (
  routines: ClientRoutineList[] | undefined,
) => {
  return ROUTINE__STATUS_ORDERED.reduce(
    (mapped, routineStatus) => {
      return {
        ...mapped,
        [routineStatus]: getRoutinesByStatus(routines, routineStatus),
      };
    },
    {} as Record<RoutineStatus, ClientRoutineList[]>,
  );
};

export const getWorkoutCounts = <T extends { status: WorkoutStatus }>(
  workouts: T[],
) => {
  return workouts.reduce(
    (details, workout) => {
      details[workout.status]++;
      return details;
    },
    {
      total: workouts.length,
      [WorkoutStatus.Completed]: 0,
      [WorkoutStatus.CompletedWithMissingData]: 0,
      [WorkoutStatus.InProgress]: 0,
      [WorkoutStatus.None]: 0,
      [WorkoutStatus.Skipped]: 0,
    } as Record<'total' | WorkoutStatus, number>,
  );
};

export const getLastWorkoutCompleted = (
  routine: ClientRoutineList,
  includeSkipped = false,
) =>
  routine.workouts.findLast(({ status }) =>
    isWorkoutCompleted(status, includeSkipped),
  ) ?? null;

export const getNextWorkout = (routine: ClientRoutineList) =>
  routine.workouts.find(
    ({ status }) =>
      status === WorkoutStatus.None || status === WorkoutStatus.InProgress,
  ) ?? null;

export const getRoutineStartedAt = (routine: ClientRoutineList) =>
  routine.workouts.find(({ status }) => status !== WorkoutStatus.None)
    ?.completedAt ?? null;

export const getRoutineCompletedAt = (routine: ClientRoutineList) => {
  if (routine.status !== RoutineStatus.Completed) {
    return null;
  }

  return (
    routine.completedAt ??
    routine.workouts.findLast(({ status }) => isWorkoutCompleted(status))
      ?.completedAt ??
    null
  );
};

export const getRoutineStatusUpdateOptions = <
  T extends { status: RoutineStatus },
>(
  routine: T,
  onClick?: (routineStatus: RoutineStatus) => void,
) => {
  return ROUTINE__STATUS_ORDERED.filter(s => s !== routine.status).map(
    status => ({
      label: translate(`routine.status.${status}.label`),
      onClick: () => onClick?.(status),
      value: status,
    }),
  );
};
