import { useMutation } from '@tanstack/react-query';

import http from '../../../../config/http';
import {
  ClientMutationContext,
  clientMutationOnErrorHandler,
  clientMutationOnMutateHandler,
  clientMutationOnSettledHandler,
} from './handler';

const deleteClient = async (clientId: string) => {
  const { data } = await http.delete(`/users/${clientId}`);

  return data.data;
};

export const useClientDeleteMutation = () => {
  return useMutation({
    mutationFn: deleteClient,
    async onMutate(variables) {
      return await clientMutationOnMutateHandler(variables);
    },
    onSettled(_, error, clientId, context) {
      if (error) {
        clientMutationOnErrorHandler(context as ClientMutationContext);
      }
      clientMutationOnSettledHandler(clientId);
    },
  });
};
