/** @file Contains helpers for button elements */
import { TOptions } from 'i18next';

import { translate } from '..';
import { type ButtonId } from '../../constants/button-ids';

/**
 * Gets the translated button label
 * @param buttonId The ButtonId
 * @param options  Translation options
 * @returns        Translation for button label
 */
export const getButtonLabel = (buttonId: ButtonId, options: TOptions = {}) => {
  return translate(`button.${buttonId}.label`, options);
};

/**
 * Gets the translated button title
 * @param buttonId The ButtonId
 * @returns        Translation for button title
 */
export const getButtonTitle = (buttonId: ButtonId) => {
  return translate(`button.${buttonId}.title`);
};
