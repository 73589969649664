import { ActionIconProps } from '@mantine/core';

import { iconButtonProps } from './config';

export const getActionIconProps = (
  colorVariant: 'dark' | 'light' | 'primary',
  variant: ActionIconProps['variant'] = 'subtle',
): ActionIconProps => {
  const colorsMap = {
    dark: 'dark.7',
    light: 'gray.1',
    primary: 'branding.6',
  };

  return {
    ...iconButtonProps,
    color: colorsMap[colorVariant],
    radius: 'md',
    variant: colorVariant === 'primary' ? 'subtle' : variant,
  };
};
