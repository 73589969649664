import { Button } from '@mantine/core';
import { FC } from 'react';

import HeaderMobile from '../../components/layout/HeaderMobile';
import { PageId } from '../../constants/page-ids';
import { setUser } from '../../features/user/utils/cognito';
import useIsMobile from '../../hooks/useIsMobile';
import { getPageLabel } from '../../i18n/helpers/page';

const MoreLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile === false) {
    return null;
  }

  return (
    <HeaderMobile
      Action={() => (
        <Button
          color="dark"
          onClick={() => setUser(null, true)}
          size="sm"
          variant="transparent"
        >
          Logout
        </Button>
      )}
      title={getPageLabel(PageId.More)}
      withBackButton={false}
    />
  );
};

export default MoreLayout;
