import { useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../../config/http';
import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { TemplateRoutineFull } from '../../types';

async function getFullTemplateRoutine(
  routineId: string,
): Promise<TemplateRoutineFull> {
  const { data } = await http.get<ApiResponse<TemplateRoutineFull>>(
    `/template-routines/${routineId}`,
  );
  return {
    ...data.data,
    workouts: data.data.workouts.map(wo => ({
      ...wo,
      exerciseInstructions: wo.exerciseInstructions.map(exInstr => ({
        ...exInstr,
        workoutId: wo.id,
      })),
    })),
  };
}

export const useTemplateRoutineFullQuery = (
  routineId: null | string,
  opt?: { enabled?: boolean },
) => {
  return useQuery({
    enabled: routineId !== null && opt?.enabled !== false,
    queryFn: () => getFullTemplateRoutine(routineId as string),
    queryKey: QueryKey.TemplateRoutine(routineId as string),
  });
};

export const invalidateTemplateRoutine = (routineId: string) =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.TemplateRoutine(routineId),
    refetchType: 'all',
  });

export const cancelTemplateRoutineQuery = (routineId: string) =>
  queryClient.cancelQueries({
    queryKey: QueryKey.TemplateRoutine(routineId),
  });
