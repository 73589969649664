import { AppShell, Center, Group, ScrollArea, Stack } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import { FC } from 'react';

import { PageId } from '../../../constants/page-ids';
import { PORTAL__SIDEBAR_CONTENT } from '../../../constants/portals';
import {
  HEADER_AND_RAIL_COLOR,
  HEADER_HEIGHT,
  RAIL_DRAWER_WIDTH,
} from '../../../constants/preferences';
import UserMenu from '../../../features/user/components/UserMenu';
import { getPageLabel } from '../../../i18n/helpers/page';
import { getNavItems } from '../../../router/helpers/navigation';
import { routes } from '../../../router/routes';
import { RailNavigationItemName } from '../../../router/types';
import RailNavItem from '../../common/RailNavItem';
import Logo from '../Logo';
import classes from './styles.css';

const height = `calc(100dvh - ${HEADER_HEIGHT}px)`;

const NavigationDesktop: FC = () => {
  const links = getNavItems().map((item, i) => {
    if (item === null) {
      return null;
    }

    return <RailNavItem key={i} name={null} {...item} />;
  });

  return (
    <AppShell.Navbar
      bg={HEADER_AND_RAIL_COLOR}
      className={classes.wrapper}
      mah="100dvh"
    >
      <Group align="flex-start" flex={1} gap={0}>
        <Stack gap={0} h="100%" maw={RAIL_DRAWER_WIDTH} pb="xs" px="md">
          <Center h={HEADER_HEIGHT} maw={RAIL_DRAWER_WIDTH}>
            <Logo />
          </Center>
          <AppShell.Section
            className={classes.links}
            component={ScrollArea}
            grow
            h={height}
          >
            <Stack mih={height} pb="xl">
              {links}
              <Stack flex={1} h="100%" justify="flex-end">
                <RailNavItem
                  icon={IconSettings}
                  label={getPageLabel(PageId.Settings)}
                  name={RailNavigationItemName.Settings}
                  to={routes.settings.root()}
                />
                <UserMenu />
              </Stack>
            </Stack>
          </AppShell.Section>
        </Stack>
        <AppShell.Section id={PORTAL__SIDEBAR_CONTENT.replace('#', '')} />
      </Group>
    </AppShell.Navbar>
  );
};

export default NavigationDesktop;
