import { RoutineStatus } from '../features/routine/@client/types';
import { ExerciseCategory } from '../features/routine/types';
import { UserType } from '../features/user/types';
import { ModalId } from '../utils/modal';

export const SearchParam = {
  ClientId: 'clientId',
  CoachId: 'coachId',
  Code: 'code',
  CompareWorkouts: 'cmpWo',
  Component: 'cmp',
  CycleId: 'cycleId',
  Email: 'email',
  /**
   * General id used
   */
  EntityId: 'eId',
  EntityType: 'eType',
  EntityVariant: 'eVariant',
  ExerciseId: 'exerciseId',
  ExercisesCategory: 'exerciseCategory',
  Facilities: 'facilities',
  ModalId: 'modalId',
  Rail: 'rail',
  RoutineStatus: 'rStatus',
  Search: 'search',
  SelectedExerciseInstructions: 'sbEId',
  SetParameters: 'sP',
  SetsBuilderOpen: 'sbo',
  SetsBuilderSetId: 'sbSetId',
  SetsBuilderSetsSelected: 'sbSv',
  SortBy: 'sortBy',
  SortDirection: 'sortDirection',
  Status: 'status',
  Temp: 'temp',
  UserType: 'uType',
} as const;

export type SearchParam = (typeof SearchParam)[keyof typeof SearchParam];

export enum RailNavigationItemName {
  Business = 'business',
  Clients = 'clients',
  Home = 'home',
  Library = 'library',
  More = 'more',
  MyStuff = 'me',
  Settings = 'settings',
}

export interface SearchParamValues extends Record<SearchParam, null | string> {
  [SearchParam.ClientId]: null | string;
  [SearchParam.CoachId]: null | string;
  [SearchParam.Code]: null | string;
  [SearchParam.CompareWorkouts]: null | string;
  [SearchParam.CycleId]: null | string;
  [SearchParam.Email]: null | string;
  [SearchParam.EntityId]: null | string;
  [SearchParam.EntityType]: null | string;
  [SearchParam.EntityVariant]: null | string;
  [SearchParam.ExerciseId]: null | string;
  [SearchParam.ExercisesCategory]: ExerciseCategory | null;
  [SearchParam.Facilities]: null | string;
  [SearchParam.ModalId]: ModalId | null;
  [SearchParam.Rail]: null | RailNavigationItemName;
  [SearchParam.RoutineStatus]: null | RoutineStatus;
  [SearchParam.Search]: null | string;
  [SearchParam.SelectedExerciseInstructions]: null | string;
  [SearchParam.SetParameters]: null | string;
  [SearchParam.SetsBuilderOpen]: 'false' | 'true' | null;
  /**
   * To Prefill data
   */
  [SearchParam.SetsBuilderSetId]: null | string;
  [SearchParam.SetsBuilderSetsSelected]: null | string;
  [SearchParam.SortBy]: null | string;
  [SearchParam.SortDirection]: null | string;
  [SearchParam.Status]: null | string;
  [SearchParam.UserType]: null | UserType;
}

export type IUrlSearchParams = Partial<SearchParamValues> & URLSearchParams;
