import { useMutation } from '@tanstack/react-query';

import http from '../../../../../config/http';
import { getTemplateRoutineFull, getTemplateRoutines } from '../cache/getters';
import { setTemplateRoutines } from '../cache/setters';
import { cancelTemplateRoutineQuery } from '../queries/useTemplateRoutineFullQuery';
import { cancelTemplateRoutinesQuery } from '../queries/useTemplateRoutinesQuery';
import {
  templateRoutineMutationErrorHandler,
  templateRoutineMutationSettledHandler,
} from './handlers';
import { TemplateRoutineMutationContext } from './types';

async function deleteRoutine(routineId: string) {
  await http.delete(`/template-routines/${routineId}`);
}

export const useTemplateRoutineDelete = () => {
  return useMutation({
    mutationFn: deleteRoutine,
    onError(_error, routineId, context) {
      templateRoutineMutationErrorHandler(
        routineId,
        context as TemplateRoutineMutationContext,
      );
    },
    onMutate: async routineId => {
      const routine = getTemplateRoutineFull(routineId);

      await Promise.all([
        cancelTemplateRoutineQuery(routineId),
        cancelTemplateRoutinesQuery(),
      ]);

      const routinesAll = getTemplateRoutines();

      if (routinesAll !== null) {
        setTemplateRoutines(routinesAll.filter(r => r.id !== routineId));
      }

      return { routine, routinesAll };
    },
    onSettled(_, __, ___, context) {
      const { routine } = context as TemplateRoutineMutationContext;
      templateRoutineMutationSettledHandler(
        routine?.id,
        context as TemplateRoutineMutationContext,
      );
    },
  });
};
