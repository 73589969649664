import { FC } from 'react';

import HeaderMobile from '../../../components/layout/HeaderMobile';
import { PageId } from '../../../constants/page-ids';
import useIsMobile from '../../../hooks/useIsMobile';
import { getPageLabel } from '../../../i18n/helpers/page';
import { PATH__MORE } from '../../../router/paths/paths';

const SettingsRootLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile === false) {
    return null;
  }

  return (
    <HeaderMobile
      Action={null}
      backUrl={PATH__MORE}
      title={getPageLabel(PageId.Settings)}
    />
  );
};

export default SettingsRootLayout;
