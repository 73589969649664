import { useContext } from 'react';

import ScrollContainerContext from './context';

const useScrollContainerContext = () => {
  const context = useContext(ScrollContainerContext);

  if (context === undefined) {
    throw new Error(
      'useScrollContainerContext can not be used outside of ScrollContainerProvider',
    );
  }

  return context;
};

export default useScrollContainerContext;
