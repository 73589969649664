/**
 * @file Contains helpers for array actions
 */

/**
 * Looks for an object with matching id
 * @param data Any array with objects that have id property
 * @param id   Id of the object that we are looking for
 * @returns    If found, the object, otherwise null
 */
export const getDataById = <T extends { id: number | string }>(
  data: null | T[] | undefined,
  id: null | number | string | undefined,
): null | T => {
  if (!data || !id) {
    return null;
  }

  return data.find(dataItem => dataItem.id === id) ?? null;
};

/**
 * Inserts data at specified index
 * @param data  Any array with objects that have id property
 * @param item  The item that will be inserted
 * @param index The index where the item will be inserted (optional - default at end of the array)
 * @returns     If found, the object, otherwise null
 */
export const insertData = <T extends { id: string }>(
  data: T[],
  item: T,
  index: number = data.length,
): T[] => {
  return data.toSpliced(index, 0, item);
};

/**
 * Removes the object by id from an array
 * @param data Any array with objects that have id property
 * @param id   Id of the object that will be removed
 * @returns    Updated array
 */
export const removeDataById = <T extends { id: string }>(
  data: T[],
  id: string,
): T[] => data.filter(dataItem => dataItem.id !== id);

/**
 * Updates an item within an array
 * @param data         Any array with objects that have id property
 * @param id           Id of the object that will be removed
 * @param dataToUpdate The data we want to update
 * @returns            Updated array
 */
export const updateDataById = <T extends { id: string }>(
  data: T[],
  id: string,
  dataToUpdate: Partial<T>,
): T[] =>
  data.map(dataItem =>
    dataItem.id !== id ? dataItem : { ...dataItem, ...dataToUpdate },
  );

/**
 * Given any array data of objects with id, the provided data item will be
 * replaced if exists, otherwise it will be added to the array
 * @param data     Any array with objects that have id property
 * @param item     The object that will be added/replaced
 * @param position Where we want to place the object (end | exact | start)
 * @returns        Updated array including new or replaced object
 */
// eslint-disable-next-line complexity
export const upsertData = <T extends { id: string }>(
  data: T[],
  item: T,
  position: 'end' | 'exact-end' | 'exact-start' | 'start' = 'exact-start',
): T[] => {
  const copy = structuredClone(data);
  const index = copy.findIndex(dataItem => dataItem.id === item.id);

  if (index > -1) {
    copy.splice(index, 1);
  }

  switch (position) {
    case 'end': {
      copy.push(item);
      break;
    }
    case 'start': {
      copy.unshift(item);
      break;
    }
    case 'exact-start': {
      copy.splice(index > -1 ? index : 0, 0, item);
      break;
    }
    case 'exact-end': {
      copy.splice(index > -1 ? index : copy.length, 0, item);
      break;
    }
  }

  return copy;
};

/**
 * Get the unique values for primary and secondary items for select component.
 * When a primary item is selected, it should not be available
 * in secondary select option to pick
 * @param input          Select options
 * @param primaryItems   Goals array
 * @param secondaryItems Goals Array
 * @returns              The available options for primary and secondary goals
 */
export const getDistinctItems = <T>(
  input: { label: string; value: T }[],
  primaryItems: T[],
  secondaryItems: T[],
) => {
  const setPrimary = new Set(primaryItems);
  const setSecondary = new Set(secondaryItems);

  const primary = input.filter(
    item => setSecondary.has(item.value as T) === false,
  );

  const secondary = input.filter(
    item => setPrimary.has(item.value as T) === false,
  );

  return [primary, secondary];
};
