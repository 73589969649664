import { createContext } from 'react';

interface SidebarContextProps {
  close: () => void;
  isOpen: boolean;
  open: () => void;
  toggle: () => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined,
);

export default SidebarContext;
