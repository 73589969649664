import {
  Box,
  Button,
  Group,
  InputBase,
  InputWrapper,
  Loader,
  rem,
  ScrollArea,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import Downshift from 'downshift';
import { useCallback, useRef } from 'react';

import { ActionIconButton } from '../../../../../components/common/ActionIcons';
import { ButtonId } from '../../../../../constants/button-ids';
import { translate } from '../../../../../i18n';
import { getButtonLabel } from '../../../../../i18n/helpers/buttons';
import { getDataById } from '../../../../../utils/array';
import { Exercise } from '../../../types';
import { useExercises } from '../../api/queries/useExercises';
// import { assets } from '../../assets';
import { createExerciseFilter, getFullName } from './helpers';
import classes from './styles.css';
import { TGetMenuItemPropsFn } from './types';

interface Props {
  autoFocus?: boolean;
  defaultSelected: null | string;
  isLoading?: boolean;
  onBlur?: () => void;
  onChange: (exerciseId: null | string) => void;
  onClear?: () => void;
  styleVariant?: 'dark' | 'light';
}

export default function ExercisePickerAutocomplete({
  autoFocus = true,
  defaultSelected,
  isLoading = false,
  onBlur,
  onChange,
  onClear,
  styleVariant = 'light',
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: exercises, isLoading: exercisesLoading } = useExercises();
  const defaultExercise = getDataById(exercises, defaultSelected);

  /**
   * Memoized filter and sort function for exercises.
   * @param   {Exercise[]} exercises  An array of exercises to filter and sort.
   * @param   {string}     inputValue The search query for filtering exercises.
   * @returns {Exercise[]}            An array of filtered and sorted exercises.
   */
  const filterExercises = useCallback(
    (exercises: Exercise[], inputValue: string) => {
      const exerciseFilter = createExerciseFilter();
      return exerciseFilter(exercises, inputValue);
    },
    [],
  );

  /**
   * Get filtered and sorted exercises based on user input and component state.
   * @param   {boolean}    isOpen          A flag indicating whether the filtering should be performed (true for open, false otherwise).
   * @param   {string}     [inputValue=""] The search query for filtering exercises (optional).
   * @returns {Exercise[]}                 An array of filtered and sorted exercises.
   */
  const getFilteredExercises = useCallback(
    (isOpen: boolean, inputValue = '') => {
      if (isOpen === false || exercises === undefined) {
        return [];
      }

      return filterExercises(Object.values(exercises).flat(), inputValue);
    },
    [filterExercises, exercises],
  );

  const renderResults = useCallback(
    (
      isOpen: boolean,
      inputValue: string,
      getItemProps: TGetMenuItemPropsFn,
      closeMenu: () => void,
    ) => {
      if (
        isOpen === false ||
        exercises === undefined ||
        inputValue.trim() === ''
      ) {
        return null;
      }

      const filteredExercises = getFilteredExercises(isOpen, inputValue);

      if (filteredExercises.length > 0) {
        return filteredExercises.map((exercise, i) => {
          const { id, name, variation } = exercise;

          return (
            <Group
              {...getItemProps({
                index: i,
                item: exercise,
                key: id,
              })}
              className={classes.menuItem}
            >
              {/* <Image
                alt={name}
                h={52}
                miw={80}
                radius="sm"
                src={thumbnail ?? assets.defaultExerciseThumbnail}
              /> */}
              <Stack gap={4}>
                <Title order={6}>{name}</Title>
                <Text c="gray.5" size="sm">
                  {variation}
                </Text>
              </Stack>
            </Group>
          );
        });
      } else {
        return (
          <Stack align="center" justify="center" p="md" pt="xl">
            <Title c="dark.6" size="sm">
              {translate('exercise.search.noResult.title')}
            </Title>
            <Text c="dimmed" size="sm">
              {translate('exercise.search.noResult.subtitle')}
            </Text>
            <Button
              mt="md"
              onClick={() => {
                if (inputRef.current !== null) {
                  inputRef.current.value = '';
                }
                closeMenu();
              }}
            >
              {getButtonLabel(ButtonId.ExerciseCreate)}
            </Button>
          </Stack>
        );
      }
    },
    [exercises, getFilteredExercises],
  );

  if (exercisesLoading) {
    return (
      <InputBase
        disabled
        rightSection={<Loader color="blue.2" size={rem(20)} />}
        size="lg"
      />
    );
  }

  const renderRightSection = (
    isLoading: boolean,
    clearSelection: (cb?: () => undefined | void) => void,
    onClear?: () => void,
  ) => {
    if (onClear === undefined) {
      return null;
    }

    if (isLoading === true) {
      return <Loader color="blue.2" size={rem(20)} />;
    }

    return (
      <ActionIconButton
        color="red.8"
        icon="x"
        onClick={() => {
          clearSelection(onClear);
        }}
        opacity={0.8}
        variant="subtle"
      />
    );
  };

  return (
    <Downshift
      defaultHighlightedIndex={0}
      initialSelectedItem={defaultSelected && defaultExercise}
      itemToString={exercise => (exercise ? getFullName(exercise) : '')}
      onSelect={exercise => onChange(exercise ? exercise.id : null)}
    >
      {({
        clearSelection,
        // selectedItem,
        closeMenu,
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getRootProps,
        inputValue,
        // getToggleButtonProps,
        isOpen,
      }) => (
        <Box pos="relative" {...getRootProps()} onBlur={onBlur}>
          <InputWrapper labelProps={getLabelProps()}>
            <InputBase
              autoFocus={autoFocus}
              c="red"
              disabled={isLoading}
              name="exercise-picker"
              onClick={e => e.stopPropagation()}
              onFocus={e => {
                e.stopPropagation();
                e.currentTarget.select();
              }}
              pos="relative"
              ref={inputRef}
              rightSection={renderRightSection(
                isLoading,
                clearSelection,
                onClear,
              )}
              rightSectionPointerEvents="all"
              size="lg"
              style={{ width: '100%', zIndex: 100 }}
              styles={{
                input: {
                  backgroundColor: 'transparent',
                  color: styleVariant === 'dark' ? '#fff' : '#000',
                },
              }}
              {...getInputProps()}
            />
          </InputWrapper>
          {!!inputValue && isOpen === true && (
            <ScrollArea {...getMenuProps()} className={classes.menu} mah={320}>
              {renderResults(isOpen, inputValue, getItemProps, closeMenu)}
            </ScrollArea>
          )}
        </Box>
      )}
    </Downshift>
  );
}
