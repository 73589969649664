import { FieldId } from '../../constants/forms/form-fields';

interface FormFieldProp {
  /**
   * Description text below the label
   */
  description?: string;
  /**
   * Tooltip or some details
   */
  info?: string;
  /**
   * The label
   */
  label: string;
  /**
   * The placeholder
   */
  placeholder?: string;
}

const translationFormField: Record<FieldId, FormFieldProp> = {
  [FieldId.Admins]: {
    label: 'Admins',
  },
  [FieldId.Birthday]: {
    label: 'Birthday',
  },
  [FieldId.Bodyweight]: {
    label: 'Bodyweight',
    placeholder: 'Enter bodyweight',
  },
  [FieldId.BodyWeightPercentage]: {
    info: 'How much % of bodyweight should be included in the tonnage.',
    label: 'Bodyweight Percentage',
  },
  [FieldId.BusinessCoachRank]: {
    label: 'Coach Level',
  },
  [FieldId.BusinessName]: {
    label: 'Business Name',
  },
  [FieldId.CheckInEnergy]: {
    label: 'Energy',
    placeholder: 'Enter Energy',
  },
  [FieldId.CheckInMood]: {
    label: 'Mood',
    placeholder: 'Enter Mood',
  },
  [FieldId.CheckInSleep]: {
    label: 'Sleep',
    placeholder: 'Enter Sleep',
  },
  [FieldId.CheckInSoreness]: {
    label: 'Soreness',
    placeholder: 'Enter Soreness',
  },
  [FieldId.ClientsSearch]: {
    label: 'Search Clients',
    placeholder: 'Search Clients',
  },
  [FieldId.Coach]: {
    label: 'Coach',
  },
  [FieldId.Coaches]: {
    label: 'Coaches',
  },
  [FieldId.CoachesSearch]: {
    label: 'Search Coaches',
    placeholder: 'Search Coaches',
  },
  [FieldId.CoachingFormat]: {
    info: 'How you are going to train the client',
    label: 'Coaching Format',
  },
  [FieldId.Comments]: {
    label: 'Comments',
    placeholder: 'Enter new comment',
  },
  [FieldId.Complexity]: {
    label: 'Exercise Complexity',
  },
  [FieldId.ConfirmPassword]: {
    label: 'Confirm Password',
  },
  [FieldId.CountryCode]: {
    label: 'Select Your Country',
  },
  [FieldId.CycleName]: {
    label: 'Cycle Name',
  },
  [FieldId.Description]: {
    label: 'Description',
  },
  [FieldId.Difficulty]: {
    label: 'Difficulty',
  },
  [FieldId.Email]: {
    label: 'Email',
  },
  [FieldId.ExecutionType]: {
    label: 'Execution Type',
  },
  [FieldId.ExerciseSearch]: {
    label: 'Search',
    placeholder: 'Search Exercises',
  },
  [FieldId.ExerciseType]: {
    info: 'Compound are multi-joint exercises like Bench Press and Squat. Isolation are single joint exercises like DB Fly, Leg Extensions',
    label: 'Exercise Type',
  },
  [FieldId.ExerciseViewSelect]: {
    label: 'List exercises',
  },
  [FieldId.ExternalUrl]: {
    label: 'External URL',
    placeholder: 'https://youtube.com/...',
  },
  [FieldId.Facilities]: {
    label: 'Facilities',
    placeholder: 'Select multiple',
  },
  [FieldId.Facility]: {
    label: 'Facility',
  },
  [FieldId.FirstName]: {
    label: 'First Name',
  },
  [FieldId.Gender]: {
    label: 'Gender',
  },
  [FieldId.Height]: {
    label: 'Height',
  },
  [FieldId.Instruction]: {
    label: 'Instructions',
  },
  [FieldId.IsUnilateral]: {
    label: 'Is Unilateral',
  },
  [FieldId.LastName]: {
    label: 'Last Name',
  },
  [FieldId.Login]: {
    label: 'Email' /** @todo - add username */,
  },
  [FieldId.Name]: {
    label: 'Name',
  },
  [FieldId.NewPassword]: {
    label: 'New Password',
  },
  [FieldId.OldPassword]: { label: 'Old Password' },
  [FieldId.Password]: { label: 'Password' },
  [FieldId.PostalCode]: {
    label: 'Postal Code',
  },
  [FieldId.PrimaryGoals]: { label: 'Primary Goals' },
  [FieldId.PrimaryMuscles]: { label: 'Primary Muscles' },
  [FieldId.Region]: {
    label: 'Region',
  },
  [FieldId.RestDetails]: { label: 'Rest Details' },
  [FieldId.RestHours]: { label: 'Rest Time' },
  [FieldId.SecondaryGoals]: { label: 'Secondary Goals' },
  [FieldId.SecondaryMuscles]: { label: 'Secondary Muscles' },
  [FieldId.SelectClient]: { label: 'Select Clients' },
  [FieldId.SelectExercise]: { label: 'Select Exercise' },
  [FieldId.SetComments]: {
    label: 'Comments',
  },
  [FieldId.SetIsOptional]: {
    label: 'Optional',
  },
  [FieldId.SetRest]: {
    label: 'Rest',
  },
  [FieldId.SetRpe]: {
    label: 'Rpe',
  },
  [FieldId.SetSets]: {
    label: 'Set',
  },
  [FieldId.SetTempo]: {
    label: 'Tempo',
  },
  [FieldId.SetVolume]: {
    label: 'Volume',
  },
  [FieldId.SetVolumeUnit]: {
    label: 'Volume',
  },
  [FieldId.SetWeight]: {
    label: 'Weight',
  },
  [FieldId.SubscriptionBillingInterval]: {
    label: 'Billing interval',
  },
  [FieldId.SubscriptionPlanType]: {
    label: 'Plan Type',
    placeholder: 'Select a plan type',
  },
  [FieldId.SubscriptionPlanVariant]: {
    label: 'Plan Variant',
    placeholder: 'Select a plan variant',
  },
  [FieldId.TaxIdentifier]: {
    info: 'For correct tax calculation and potential exemptions.',
    label: 'Tax Identifier',
  },
  [FieldId.TemplateSearch]: {
    label: 'Search Template',
    placeholder: 'Search Template',
  },
  [FieldId.TemporaryPassword]: { label: 'Temporary Password' },
  [FieldId.Terms]: { label: 'I accept and agree to the' },
  [FieldId.Thumbnail]: {
    label: 'Thumbnail',
    placeholder: 'Drag image here or click to select',
  },
  [FieldId.Username]: { label: 'Username' },
  [FieldId.Variation]: { label: 'Variation' },
  [FieldId.Video]: {
    label: 'Video',
    placeholder: 'Drag video here or click to select',
  },
  [FieldId.VolumeUnits]: { label: 'Volume Units' },
  [FieldId.WorkoutCompareColumnsFilter]: {
    description: 'Only selected columns will be visible.',
    label: 'Columns',
  },
  [FieldId.WorkoutCompareSelect]: {
    description: 'View workouts side by side to compare the results.',
    label: 'Select Workouts to Compare',
    placeholder: 'Select workouts to compare',
  },
};

export default translationFormField;
