import { useDocumentTitle as useMantineDocumentTitle } from '@mantine/hooks';

const useDocumentTitle = (title?: string) => {
  const prefix = 'Strenco';
  const text = title ? `${prefix} - ${title}` : prefix;

  useMantineDocumentTitle(text);
};

export default useDocumentTitle;
