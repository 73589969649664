import { ClientRoutineFull, ClientRoutineList } from '../../types';

/**
 * Transforms the full routine to match the structure of an routine list item
 * @param routine The full routine object
 * @returns       Transformed routine object
 */
export const transformClientRoutineFullToList = (
  routine: ClientRoutineFull,
): ClientRoutineList => {
  const { workouts, ...routineDetails } = routine;

  return {
    ...routineDetails,
    workouts: workouts.map(wo => ({
      clientId: routine.clientId,
      completedAt: wo.completedAt,
      cycleId: wo.cycleId,
      id: wo.id,
      name: wo.name,
      position: wo.position,
      restHours: wo.restHours,
      routineId: routine.id,
      status: wo.status,
    })),
  };
};
