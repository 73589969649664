import { FC } from 'react';

import { ActionIconButton } from '../../../../components/common/ActionIcons';
import { useParamFacilityId } from '../../../../router/hooks/path-params';
import { ModalId, useModal } from '../../../../utils/modal';

const BusinessCoachesHeaderAction: FC = () => {
  const { openModal } = useModal();
  const paramFacilityId = useParamFacilityId();

  const requestCoachCreate = () => {
    openModal(ModalId.BusinessCoachCreate, {
      facilities: paramFacilityId,
    });
  };

  return (
    <ActionIconButton
      colorVariant="primary"
      icon="plus"
      onClick={requestCoachCreate}
    />
  );
};

export default BusinessCoachesHeaderAction;
