import { AppShell, Group } from '@mantine/core';
import { FC } from 'react';

import {
  PORTAL__HEADER_ACTIONS,
  PORTAL__HEADER_CONTENT,
} from '../../../constants/portals';
import {
  HEADER_AND_RAIL_COLOR,
  HEADER_HEIGHT,
} from '../../../constants/preferences';

const HeaderDesktop: FC = () => {
  return (
    <AppShell.Header
      bg={HEADER_AND_RAIL_COLOR}
      h={HEADER_HEIGHT}
      visibleFrom="sm"
      withBorder={false}
    >
      <Group align="center" h="100%" justify="space-between" px="sm" w="100%">
        <Group gap="xs" id={PORTAL__HEADER_CONTENT.replace('#', '')}></Group>
        <Group
          gap="xs"
          id={PORTAL__HEADER_ACTIONS.replace('#', '')}
          ml="auto"
        ></Group>
      </Group>
    </AppShell.Header>
  );
};

export default HeaderDesktop;
