import { Group, Portal, rem } from '@mantine/core';
import { FC } from 'react';

import { ActionIconButton } from '../../../../components/common/ActionIcons';
import HeaderMobile from '../../../../components/layout/HeaderMobile';
import SearchInputUrl from '../../../../components/ui/forms/SearchInputUrl';
import { FieldId } from '../../../../constants/forms/form-fields';
import { PageId } from '../../../../constants/page-ids';
import { PORTAL__HEADER_ACTIONS } from '../../../../constants/portals';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getFieldPlaceholder } from '../../../../i18n/helpers/forms';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { ModalId, useModal } from '../../../../utils/modal';

const TemplateWorkoutsRootLayout: FC = () => {
  const isMobile = useIsMobile();
  const { openModal } = useModal();

  const requestTemplateWorkoutCreate = () => {
    openModal(ModalId.TemplateWorkoutCreate);
  };

  if (isMobile) {
    return (
      <HeaderMobile
        Action={() => (
          <ActionIconButton
            colorVariant="primary"
            icon="plus"
            onClick={requestTemplateWorkoutCreate}
            size="lg"
          />
        )}
        title={getPageLabel(PageId.TemplateWorkouts)}
      />
    );
  }

  return (
    <Portal target={PORTAL__HEADER_ACTIONS}>
      <Group flex={1}>
        <SearchInputUrl
          flex={1}
          placeholder={getFieldPlaceholder(FieldId.TemplateSearch)}
          w={rem(300)}
        />
      </Group>
    </Portal>
  );
};

export default TemplateWorkoutsRootLayout;
