/**
 * @file Contains a hook that uses links to handle modal visibility
 */
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { createLocationState } from '../../router/routes';
import { getSearchParamModalId } from '../../router/urls/searchParams/getters';
import {
  getCloseModalUrl,
  getHasRequiredModalParams,
  getOpenModalUrl,
} from './helpers';
import { ModalId, ModalOpenHandler } from './types';

/**
 * A hook that can open and close modals by using deep links
 * @returns Callbacks to open and close a modal, and the id of currently open modal
 */
export const useModal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const openModal: ModalOpenHandler = useCallback(
    (modalId, paramConfig) => {
      const url = getOpenModalUrl(location, modalId, paramConfig);
      navigate(url, { replace: true, state: createLocationState() });
    },
    [location, navigate],
  );

  const closeModal = useCallback(() => {
    const searchParamModalId = getSearchParamModalId(location);

    if (searchParamModalId === null) {
      return;
    }
    navigate(getCloseModalUrl(location), { replace: true });
  }, [location, navigate]);

  const isModalOpen = useCallback(
    (modalId: ModalId) =>
      modalId !== null &&
      getSearchParamModalId(location) === modalId &&
      getHasRequiredModalParams(location, modalId) === true,
    [location],
  );

  return { closeModal, isModalOpen, openModal };
};
