import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DrawerSidebarHeader,
  DrawerSidebarScrollArea,
} from '../../../../../components/common/DrawerComponents';
import DrawerNavGroup from '../../../../../components/common/DrawerNavGroup';
import DrawerSidebar from '../../../../../components/common/DrawerSidebar';
import useScrollActiveLinkIntoView from '../../../../../hooks/useScrollCurrentLinkIntoView';
import { getPageLabel } from '../../../../../i18n/helpers/page';
import { useHashWorkoutId } from '../../../../../router/hooks/hash';
import { useParamWorkoutId } from '../../../../../router/hooks/path-params';
import useCurrentPath from '../../../../../router/hooks/useCurrentPath';
import { getCycleWorkouts } from '../../../helpers/routine';
import BackButton from './BackButton';
import { getRoutineWorkoutsSidebarItems } from './helpers';
import {
  RoutineNavigationPartial,
  RoutineSecondaryNavigationProps,
} from './types';

const RoutineWorkouts: FC<RoutineNavigationPartial> = routine => {
  const currentPath = useCurrentPath();
  const location = useLocation();
  const hashWorkoutId = useHashWorkoutId();
  const paramWorkoutId = useParamWorkoutId();

  useScrollActiveLinkIntoView();

  const cycleWorkouts = getCycleWorkouts(routine);
  return routine?.cycles.map((cycle, cycleId) => {
    const workouts = cycleWorkouts?.[cycleId] ?? [];
    return (
      <DrawerNavGroup
        items={getRoutineWorkoutsSidebarItems(
          currentPath,
          workouts,
          hashWorkoutId,
          paramWorkoutId,
          location,
        )}
        key={cycleId}
        label={cycle.name}
      />
    );
  });
};

const RoutineFullSecondaryNavigation: FC<RoutineSecondaryNavigationProps> = ({
  pageId,
  routine,
}) => {
  const [scrolled, setScrolled] = useState(false);

  return (
    <DrawerSidebar title={getPageLabel(pageId)}>
      <DrawerSidebarHeader scrolled={scrolled}>
        <BackButton />
      </DrawerSidebarHeader>
      <DrawerSidebarScrollArea onScrolled={setScrolled}>
        <div>
          {routine === undefined ? (
            <>
              <DrawerNavGroup isLoading={true} label="" />
              <DrawerNavGroup isLoading={true} label="" />
              <DrawerNavGroup isLoading={true} label="" />
            </>
          ) : (
            <RoutineWorkouts {...routine} />
          )}
        </div>
      </DrawerSidebarScrollArea>
    </DrawerSidebar>
  );
};

export default RoutineFullSecondaryNavigation;
