import { ExerciseVolumeUnit } from '../../types';
import { ClientSetAttributes, MeasureUnit, SetMeasureType } from '../types';

const setMeasureTypeMeasureUnits = {
  [SetMeasureType.Distance]: [MeasureUnit.Meter, MeasureUnit.Yard],
  [SetMeasureType.Reps]: [MeasureUnit.Count],
  [SetMeasureType.Time]: [MeasureUnit.Seconds],
  [SetMeasureType.Weight]: [MeasureUnit.Kilogram, MeasureUnit.Pound],
};

const volumeUnitMeasureTypes = {
  [ExerciseVolumeUnit.Distance]: [
    SetMeasureType.Weight,
    SetMeasureType.Distance,
    SetMeasureType.Time,
  ],
  [ExerciseVolumeUnit.Reps]: [SetMeasureType.Reps, SetMeasureType.Weight],
  [ExerciseVolumeUnit.Time]: [
    SetMeasureType.Weight,
    SetMeasureType.Distance,
    SetMeasureType.Time,
  ],
};

/** @todo Can be improved */
const requiredVolumeUnitSetMeasureTypes = {
  [ExerciseVolumeUnit.Distance]: [SetMeasureType.Distance],
  [ExerciseVolumeUnit.Reps]: [SetMeasureType.Reps, SetMeasureType.Weight],
  [ExerciseVolumeUnit.Time]: [SetMeasureType.Time],
};

const config = {
  requiredVolumeUnitSetMeasureTypes,
  setMeasureTypeMeasureUnits,
  volumeUnitMeasureTypes,
};

export default config;

export const getHasSetAllRequiredMeasures = (set: ClientSetAttributes) => {
  // we don't count skipped sets here
  if (set.isSkipped === true) {
    return true;
  }

  // verify whether all required set measures are filled in
  return config.requiredVolumeUnitSetMeasureTypes[set.volumeUnit].every(
    type =>
      set.accomplished?.measures.some(m => m.type === type && m.value !== null),
  );
};

export const getPrimaryMeasure = (
  volumeUnit: ExerciseVolumeUnit,
  accomplished: ClientSetAttributes['accomplished'],
) =>
  accomplished?.measures.find(
    m => String(m.type) === String(volumeUnit) && m.value !== null,
  ) ?? null;

export const getSecondaryMeasures = (
  volumeUnit: ExerciseVolumeUnit,
  accomplished: ClientSetAttributes['accomplished'],
) =>
  accomplished?.measures.filter(measure => {
    return (
      String(measure.type) !== String(volumeUnit) && measure.value !== null
    );
  }) ?? null;
