import { IconPlus } from '@tabler/icons-react';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import StickyButton from '../../../components/common/StickyButton';
import HeaderMobile from '../../../components/layout/HeaderMobile';
import { PageId } from '../../../constants/page-ids';
import { useClientFull } from '../../../features/clients/api/queries/useClientFull';
import ClientHeaderActions from '../../../features/clients/components/ClientHeaderActions';
import ClientsSecondaryNavigation from '../../../features/clients/components/ClientsNavigation/ClientsSecondaryNavigation';
import { getUser } from '../../../features/user/api/cache/getters';
import useIsMobile from '../../../hooks/useIsMobile';
import { translate } from '../../../i18n';
import { getPageLabel } from '../../../i18n/helpers/page';
import { useParamClientId } from '../../../router/hooks/path-params';
import { routes } from '../../../router/routes';
import { ModalId, useModal } from '../../../utils/modal';

const ClientFullLayout: FC<{ clientId?: string }> = props => {
  const isMobile = useIsMobile();
  const paramClientId = useParamClientId();
  /**
   * Users of type "Coach" don't have clientId in the Url
   */
  const clientId = props.clientId ?? paramClientId;
  const { data: client } = useClientFull(clientId);
  const isMe = clientId === getUser()?.id;

  const { isModalOpen, openModal } = useModal();
  const isOpen = isModalOpen(ModalId.ClientRoutineCreate);

  if (clientId === null) {
    return <Navigate to={routes.home} />;
  }

  if (isMobile === false) {
    return <ClientsSecondaryNavigation />;
  }

  const requestRoutineCreate = () => {
    openModal(ModalId.ClientRoutineCreate, { clientId });
  };

  const shouldShowHeaderActions =
    client !== undefined && props.clientId === undefined;

  const pageId = isMe ? PageId.MyStuff : PageId.ClientsDetails;

  return (
    <>
      <HeaderMobile
        Action={() => (
          <>
            {shouldShowHeaderActions ? (
              <ClientHeaderActions client={client} />
            ) : null}
          </>
        )}
        title={getPageLabel(pageId)}
        withBackButton={isMe === false}
      />
      <StickyButton
        data-target={ModalId.ClientRoutineCreate}
        hidden={isOpen}
        Icon={IconPlus}
        onClick={requestRoutineCreate}
        tooltip={translate('routine.add')}
      />
    </>
  );
};

export default ClientFullLayout;
