import { TextInput } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SIDEBAR_COLOR } from '../../../constants/preferences';
import {
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../../router/helpers/searchParams';
import { SearchParam } from '../../../router/types';
import { getSearchParamSearch } from '../../../router/urls/searchParams/getters';
import SearchInput from './SearchInput';
import classes from './styles.css';

type Props = Omit<ComponentPropsWithoutRef<typeof SearchInput>, 'onDebounce'>;

const SearchInputUrl: FC<Props> = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchValueUrl = getSearchParamSearch(location) ?? '';

  const handleSearch = useDebouncedCallback((value: string) => {
    const currentSearch = getSearchParamSearch(location);

    if (currentSearch && !value) {
      navigate(removeParamsKeysFromUrl([SearchParam.Search]), {
        replace: true,
      });
      return;
    }

    if (currentSearch !== value) {
      const url = replaceOrAddParamsToUrl(
        { [SearchParam.Search]: value },
        location,
      );
      navigate(url, { replace: true });
    }
  }, 400);

  return (
    <TextInput
      autoFocus={searchValueUrl !== ''}
      classNames={classes}
      defaultValue={getSearchParamSearch(location) ?? ''}
      leftSection={<IconSearch size="0.9rem" stroke={1.5} />}
      onChange={e => handleSearch(e.target.value)}
      wrapperProps={{ style: { backgroundColor: SIDEBAR_COLOR } }}
      {...props}
    />
  );
};

export default SearchInputUrl;
