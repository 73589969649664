import { Identity, IdentityList, UserStatus } from '.';
import {
  ClientExerciseInstruction,
  ClientWorkout,
} from '../features/routine/@client/types';

export enum CoachingFormat {
  Blended = 'blended',
  FaceToFace = 'face_to_face',
  Virtual = 'virtual',
}

export interface Bodyweight {
  createdAt: string;
  id: string;
  value: number;
}

type ExInstrPartial = Pick<ClientExerciseInstruction, 'id' | 'sets'>;

type ClientsListWorkoutCommon = {
  exerciseInstructions: ExInstrPartial[];
} & Pick<ClientWorkout, 'id' | 'name' | 'routineId'>;

export type ClientListWorkoutInProgress = ClientsListWorkoutCommon;

export type LastCompletedWorkout = ClientsListWorkoutCommon &
  Pick<ClientWorkout, 'clientId' | 'completedAt'>;

interface ClientCommon {
  readonly businessId: null | string;
  coachId: null | string;
  coachingFormat: CoachingFormat | null;
  readonly email: string;
  readonly id: string;
  lastCompletedWorkout: LastCompletedWorkout | null;
  routineInProgress: null | string;
  workoutsInProgress: ClientListWorkoutInProgress[];
}

export interface Client extends ClientCommon {
  bodyWeights: { createdAt: string; id: string; value: number }[];
  businessId: null | string;
  checkIns: CheckInAttributes[];
  coachDetails: {
    avatarUrl: null | string;
    birthday: null | string;
    contactEmail: string;
    firstName: string;
    lastName: string;
    phone: null | string;

    website?: string;
  };
  facilityMember: {
    facilityId: string;
    userId: string;
  } | null;
  identity: Identity;
  inviteDetails: {
    acceptedAt: null | string;
  };
  status: UserStatus;
  userId: string;
}

export interface ClientListItem extends ClientCommon {
  coachId: null | string;
  facilityId: null | string;
  identity: IdentityList;
  status: UserStatus;
}

export enum CheckInType {
  Energy = 'energy',
  Mood = 'mood',
  Sleep = 'sleep',
  Soreness = 'soreness',
}

export type CheckInValue = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface CheckInAttributes extends Record<CheckInType, number> {
  createdAt: string;
  id: string;
  userId?: string;
}

export interface UpdateClientAttributes {
  coachingFormat: CoachingFormat | null;
  firstName: string;
  lastName: string;
  status: UserStatus;
}
