import { useMutation } from '@tanstack/react-query';

import http from '../../../../../config/http';
import { getClientRoutineFull } from '../../../@client/api/cache/getters';
import { invalidateClientRoutine } from '../../../@client/api/queries/useClientRoutineFullQuery';
import { invalidateClientRoutines } from '../../../@client/api/queries/useClientRoutinesQuery';
import {
  cancelTemplateRoutineQuery,
  invalidateTemplateRoutine,
} from '../../../@template/api/queries/useTemplateRoutineFullQuery';
import {
  cancelTemplateRoutinesQuery,
  invalidateTemplateRoutines,
} from '../../../@template/api/queries/useTemplateRoutinesQuery';
import { invalidateTemplateWorkouts } from '../../../@template/api/queries/useTemplateWorkoutsQuery';
import { ConversionType, RequestConversion } from '../../../types/conversion';

const convert = (conversion: RequestConversion) => {
  return http.post('/conversions', conversion);
};

export const useConversion = () => {
  return useMutation({
    mutationFn: convert,

    onMutate(variables) {
      switch (variables.conversionType) {
        case ConversionType.StandaloneTemplateWorkoutToTemplateRoutineWorkout: {
          cancelTemplateRoutinesQuery();
          cancelTemplateRoutineQuery(variables.params.templateRoutineId);
          break;
        }
      }
    },
  });
};

// eslint-disable-next-line complexity
export const handleConversionSuccess = (variables: RequestConversion) => {
  switch (variables.conversionType) {
    case ConversionType.ClientRoutineToTemplateRoutine: {
      invalidateTemplateRoutines({ refetchType: 'all' });
      break;
    }
    case ConversionType.ClientWorkoutToStandaloneTemplateWorkout:
    case ConversionType.TemplateRoutineWorkoutToStandaloneTemplateWorkout: {
      invalidateTemplateWorkouts({ refetchType: 'all' });
      break;
    }
    case ConversionType.StandaloneTemplateWorkoutToClientWorkout: {
      const routine = getClientRoutineFull(variables.params.routineId);
      routine !== null && invalidateClientRoutines(routine.clientId);
      invalidateClientRoutine(variables.params.routineId);
      break;
    }
    case ConversionType.StandaloneTemplateWorkoutToTemplateRoutineWorkout: {
      invalidateTemplateRoutines({ refetchType: 'all' });
      invalidateTemplateRoutine(variables.params.templateRoutineId);
      break;
    }
    case ConversionType.TemplateRoutineToClientRoutine: {
      invalidateClientRoutines(variables.params.clientId);
      break;
    }
  }
};
