import { useLocation } from 'react-router-dom';

import { BreadcrumbItem } from '../../../components/common/Breadcrumbs';
import { useExercise } from '../../../features/routine/@exercise/api/queries/useExercises';
import { translate } from '../../../i18n';
import { useParamExerciseId } from '../../../router/hooks/path-params';
import { routes } from '../../../router/routes';
import { getSearchParamExerciseCategory } from '../../../router/urls/searchParams/getters';

const useBreadcrumbs = (): BreadcrumbItem[] => {
  const location = useLocation();

  const exerciseId = useParamExerciseId();
  const exercise = useExercise(exerciseId);

  const exerciseCategory = getSearchParamExerciseCategory(location) ?? 'my';

  const items: BreadcrumbItem[] = [
    {
      content: translate(`exercise.exercisesView.${exerciseCategory}.long`),
      href: routes.library.exercises.root(exerciseCategory),
      isLoading: false,
    },
  ];

  if (exerciseId !== null) {
    items.push({
      content: exercise?.name,
      href: routes.library.exercises.byId(exerciseId),
      isLoading: exercise === null,
      label: translate('exercise.label'),
    });
  }

  return items;
};

export default useBreadcrumbs;
