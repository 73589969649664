/** @file Contains setters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import {
  getDataById,
  insertData,
  removeDataById,
  updateDataById,
} from '../../../../../utils/array';
import { MoveExerciseInstruction, MoveWorkout } from '../../../types';
import {
  ClientExerciseInstruction,
  ClientRoutineFull,
  ClientRoutineList,
  ClientWorkout,
} from '../../types';
import { getClientRoutineFull, getClientRoutines } from './getters';
import { transformClientRoutineFullToList } from './helpers';
import { setClientRoutineFull, setClientRoutines } from './setters';
import { MoveClientRoutine } from './types';

/**
 * Updates the cache when routine is created
 * @param routine The new routine we want to set
 * @param qc      QueryClient
 */
export const createClientRoutine = (
  routine: ClientRoutineFull,
  qc: QueryClient = queryClient,
) => {
  setClientRoutineFull(routine.id, routine);
  const clientRoutines = getClientRoutines(routine.clientId, qc) ?? [];
  setClientRoutines(
    routine.clientId,
    [...clientRoutines, transformClientRoutineFullToList(routine)],
    qc,
  );
};

/**
 * Updates the cache when routine is deleted
 * @param routineId The ID of the routine we want to delete
 * @param clientId  The ID of the client, the routine belongs
 * @param qc        QueryClient
 */
export const deleteClientRoutine = (
  routineId: string,
  clientId: string,
  qc: QueryClient = queryClient,
) => {
  setClientRoutineFull(routineId, undefined);
  const clientRoutines = getClientRoutines(clientId, qc);

  if (clientRoutines !== null) {
    setClientRoutines(clientId, removeDataById(clientRoutines, routineId));
  }
};

/**
 * Updates the cache for full routine and list routine
 * @param routineId The ID of the routine we want to delete
 * @param data      The routine data we want to update
 * @param qc        QueryClient
 */
export const setClientRoutineAll = (
  routineId: string,
  data: ClientRoutineFull,
  qc = queryClient,
) => {
  setClientRoutineFull(routineId, data);

  const clientRoutines = getClientRoutines(data.clientId, qc);

  if (clientRoutines !== null) {
    setClientRoutines(
      data.clientId,
      updateDataById(
        clientRoutines,
        routineId,
        transformClientRoutineFullToList(data),
      ),
    );
  }
};

/**
 * Updates the cache when routine is edited
 * @param routineId The ID of the routine we want to delete
 * @param data      The routine data we want to update
 * @param qc        QueryClient
 */
export const editClientRoutine = (
  routineId: string,
  data: Partial<ClientRoutineFull>,
  qc: QueryClient = queryClient,
) => {
  const routineFull = getClientRoutineFull(routineId);

  if (routineFull === null) {
    return;
  }

  const updated: ClientRoutineFull = { ...routineFull, ...data };

  setClientRoutineAll(routineId, updated, qc);
};

/**
 * Deletes a cycle and related workouts from the client routine
 * @param routineId The routine ID to whom the cycle belongs
 * @param cycleId   The INDEX of the cycle we want to delete
 * @param qc        QueryClient
 */
export const deleteClientRoutineCycle = (
  routineId: string,
  cycleId: number,
  qc = queryClient,
) => {
  const routine = getClientRoutineFull(routineId, qc);

  if (routine !== null) {
    editClientRoutine(
      routineId,
      {
        cycles: routine.cycles.filter((_, i) => i !== cycleId),
        workouts: routine.workouts
          .filter(workout => workout.cycleId !== cycleId)
          .map(workout =>
            workout.cycleId > cycleId
              ? { ...workout, cycleId: workout.cycleId - 1 }
              : workout,
          ),
      },
      qc,
    );
  }
};

/**
 * Updates the cache when client workout is edited
 * @param routineId The ID of the routine the workout belongs to
 * @param workoutId The ID of the workout we want to edit.
 * @param data      The workout data we want to update
 * @param qc        QueryClient
 */
export const editClientWorkout = (
  routineId: string,
  workoutId: string,
  data: Partial<ClientWorkout>,
  qc: QueryClient = queryClient,
) => {
  const routineFull = getClientRoutineFull(routineId);

  if (routineFull === null) {
    return;
  }

  const workout = getDataById(routineFull.workouts, workoutId);

  if (workout === null) {
    return;
  }

  const updatedRoutine: ClientRoutineFull = {
    ...routineFull,
    workouts: updateDataById(routineFull.workouts, workoutId, data),
  };

  setClientRoutineAll(routineId, updatedRoutine, qc);
};

/**
 * Updates the cache when client workout is created
 * @param workout New workout
 * @param qc      QueryClient
 */
export const createClientWorkout = (
  workout: ClientWorkout,
  qc: QueryClient = queryClient,
) => {
  const routineFull = getClientRoutineFull(workout.routineId, qc);

  if (routineFull === null) {
    return;
  }

  const positionToInsert = routineFull.workouts.findLastIndex(wo => {
    if (wo.cycleId > workout.cycleId) return false;

    return true;
  });

  const updated = {
    ...routineFull,
    workouts: insertData(
      routineFull.workouts,
      workout,
      positionToInsert < 0 ? 0 : positionToInsert + 1,
    ),
  };

  setClientRoutineAll(workout.routineId, updated, qc);
};

/**
 * Updates the client routines cache after there was a change in routine status
 * @param param0           Drag and drop payload
 * @param param0.clientId  The ID of the client the routine belongs to
 * @param param0.routineId The id of the routine
 * @param param0.position  The position where it was moved
 * @param param0.status    The status it was moved to
 */
export const moveClientRoutine = ({
  clientId,
  position,
  routineId,
  status,
}: MoveClientRoutine) => {
  const clientRoutines = getClientRoutines(clientId);

  if (clientRoutines === null) {
    return;
  }

  const movedRoutine = getDataById(clientRoutines, routineId);

  if (movedRoutine === null) {
    console.error('Routine not found');
    return;
  }

  const mappedStatusRoutines = clientRoutines.reduce(
    (mapped, routine) => {
      if (routine.id === routineId) {
        return mapped;
      }

      if (mapped[routine.status] === undefined) {
        mapped[routine.status] = [routine];
      } else {
        mapped[routine.status]?.push(routine);
      }

      return mapped;
    },
    {} as Partial<Record<ClientRoutineList['status'], ClientRoutineList[]>>,
  );

  const updated = { ...movedRoutine, position, status };

  if (mappedStatusRoutines[status] === undefined) {
    mappedStatusRoutines[status] = [updated];
  } else {
    mappedStatusRoutines[status]?.splice(position, 0, updated);
  }

  const originalStatus = movedRoutine.status;

  mappedStatusRoutines[originalStatus]?.forEach((routine, index) => {
    routine.position = index;
  });

  if (originalStatus !== status) {
    mappedStatusRoutines[status]?.forEach((routine, index) => {
      routine.position = index;
    });
  }

  setClientRoutines(clientId, Object.values(mappedStatusRoutines).flat());
};

/**
 * Updates the client routines cache after there was a change in exercise instruction ordering
 * @param param0                       Drag and drop payload
 * @param param0.exerciseInstructionId The ID of the exercise instruction we want to move
 * @param param0.groupId               The groupId it was moved to
 * @param param0.position              The position where it was moved
 * @param param0.routineId             The id of the routine
 * @param param0.workoutId             The id of the workout
 */
export const moveExerciseInstruction = ({
  exerciseInstructionId,
  groupId,
  position,
  routineId,
  workoutId,
}: MoveExerciseInstruction) => {
  const routine = getClientRoutineFull(routineId);

  if (routine === null) {
    return;
  }

  const workout = getDataById(routine.workouts, workoutId);

  if (workout === null) {
    console.error('Workout not found');
    return;
  }

  const copy = structuredClone(workout);

  const mappedByGroup = copy.exerciseInstructions.reduce((mapped, exInstr) => {
    if (mapped[exInstr.groupId] === undefined) {
      mapped[exInstr.groupId] = [exInstr];
    } else {
      mapped[exInstr.groupId].push(exInstr);
    }
    return mapped;
  }, [] as ClientExerciseInstruction[][]);

  const toMoveExerciseInstruction = getDataById(
    copy.exerciseInstructions,
    exerciseInstructionId,
  );

  if (toMoveExerciseInstruction === null) {
    console.error('Exercise Instruction Not Found');
    return;
  }

  // Remove from source position
  mappedByGroup[toMoveExerciseInstruction.groupId].splice(
    toMoveExerciseInstruction.position,
    1,
  );

  // Moved to an empty group
  if (mappedByGroup[groupId] === undefined) {
    mappedByGroup[groupId] = [];
  }

  // insert at destination group
  mappedByGroup[groupId].splice(position, 0, {
    ...toMoveExerciseInstruction,
    groupId,
    position,
  });

  // re index destination group
  mappedByGroup[groupId].forEach((exInstr, i) => (exInstr.position = i));

  // moved to different group
  if (toMoveExerciseInstruction.groupId !== groupId) {
    // re index source group
    mappedByGroup[toMoveExerciseInstruction.groupId].forEach(
      (exInstr, i) => (exInstr.position = i),
    );
  }

  const updatedWorkout = {
    ...workout,
    exerciseInstructions: mappedByGroup.flat(),
  };

  editClientWorkout(routineId, workoutId, updatedWorkout);
};

/**
 * Updates the client routine cache after there was a change in exercise instruction ordering
 * @param param0           Drag and drop payload
 * @param param0.cycleId   The cycleId it was moved to
 * @param param0.position  The position where it was moved
 * @param param0.routineId The id of the routine
 * @param param0.workoutId The id of the workout
 */
export const moveClientWorkout = ({
  cycleId,
  position,
  routineId,
  workoutId,
}: MoveWorkout) => {
  const routine = getClientRoutineFull(routineId);

  if (routine === null) {
    console.error('Routine not found');
    return;
  }

  const copy = structuredClone(routine);

  const toMoveWorkout = getDataById(copy.workouts, workoutId);

  if (toMoveWorkout === null) {
    console.error('Workout Not Found');
    return;
  }

  const mappedByCycle = copy.workouts.reduce((mapped, workout) => {
    if (mapped[workout.cycleId] === undefined) {
      mapped[workout.cycleId] = [workout];
    } else {
      mapped[workout.cycleId].push(workout);
    }
    return mapped;
  }, [] as ClientWorkout[][]);

  // Remove from source position
  mappedByCycle[toMoveWorkout.cycleId].splice(toMoveWorkout.position, 1);

  // Moved to a cycle that has no workouts. Empty cycles are not included in reduce
  if (mappedByCycle[cycleId] === undefined) {
    mappedByCycle[cycleId] = [];
  }

  // insert at destination group
  mappedByCycle[cycleId].splice(position, 0, {
    ...toMoveWorkout,
    cycleId,
    position,
  });

  // re index destination group
  mappedByCycle[cycleId].forEach((exInstr, i) => (exInstr.position = i));

  // moved to different group
  if (toMoveWorkout.cycleId !== cycleId) {
    // re index source group
    mappedByCycle[toMoveWorkout.cycleId].forEach(
      (exInstr, i) => (exInstr.position = i),
    );
  }

  const updatedRoutine = {
    ...routine,
    workouts: mappedByCycle.flat(),
  };

  editClientRoutine(routineId, updatedRoutine);
};
