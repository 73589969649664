import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';

import http, { ApiResponse } from '../../../../../config/http';
import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { getUser } from '../../../../user/api/cache/getters';
import { Exercise, ExerciseCategory, ExerciseList } from '../../../types';

let cachedExercisesMap: null | Record<string, Exercise> = null;

async function fetchExercises(): Promise<Exercise[]> {
  const { data } = await http.get<ApiResponse<Exercise[]>>('/exercises');

  return data.data;
}

export const useExercises = (): UseQueryResult<Exercise[]> => {
  return useQuery({ queryFn: fetchExercises, queryKey: QueryKey.Exercises() });
};

export const invalidateExercises = () => {
  cachedExercisesMap = null;
  queryClient.invalidateQueries({ queryKey: QueryKey.Exercises() });
};

export const cancelExercisesQuery = () =>
  queryClient.cancelQueries({ queryKey: QueryKey.Exercises() });

export const useExercisesCategoryMap = (): Record<
  ExerciseCategory,
  Exercise[]
> => {
  const { data } = useExercises();
  return useMemo(() => {
    if (data === undefined) {
      return {
        company: [],
        my: [],
        strenco: [],
      } as ExerciseList;
    }

    return data.reduce(
      (mapped, exercise) => {
        if (exercise.isPublic === true) {
          mapped.strenco.push(exercise);
        }

        if (exercise.createdBy === getUser()?.id) {
          mapped.my.push(exercise);
        }

        if (exercise.businessId) {
          mapped.company.push(exercise);
        }

        return mapped;
      },
      {
        company: [],
        my: [],
        strenco: [],
      } as ExerciseList,
    );
  }, [data]);
};

export const useExercisesMap = () => {
  const { data: exercises } = useExercises();

  return useMemo(() => {
    if (exercises === undefined) {
      return {};
    }

    if (cachedExercisesMap === null) {
      cachedExercisesMap = exercises.reduce(
        (mapped, exercise) => {
          mapped[exercise.id] = exercise;
          return mapped;
        },
        {} as Record<string, Exercise>,
      );
    }

    return cachedExercisesMap;
  }, [exercises]);
};

export const useExercise = (exerciseId: null | string) => {
  const exercisesMap = useExercisesMap();

  return exerciseId ? (exercisesMap[exerciseId] ?? null) : null;
};
