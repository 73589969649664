import { modals } from '@mantine/modals';

import { iconsMap } from '../../../../components/common/ActionIcons/config';
import { MenuItem } from '../../../../components/ui/menus/CustomMenu';
import { MutationDelete } from '../../../../constants/mutation-keys';
import { translate } from '../../../../i18n';
import { SearchParam } from '../../../../router/types';
import { getApiErrorCode } from '../../../../utils/errors';
import { ModalId, useModal } from '../../../../utils/modal';
import { confirmDanger } from '../../../../utils/modal/confirm';
import { Exercise } from '../../types';
import { useExerciseDelete } from '../api/mutations/useExerciseDelete';
import { useExerciseDuplicate } from '../api/mutations/useExerciseDuplicate';
import ExerciseInUseModalContent from '../components/ExerciseInUseModalContent';
import { getDuplicateTooltip } from '../components/ExercisesTable/ExerciseRow/helpers';
import { ExerciseInUseApiErrorDetails } from '../types';
import { canDeleteExercise, canEditExercise } from '../utils/permissions';

const useExerciseActions = () => {
  const { isPending: isDeleting, mutateAsync: deleteExercise } =
    useExerciseDelete();
  const { openModal } = useModal();
  const { isPending: isDuplicating, mutateAsync: duplicateExercise } =
    useExerciseDuplicate();

  const requestCreate = () => {
    openModal(ModalId.Exercise);
  };

  const requestDelete = (exerciseId: string) => {
    confirmDanger({
      key: MutationDelete.Exercise,
      onConfirm: () =>
        deleteExercise(exerciseId).catch(errorResponse => {
          if (
            getApiErrorCode(errorResponse) ===
            'ExerciseDeleteUsedInExerciseInstructions'
          ) {
            const details: ExerciseInUseApiErrorDetails =
              errorResponse?.response?.data?.details;

            if (details !== undefined) {
              modals.open({
                children: (
                  <ExerciseInUseModalContent
                    {...details}
                    exerciseId={exerciseId}
                    onChange={() => requestDelete(exerciseId)}
                  />
                ),
                size: 'xl',
                title: errorResponse?.response?.data?.message,
              });
            }
          }
        }),
    });
  };

  const requestDuplicate = async (exerciseId: string) => {
    const response = await duplicateExercise(exerciseId);
    openModal(ModalId.Exercise, { [SearchParam.ExerciseId]: response.id });
  };

  const requestEdit = (exerciseId: string) => {
    openModal(ModalId.Exercise, { [SearchParam.ExerciseId]: exerciseId });
  };

  return {
    isDeleting,
    isDuplicating,
    requestCreate,
    requestDelete,
    requestDuplicate,
    requestEdit,
  };
};

export const useExerciseActionItems = (
  exercise: Pick<Exercise, 'createdBy' | 'id' | 'isPublic'>,
) => {
  const {
    isDeleting,
    isDuplicating,
    requestDelete,
    requestDuplicate,
    requestEdit,
  } = useExerciseActions();
  const items: MenuItem[] = [];

  const isLoading = isDeleting || isDuplicating;

  if (canEditExercise(exercise)) {
    items.push({
      Icon: iconsMap.edit.Icon,
      isLoading,
      label: translate('exercise.edit'),
      onClick: () => requestEdit(exercise.id),
    });
  }

  items.push({
    Icon: iconsMap.edit.Icon,
    isLoading,
    label: getDuplicateTooltip(exercise),
    onClick: () => requestDuplicate(exercise.id),
  });

  if (canDeleteExercise(exercise)) {
    items.push({
      Icon: iconsMap.delete.Icon,
      isLoading,
      label: translate('exercise.delete'),
      onClick: () => requestDelete(exercise.id),
    });
  }

  return items;
};

export default useExerciseActions;
