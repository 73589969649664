import { Group, Stack, Title } from '@mantine/core';

import {
  HEADER_AND_RAIL_COLOR,
  HEADER_HEIGHT,
  RAIL_DRAWER_WIDTH,
  SIDEBAR_COLOR,
} from '../../../constants/preferences';
import { vars } from '../../../theme';

export const Wrapper = Stack.withProps({
  bg: HEADER_AND_RAIL_COLOR,
  gap: 0,
  mih: '100svh',
  miw: RAIL_DRAWER_WIDTH,
  w: RAIL_DRAWER_WIDTH,
});

export const Header = Group.withProps({
  align: 'center',
  h: HEADER_HEIGHT,
  pl: 'md',
});

export const Heading = Title.withProps({
  c: 'white',
  fw: 600,
  lts: 2,
  order: 5,
  tt: 'uppercase',
});

export const WrapperInner = Stack.withProps({
  bg: SIDEBAR_COLOR,
  flex: 1,
  gap: 0,
  mah: `calc(100dvh - ${HEADER_HEIGHT}px)`,
  pos: 'relative',
  pt: 'md',
  style: { borderTopLeftRadius: vars.radius.md, overflow: 'hidden' },
});
