import { FC } from 'react';

import { ActionIconHeaderActions } from '../../../../../components/common/ActionIcons';
import CustomMenu from '../../../../../components/ui/menus/CustomMenu';
import { translate } from '../../../../../i18n';
import { Exercise } from '../../../types';
import { useExerciseActionItems } from '../../hooks/useExerciseActions';

interface Props {
  exercise: Exercise;
}

const ExerciseHeaderActions: FC<Props> = ({ exercise }) => {
  const items = useExerciseActionItems(exercise);

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      tooltip={translate('exercise.actions')}
      trigger={<ActionIconHeaderActions />}
    />
  );
};

export default ExerciseHeaderActions;
