import { FILE__AVATAR_SIZE_KB_LIMIT } from '../../constants/preferences';
import {
  ToastErrorId,
  ToastInfoId,
  ToastSuccessId,
  ToastWarningId,
} from '../../constants/toast-ids';

interface Props {
  text: string;
  title: string;
}

const translationsError: Record<ToastErrorId, Props> = {
  [ToastErrorId.AvatarUploadLarge]: {
    text: `The maximum allowed file size for avatars is ${FILE__AVATAR_SIZE_KB_LIMIT}KB. Please choose a smaller image or use an image-resize tool like <a href="https://tinypng.com" target="_blank"><i>tinypng</i></a>`,
    title: 'The selected image is to large.',
  },
  [ToastErrorId.General]: {
    text: 'Try later or contact support.',
    title: 'Something went wrong',
  },
  [ToastErrorId.LoginUserClientForbidden]: {
    text: 'Access to this app is restricted to coaches only.',
    title: 'Access Forbidden',
  },
  [ToastErrorId.PasswordChange]: {
    text: 'The password you provided is incorrect. Please check your password and try again.',
    title: 'Invalid Password',
  },
  [ToastErrorId.ResendVerificationCode]: {
    text: 'An error occurred while sending the verification code.',
    title: 'Something Went Wrong',
  },
  [ToastErrorId.SubscriptionUpdate]: {
    text: 'An error occurred while updating your subscription. Please try again later or contact support.',
    title: 'Subscription update failed',
  },
};

const translationsSuccess: Record<ToastSuccessId, Props> = {
  [ToastSuccessId.BusinessAdminCreate]: {
    text: 'A new admin has been successfully added to your business account.',
    title: 'Admin Added Successfully',
  },
  [ToastSuccessId.BusinessAdminDelete]: {
    text: 'The admin has been removed from your business account.',
    title: 'Admin Removed',
  },
  [ToastSuccessId.BusinessCoachCreate]: {
    text: 'A new coach has been successfully added to your business team.',
    title: 'Coach Added Successfully',
  },
  [ToastSuccessId.BusinessCoachDelete]: {
    text: 'The coach has been removed from your business account.',
    title: 'Coach Removed',
  },
  [ToastSuccessId.BusinessFacilityCreate]: {
    text: 'A new facility has been successfully added to your business.',
    title: 'Facility Added Successfully',
  },
  [ToastSuccessId.BusinessFacilityDelete]: {
    text: 'The facility has been removed from your business.',
    title: 'Facility Removed',
  },
  [ToastSuccessId.ClientCreate]: {
    text: 'The client has been successfully added. They will receive an email with instructions to get started.',
    title: 'Client Added Successfully',
  },
  [ToastSuccessId.ClientDelete]: {
    text: 'The client has been removed and will no longer have access to the app.',
    title: 'Client Removed',
  },
  [ToastSuccessId.ClientRoutineGenerated]: {
    text: 'A routine has been generated for your client.',
    title: 'Client Routine Generated',
  },
  [ToastSuccessId.PasswordChange]: {
    text: 'Your password has been successfully updated. You can now log in with your new password.',
    title: 'Password Updated',
  },
  [ToastSuccessId.PasswordForgot]: {
    text: 'A password reset link has been sent to your email. Follow the instructions to reset your password.',
    title: 'Password Reset Email Sent',
  },
  [ToastSuccessId.PasswordReset]: {
    text: 'Your password has been reset successfully. You can now log in with the new password.',
    title: 'Password Reset Complete',
  },
  [ToastSuccessId.ResendVerificationCode]: {
    text: 'A new verification code has been sent to your email. Please check your inbox to verify your account.',
    title: 'Verification Code Resent',
  },
  [ToastSuccessId.SignUp]: {
    text: 'Sign-up successful! Please check your email for a verification link to activate your account.',
    title: 'Sign-Up Complete',
  },
  [ToastSuccessId.SubscriptionUpdated]: {
    text: 'Your subscription plan has been upgraded. Please log out and log back in if you don’t see the changes immediately.',
    title: 'Subscription Updated',
  },
  [ToastSuccessId.TemplateRoutineGenerated]: {
    text: 'Use it to generate routines for your clients faster!',
    title: 'Routine Template Created',
  },
  [ToastSuccessId.TemplateWorkoutGenerated]: {
    text: 'Use it to create routines faster!',
    title: 'Workout Template Created',
  },
};

const translationsWarning: Record<ToastWarningId, Props> = {
  [ToastWarningId.InternetConnection]: {
    text: 'Uh-oh! It seems like your internet connection is down.',
    title: 'Connection Lost',
  },
};

const translationsInfo: Record<ToastInfoId, Props> = {
  [ToastInfoId.InternetConnection]: {
    text: 'You can continue using the app without any issues.',
    title: 'Connected to the Internet',
  },
};

const translationsToast = {
  ...translationsError,
  ...translationsSuccess,
  ...translationsWarning,
  ...translationsInfo,
};

export default translationsToast;
