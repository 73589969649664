/**
 * @file We use ids to identify forms
 * The translations can be found in {@link file://./../../i18n/en/form.ts}
 */

/**
 * Ids of forms we use to identify translations and more
 */
export enum FormId {
  BodyweightCreate = 'Form_BodyweightCreate',
  BusinessAdminCreate = 'Form_BusinessAdminCreate',
  BusinessAdminEdit = 'Form_BusinessAdminEdit',
  BusinessCoachCreate = 'Form_BusinessCoachCreate',
  BusinessCoachEdit = 'Form_BusinessCoachEdit',
  BusinessCoachRankCreate = 'Form_BusinessCoachRankCreate',
  BusinessCoachRankEdit = 'Form_BusinessCoachRankEdit',
  BusinessFacilityCreate = 'Form_BusinessFacilityCreate',
  BusinessFacilityEdit = 'Form_BusinessFacilityEdit',
  CheckInCreate = 'Form_CheckInCreate',
  ClientCreate = 'Form_ClientCreate',
  ClientEdit = 'Form_ClientEdit',
  ClientRoutineDetailsCreate = 'Form_ClientRoutineDetailsCreate',
  ClientRoutineDetailsEdit = 'Form_ClientRoutineDetailsEdit',
  ClientWorkoutDetailsCreate = 'Form_ClientWorkoutDetailsCreate',
  ClientWorkoutDetailsEdit = 'Form_ClientWorkoutDetailsEdit',
  CommentsCreator = 'Form_CommentsCreator',
  CycleCreate = 'Form_CycleCreate',
  CycleEdit = 'Form_CycleEdit',
  ExerciseCreate = 'Form_ExerciseCreate',
  ExerciseEdit = 'Form_ExerciseEdit',
  PasswordChange = 'Form_PasswordChange',
  PasswordForgot = 'Form_PasswordForgot',
  PasswordNew = 'Form_PasswordNew',
  PasswordReset = 'Form_PasswordReset',
  RoutineCreate = 'Form_RoutineCreate',
  RoutineGenerate = 'Form_RoutineGenerate',
  SignIn = 'Form_SignIn',
  SignUp = 'Form_SignUp',
  SubscriptionChange = 'Form_SubscriptionChange',
  TemplateRoutineDetailsCreate = 'Form_TemplateRoutineDetailsCreate',
  TemplateRoutineDetailsEdit = 'Form_TemplateRoutineDetailsEdit',
  TemplateWorkoutDetailsCreate = 'Form_TemplateWorkoutDetailsCreate',
  TemplateWorkoutDetailsEdit = 'Form_TemplateWorkoutDetailsEdit',
  UserProfileEdit = 'Form_UserProfileEdit',
}
