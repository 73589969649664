/**
 * @file Contains getters for user cache
 */

import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { User } from '../../types';

/**
 * Get user data object from cache
 * @returns User if exists, otherwise null
 */
export const getUser = (): null | User => {
  const user = queryClient.getQueryData<User>(QueryKey.User()) ?? null;

  return user;
};
