import { FC } from 'react';

import DrawerBackButton from '../../../../../components/common/DrawerBackButton';
import useBackNavigation from '../../../../../hooks/useGetBackUrl';
import {
  useParamClientId,
  useParamWorkoutId,
} from '../../../../../router/hooks/path-params';
import { getBackButtonText } from './helpers';

const BackButton: FC = () => {
  const paramClientId = useParamClientId();
  const paramWorkoutId = useParamWorkoutId();

  const { getBackUrl } = useBackNavigation();

  const backUrl = paramWorkoutId === null ? null : getBackUrl();

  return (
    <DrawerBackButton backUrl={backUrl}>
      {getBackButtonText(paramClientId, paramWorkoutId)}
    </DrawerBackButton>
  );
};

export default BackButton;
