import { Alert, Box, Group } from '@mantine/core';
import { FC, ReactNode } from 'react';

import { AlertId } from '../../../constants/alert';
import { CLASS_NAME__EMPTY_HIDDEN } from '../../../constants/selectors';
import { translate } from '../../../i18n';
import { alertConfig, alertLevelConfig } from './config';

interface Props {
  alertId: AlertId;
  button?: ReactNode;
  onClose?: () => void;
}

const CustomAlert: FC<Props> = ({ alertId, button = null, onClose }) => {
  const title = translate(`alert.${alertId}.title`);
  const description = translate(`alert.${alertId}.description`);
  const { level } = alertConfig[alertId];
  const { color, Icon } = alertLevelConfig[level];

  return (
    <Alert
      closeButtonLabel={translate('common.dismiss')}
      color={color}
      icon={<Icon />}
      onClose={onClose}
      radius="md"
      title={title}
      variant="light"
      withCloseButton={typeof onClose === 'function'}
    >
      <Group>
        {description}
        <Box
          className={CLASS_NAME__EMPTY_HIDDEN}
          ml="auto"
          style={{ flexShrink: 0 }}
        >
          {button}
        </Box>
      </Group>
    </Alert>
  );
};

export default CustomAlert;
