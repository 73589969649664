import { Identity, UserStatus } from '../../types';
import { PaddleSupportedCountries } from '../../types/locale';
import {
  PaddleProductType,
  PaddleProductVariant,
  PaddleSubscriptionStatus,
} from '../../types/product.types';

export enum UserType {
  Admin = 'Admin', // Strenco Admin
  Client = 'Client', // Trainees that the coaches train
  Coach = 'Coach', // Individual coach, that can train clients
  CompanyAdmin = 'CompanyAdmin', // Company admin, can create exercises, coaches, assign clients to coaches
  CompanyCoach = 'CompanyCoach', // Coach that belongs to a company
  CompanyOwner = 'CompanyOwner', // The owner of the company
  New = 'New', // Newly signed up user
  Personal = 'Personal', // Can create workouts, exercises for themselves
  SuperAdmin = 'SuperAdmin', // Strenco only
}

export interface User {
  avatarUrl: null | string;
  businessId: null | string;
  customerId: null | string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  status: UserStatus;
  subscriptionMaxUsers: number;
  subscriptionPriceId: null | string;
  subscriptionStatus: null | PaddleSubscriptionStatus;
  subscriptionTier: number;
  subscriptionType: null | PaddleProductType;
  subscriptionVariant: null | PaddleProductVariant;
  type: UserType;
}

export enum AccountType {
  Business = 'business',
  Individual = 'individual',
}

export interface SignUpFormData {
  accountType: AccountType;
  business: {
    name: string;
    taxIdentifier: null | string;
  } | null;
  confirmPassword: string;
  countryCode: null | PaddleSupportedCountries;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  postalCode: null | string;
}

export interface BusinessAttributes {
  avatarUrl: null | string;
  email: null | string;
  readonly id: string;
  name: string;
  readonly ownerId: string;
  taxIdentifier: null | string;
}

export interface CustomerAttributes {
  countryCode: null | string;
  paddleAddressId: null | string;
  paddleBusinessId: null | string;
  paddleCustomerId: null | string;
  postalCode: null | string;
  region: null | string;
  readonly userId: string;
}

export interface UserExtended {
  adminDetails: { businessId: string } | null;
  /**
   * Only business owner has this
   */
  businessDetails: BusinessAttributes | null;
  clientDetails: {
    coachId: null | string;
    status: UserStatus;
  } | null;
  coachDetails: {
    businessDetails: { name: string };
    businessId: string;
    rankId: number;
    status: UserStatus;
  } | null;
  customerDetails: CustomerAttributes;
  email: string;
  id: string;
  identity: Identity;
  type: UserType;
}
