import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ActionIconHeaderActions } from '../../../../../components/common/ActionIcons';
import { iconsMap } from '../../../../../components/common/ActionIcons/config';
import CustomMenu, {
  MenuItem,
} from '../../../../../components/ui/menus/CustomMenu';
import { MutationDelete } from '../../../../../constants/mutation-keys';
import { translate } from '../../../../../i18n';
import { useParamRoutineId } from '../../../../../router/hooks/path-params';
import useCurrentPath from '../../../../../router/hooks/useCurrentPath';
import { getBackUrl } from '../../../../../router/paths/back-paths';
import { PATH_FRAGMENT__CLIENT_ID } from '../../../../../router/paths/path-fragments';
import { PATH__ME } from '../../../../../router/paths/paths';
import { SearchParam } from '../../../../../router/types';
import { ModalId, useModal } from '../../../../../utils/modal';
import { confirmDanger } from '../../../../../utils/modal/confirm';
import toast, { ToastSuccessId } from '../../../../../utils/toast';
import { useClientFull } from '../../../../clients/api/queries/useClientFull';
import { useConversion } from '../../../@conversion/api/mutations/useConversion';
import { invalidateTemplateRoutines } from '../../../@template/api/queries/useTemplateRoutinesQuery';
import { ConversionType } from '../../../types/conversion';
import { useClientRoutineDelete } from '../../api/mutations/useClientRoutineDelete';
import { useClientRoutineEdit } from '../../api/mutations/useClientRoutineEdit';
import { useClientRoutineFullQuery } from '../../api/queries/useClientRoutineFullQuery';
import { getRoutineStatusUpdateOptions } from '../../helpers/routine';

const ClientRoutineFullHeaderActions: FC = () => {
  const currentPath = useCurrentPath();
  const location = useLocation();
  const navigate = useNavigate();
  const routineId = useParamRoutineId();
  const { data: routine } = useClientRoutineFullQuery(routineId);
  const { data: client } = useClientFull(routine?.clientId ?? null);
  const { openModal } = useModal();
  const { isPending: isDeleting, mutateAsync: deleteRoutineMutation } =
    useClientRoutineDelete();
  const { isPending: isStatusUpdateing, mutate: editRoutine } =
    useClientRoutineEdit();
  const { isPending: isConverting, mutateAsync: convert } = useConversion();

  const conversionType = ConversionType.ClientRoutineToTemplateRoutine;

  const backUrl = currentPath?.startsWith(PATH__ME)
    ? PATH__ME
    : getBackUrl(currentPath, location, {
        toPathFragment: PATH_FRAGMENT__CLIENT_ID,
      });

  const labelConvert = translate(`common.conversion.${conversionType}.title`);

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.ClientRoutine,
      onConfirm: () => {
        if (routine === undefined || client === undefined) {
          return;
        }

        deleteRoutineMutation(routine.id);

        navigate(
          {
            pathname: backUrl,
            search: `${SearchParam.RoutineStatus}=${routine.status}`,
          },
          { replace: true },
        );
      },
    });
  };

  const requestEdit = () => {
    openModal(ModalId.ClientRoutineDetailsEdit, {
      [SearchParam.EntityId]: routineId,
    });
  };

  const requestConvert = async () => {
    if (routineId === null) {
      return;
    }
    await convert({ conversionType, params: { position: null, routineId } });
    invalidateTemplateRoutines({ refetchType: 'all' });
    toast.success(ToastSuccessId.TemplateRoutineGenerated);
  };

  const items: MenuItem[] = [
    {
      Icon: iconsMap.edit.Icon,
      label: translate('routine.edit'),
      onClick: requestEdit,
    },
    {
      Icon: iconsMap.export.Icon,
      label: labelConvert,
      onClick: requestConvert,
    },
    {
      Icon: iconsMap.delete.Icon,
      label: translate('routine.delete'),
      onClick: requestDelete,
    },
  ];

  if (routine !== undefined) {
    items.push({
      isSectionLabel: true,
      label: translate('common.status_change_to'),
    });

    const statusUpdateOptions = getRoutineStatusUpdateOptions(
      { status: routine.status },
      updatedStatus => {
        editRoutine({
          clientId: routine.clientId,
          payload: {
            status: updatedStatus,
          },
          routineId: routine.id,
        });
      },
    );

    items.push(...statusUpdateOptions);
  }

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      trigger={
        <ActionIconHeaderActions
          label={translate('routine.actions')}
          loading={isDeleting || isConverting || isStatusUpdateing}
        />
      }
    />
  );
};

export default ClientRoutineFullHeaderActions;
