import { useMutation } from '@tanstack/react-query';

import http from '../../../../../config/http';
import { updateDataById } from '../../../../../utils/array';
import { FormRoutineDetails } from '../../../types/routine';
import { ClientRoutineFull, RoutineStatus } from '../../types';
import {
  getClientRoutineFull,
  getClientRoutineListItem,
  getClientRoutines,
} from '../cache/getters';
import { moveClientRoutine } from '../cache/mutations';
import { setClientRoutineFull, setClientRoutines } from '../cache/setters';
import { cancelClientRoutineQuery } from '../queries/useClientRoutineFullQuery';
import { cancelClientRoutinesQuery } from '../queries/useClientRoutinesQuery';
import {
  clientRoutineMutationErrorHandler,
  clientRoutineMutationSettledHandler,
} from './handlers';
import { getUpdatedRoutineFull, getUpdatedRoutineList } from './helpers';
import { ClientRoutineMutationContext } from './types';

interface R {
  routineId: string;
}

/**
 * @todo move cycles to separate endpoint
 */
export type EditClientRoutinePayload = {
  payload: Partial<
    {
      cycles?: ClientRoutineFull['cycles'];
    } & { position: number; status: RoutineStatus } & FormRoutineDetails
  >;
} & { clientId: string } & R;

export const patchClientRoutine = async ({
  payload,
  routineId,
}: EditClientRoutinePayload) => {
  await http.patch(`/client-routines/${routineId}`, payload);
};

export const useClientRoutineEdit = () => {
  return useMutation({
    mutationFn: patchClientRoutine,
    onError(_, variables, context) {
      clientRoutineMutationErrorHandler(
        variables.routineId,
        context as ClientRoutineMutationContext,
      );
    },
    onMutate: async ({ clientId, payload, routineId }) => {
      const routineFull = getClientRoutineFull(routineId);
      const routineListItem = getClientRoutineListItem(clientId, routineId);

      await Promise.all([
        cancelClientRoutineQuery(routineId),
        cancelClientRoutinesQuery(clientId),
      ]);

      const routinesAll = getClientRoutines(clientId);

      if (payload.status !== undefined && payload.position !== undefined) {
        moveClientRoutine({
          clientId,
          position: payload.position,
          routineId,
          status: payload.status,
        });
      }

      if (routineFull !== null) {
        const updatedRoutineFull = getUpdatedRoutineFull(routineFull, payload);
        setClientRoutineFull(routineId, updatedRoutineFull);
      }

      if (routinesAll !== null && routineListItem !== null) {
        const updatedRoutineList = getUpdatedRoutineList(
          routineListItem,
          payload,
        );
        setClientRoutines(
          routineId,
          updateDataById(routinesAll, routineId, updatedRoutineList),
        );
      }

      return { routine: routineFull, routinesAll };
    },
    onSettled(_, __, { clientId, routineId }, context) {
      clientRoutineMutationSettledHandler(
        routineId,
        context as ClientRoutineMutationContext,
        clientId,
      );
    },
  });
};
