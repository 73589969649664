import { MutationDelete } from '../../../constants/mutation-keys';
import useBackNavigation from '../../../hooks/useGetBackUrl';
import useCurrentPath from '../../../router/hooks/useCurrentPath';
import {
  PATH_FRAGMENT__COACHES,
  PATH_FRAGMENT__FACILITY_ID,
} from '../../../router/paths/path-fragments';
import { PATH__BUSINESS__FACILITIES } from '../../../router/paths/paths';
import { SearchParam } from '../../../router/types';
import { UserStatus } from '../../../types';
import { BusinessUser } from '../../../types/business';
import { ModalId, useModal } from '../../../utils/modal';
import { confirmDanger } from '../../../utils/modal/confirm';
import {
  useBusinessCoachUpdateMutation,
  useBusinessUserDeleteMutation,
} from '../api/mutations/coach';

const useCoachActions = (coach: Pick<BusinessUser, 'id' | 'status'>) => {
  const { goBack } = useBackNavigation();
  const currentPath = useCurrentPath();
  const { id, status } = coach;
  const { openModal } = useModal();

  const { isPending, mutate: updateCoach } = useBusinessCoachUpdateMutation();

  const { isPending: isDeleting, mutateAsync: deleteClient } =
    useBusinessUserDeleteMutation();

  const isDeleteDisabled = isPending || status === UserStatus.Active;

  const requestActivate = () =>
    updateCoach({ coachDetails: { status: UserStatus.Active }, userId: id });

  const requestDeactivate = () =>
    updateCoach({ coachDetails: { status: UserStatus.Inactive }, userId: id });

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.BusinessCoach,
      onConfirm: () =>
        deleteClient(id).then(() => {
          if (currentPath?.startsWith(PATH__BUSINESS__FACILITIES)) {
            goBack({ toPathFragment: PATH_FRAGMENT__FACILITY_ID });
          } else {
            goBack({ toPathFragment: PATH_FRAGMENT__COACHES });
          }
        }),
    });
  };

  const requestEdit = () => {
    openModal(ModalId.BusinessCoachEdit, {
      [SearchParam.EntityId]: id,
    });
  };

  return {
    isDeleteDisabled,
    isDeleting,
    isUpdating: isPending,
    requestActivate,
    requestDeactivate,
    requestDelete,
    requestEdit,
  };
};

export default useCoachActions;
