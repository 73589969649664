import { useLocation } from 'react-router-dom';

import useScrollActiveLinkIntoView from '../../../../hooks/useScrollCurrentLinkIntoView';
import {
  useParamClientId,
  useParamCoachId,
} from '../../../../router/hooks/path-params';
import { getSearchParamSearch } from '../../../../router/urls/searchParams/getters';
import { useClients } from '../../api/queries/useClients';
import { getFilteredClients } from './helpers';

const useNavigationClients = () => {
  const location = useLocation();
  const paramCoachId = useParamCoachId();
  const paramClientId = useParamClientId();
  const { data: clients = [], isLoading } = useClients();
  const search = getSearchParamSearch(location)?.toLowerCase();

  useScrollActiveLinkIntoView(isLoading === false);

  const filters = {
    paramClientId,
    paramCoachId,
    search,
  };

  return {
    clients: getFilteredClients(clients, filters, location),
    isLoading,
  };
};

export default useNavigationClients;
