import { AppShell } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { lazy, Suspense, useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import {
  HEADER_AND_RAIL_COLOR,
  HEADER_HEIGHT,
  RAIL_DRAWER_WIDTH,
  RAIL_WIDTH,
} from '../../../constants/preferences';
import useSidebarContext from '../../../providers/sidebar/useSidebarContext';
import NavigationDesktop from '../NavigationDesktop';
import NavigationMobile from '../NavigationMobile';

const Modals = lazy(() => import('../../../app/AppAuthenticated/Modals'));

function AuthenticatedLayout() {
  const { isOpen } = useSidebarContext();

  useLayoutEffect(() => {
    document.body.style.backgroundColor = HEADER_AND_RAIL_COLOR;
  }, []);

  return (
    <AppShell
      header={{
        height: HEADER_HEIGHT,
      }}
      layout="alt"
      navbar={{
        breakpoint: 'sm',
        collapsed: {
          desktop: false,
          mobile: true,
        },
        width: isOpen ? RAIL_WIDTH + RAIL_DRAWER_WIDTH : RAIL_WIDTH,
      }}
      padding={0}
    >
      <NavigationDesktop />
      <AppShell.Main>
        <ModalsProvider>
          <Outlet />
          <Suspense fallback={null}>
            <Modals />
          </Suspense>
        </ModalsProvider>
      </AppShell.Main>
      <NavigationMobile />
    </AppShell>
  );
}

export default AuthenticatedLayout;
