import { useMutation } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../config/http';
import { Identity, UserStatus } from '../../../../types';
import {
  BusinessUser,
  BusinessUserFull,
  UpdateBusinessCoachPayload,
} from '../../../../types/business';
import { invalidateClients } from '../../../clients/api/queries/useClients';
import { UserType } from '../../../user/types';
import { invalidateBusiness } from '../queries/business';
import { invalidateBusinessCoach } from '../queries/users';

interface UserId {
  userId: string;
}

interface UserCoachCreate {
  clientDetails: {
    coachId: null | string;
    status: null;
  };
  coachDetails: {
    rankId: null | number;
    status: UserStatus;
  };
  email: string;
  facilityIds: string[];
  firstName: string;
  lastName: string;
  type: UserType.CompanyCoach;
}

const coachCreate = async (
  payload: {
    businessName: string;
    email: string;
    facilityIds: string[];
    rankId: null | number;
  } & Pick<Identity, 'firstName' | 'lastName'>,
) => {
  const { businessName, facilityIds, rankId, ...rest } = payload;

  const payloadCreate: UserCoachCreate = {
    ...rest,
    clientDetails: {
      coachId: null,
      status: null,
    },
    coachDetails: {
      rankId,
      status: UserStatus.SignUpInstructionsSent,
    },
    facilityIds,
    type: UserType.CompanyCoach,
  };

  const { data } = await http.post<ApiResponse<BusinessUser>>('/users', {
    ...payloadCreate,
    businessName,
  });

  return data.data;
};

export const useBusinessCoachCreateMutation = () => {
  return useMutation({
    mutationFn: coachCreate,
    onSuccess: () => {
      invalidateBusiness();
    },
  });
};

const patchBusinessCoachUser = async (
  payload: UpdateBusinessCoachPayload & UserId,
) => {
  const { userId, ...updateData } = payload;
  if (!userId) {
    return;
  }

  const { data } = await http.patch<ApiResponse<BusinessUserFull>>(
    `/business-coaches/${userId}`,
    updateData,
  );

  return data.data;
};
export const useBusinessCoachUpdateMutation = () => {
  return useMutation({
    mutationFn: patchBusinessCoachUser,
    onMutate(_variables) {},
    onSettled(_data, error, variables, _context) {
      if (error !== null) {
        invalidateBusiness();
        invalidateBusinessCoach(variables.userId);
      } else {
        invalidateBusiness();
        invalidateBusinessCoach(variables.userId);
      }
    },
  });
};

const deleteUser = async (coachId: string) => {
  const { data } = await http.delete(`/users/${coachId}`);

  return data.data;
};

export const useBusinessUserDeleteMutation = () => {
  return useMutation({
    mutationFn: deleteUser,
    onSettled(_, __, _clientId) {
      invalidateBusiness();
      invalidateClients();
    },
  });
};
