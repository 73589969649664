import { useMutation } from '@tanstack/react-query';

import http from '../../../../../../config/http';
import { MutationUpdate } from '../../../../../../constants/mutation-keys';
import { useParamRoutineId } from '../../../../../../router/hooks/path-params';
import { getExpectedWorkoutStatus } from '../../../helpers/workout';
import { ClientWorkout, WorkoutStatus } from '../../../types';
import { getClientRoutineWorkout } from '../../cache/getters';
import {
  clientRoutineMutationErrorHandler,
  clientRoutineMutationSettledHandler,
} from '../handlers';
import { ClientRoutineMutationContext } from '../types';
import { clientWorkoutOptimisticHandler } from './handlers';

async function putWorkoutAction(p: {
  payload: { reset?: boolean; skip?: boolean };
  workoutId: string;
}) {
  await http.put(`/client-workouts/${p.workoutId}`, p.payload);
}

export function useClientWorkoutSkip(clientId: string) {
  const routineId = useParamRoutineId();

  return useMutation({
    mutationFn: (workoutId: string) =>
      putWorkoutAction({ payload: { skip: true }, workoutId }),
    mutationKey: [MutationUpdate.ClientWorkoutSkip],
    onError(_, __, context) {
      const { routine } = context as ClientRoutineMutationContext;
      clientRoutineMutationErrorHandler(
        routineId ?? routine.id,
        context as ClientRoutineMutationContext,
        clientId,
      );
    },
    async onMutate(workoutId) {
      const payload: Partial<ClientWorkout> = {
        status: WorkoutStatus.Skipped,
      };

      return await clientWorkoutOptimisticHandler({
        payload,
        routineId,
        workoutId,
      });
    },
    onSettled(_, __, ___, context) {
      if (routineId === null) {
        return;
      }
      clientRoutineMutationSettledHandler(
        routineId,
        context as ClientRoutineMutationContext,
      );
    },
  });
}

export function useClientWorkoutUnSkip(clientId: string) {
  const routineId = useParamRoutineId();

  return useMutation({
    mutationFn: (workoutId: string) =>
      putWorkoutAction({ payload: { skip: false }, workoutId }),
    mutationKey: [MutationUpdate.ClientWorkoutUnSkip],
    onError(_, __, context) {
      const { routine } = context as ClientRoutineMutationContext;
      clientRoutineMutationErrorHandler(
        routineId ?? routine.id,
        context as ClientRoutineMutationContext,
        clientId,
      );
    },
    async onMutate(workoutId) {
      if (routineId === null) {
        return;
      }
      const workout = getClientRoutineWorkout(routineId, workoutId);

      if (workout === null) {
        return;
      }

      const payload: Partial<ClientWorkout> = {
        status: getExpectedWorkoutStatus({
          ...workout,
          status: WorkoutStatus.None, // With skipped, it wont be calculated
        }),
      };

      return await clientWorkoutOptimisticHandler({
        payload,
        routineId,
        workoutId,
      });
    },
    onSettled(_, __, ___, context) {
      if (routineId === null) {
        return;
      }
      clientRoutineMutationSettledHandler(
        routineId,
        context as ClientRoutineMutationContext,
      );
    },
  });
}

export function useClientWorkoutReset(clientId: string) {
  const routineId = useParamRoutineId();

  return useMutation({
    mutationFn: (workoutId: string) =>
      putWorkoutAction({ payload: { reset: true }, workoutId }),
    mutationKey: [MutationUpdate.ClientWorkoutReset],
    onError(_, __, context) {
      const { routine } = context as ClientRoutineMutationContext;
      clientRoutineMutationErrorHandler(
        routineId ?? routine.id,
        context as ClientRoutineMutationContext,
        clientId,
      );
    },
    async onMutate(workoutId) {
      if (routineId === null) {
        return;
      }
      const workout = getClientRoutineWorkout(routineId, workoutId);

      if (workout === null) {
        return;
      }

      const payload: Partial<ClientWorkout> = {
        bodyWeight: null,
        completedAt: null,
        exerciseInstructions: workout.exerciseInstructions.map(exInstr => ({
          ...exInstr,
          sets: exInstr.sets.map(set => ({
            ...set,
            accomplished: null,
            isSkipped: false,
          })),
        })),
        status: WorkoutStatus.None,
      };

      return await clientWorkoutOptimisticHandler({
        payload,
        routineId,
        workoutId,
      });
    },
    onSettled(_, __, ___, context) {
      if (routineId === null) {
        return;
      }

      clientRoutineMutationSettledHandler(
        routineId,
        context as ClientRoutineMutationContext,
      );
    },
  });
}
