import { useQuery, UseQueryResult } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../config/http';
import { queryClient } from '../../../../config/react-query';
import {
  QueryKey,
  QueryRefetchOptions,
} from '../../../../constants/react-query';
import { Client } from '../../../../types/clients';

async function getOne(id: string): Promise<Client> {
  const { data } = await http.get<ApiResponse<Client>>(`/clients/${id}`);
  if (data.data.facilityMember?.userId === null) {
    return { ...data.data, facilityMember: null };
  }
  return data.data;
}

export const useClientFull = (
  id: null | string | undefined = null,
  options?: QueryRefetchOptions,
): UseQueryResult<Client> => {
  return useQuery({
    enabled: id !== null,
    queryFn: () => getOne(id as string),
    queryKey: QueryKey.Client(id),
    ...options,
  });
};

export const invalidateClientFull = (id: string) =>
  queryClient.invalidateQueries({ queryKey: QueryKey.Client(id) });

export const cancelClientFullQueries = (id: string) =>
  queryClient.cancelQueries({ queryKey: QueryKey.Client(id) });
