import { useContext } from 'react';

import SidebarContext from './context';

const useSidebarContext = () => {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new Error(
      'useSidebarContext can not be used outside of SidebarProvider',
    );
  }

  return context;
};

export default useSidebarContext;
