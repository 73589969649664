import { Center, Image, Stack, Title } from '@mantine/core';

export default function SplashScreen() {
  return (
    <Center
      align="center"
      bg="white"
      component={Stack}
      h="100vh"
      justify="center"
      w="100vw"
    >
      <Image h={120} rel="preload" src="/assets/logo-black.webp" w="auto" />
      <Title c="dark.6" lts={6} order={1} tt="uppercase">
        Strenco
      </Title>
    </Center>
  );
}
