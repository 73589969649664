import { setClientRoutineFull, setClientRoutines } from '../cache/setters';
import { invalidateClientRoutine } from '../queries/useClientRoutineFullQuery';
import { invalidateClientRoutines } from '../queries/useClientRoutinesQuery';
import { ClientRoutineMutationContext } from './types';

export const clientRoutineMutationSettledHandler = (
  routineId: string,
  context: ClientRoutineMutationContext,
  clientId?: string,
) => {
  invalidateClientRoutine(routineId);
  const { routine } = context as ClientRoutineMutationContext;
  const _clientId = clientId ?? routine?.clientId;
  if (_clientId) {
    invalidateClientRoutines(_clientId);
  }
};

export const clientRoutineMutationErrorHandler = (
  routineId: string,
  context: ClientRoutineMutationContext,
  clientId?: string,
) => {
  if (context === undefined) {
    return;
  }

  const { routine, routinesAll } = context as ClientRoutineMutationContext;

  const _clientId = clientId ?? routine.clientId;

  setClientRoutineFull(routineId, routine);

  if (_clientId) {
    setClientRoutines(_clientId, routinesAll);
  }
};
