/** @file Contains getters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { getDataById, updateDataById } from '../../../../../utils/array';
import {
  StandaloneTemplateWorkout,
  TemplateRoutineFull,
  TemplateRoutineList,
  TemplateRoutineWorkout,
} from '../../types';
import {
  getStandaloneTemplateWorkout,
  getTemplateRoutineFull,
} from './getters';

/**
 * Updates the template routine in the cache
 * @param routineId The id of the routine
 * @param data      The data we want to set
 * @param qc        QueryClient
 */
export const setTemplateRoutineFull = (
  routineId: string,
  data: null | TemplateRoutineFull | undefined,
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData(QueryKey.TemplateRoutine(routineId), data);
};

/**
 * Updates the template routines cache
 * @param routines Template routines we want to set
 * @param qc       QueryClient
 */
export const setTemplateRoutines = (
  routines: null | TemplateRoutineList[] | undefined,
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData(QueryKey.TemplateRoutines(), routines);
};

/**
 * Updates the workout of a routine template in the cache
 * @param routineId The id of the routine template
 * @param workoutId the id of the workout we want to update
 * @param data      The data we want to set
 * @param qc        QueryClient
 */
export const setTemplateRoutineWorkout = (
  routineId: string,
  workoutId: string,
  data: Partial<TemplateRoutineWorkout>,
  qc: QueryClient = queryClient,
) => {
  const routine = getTemplateRoutineFull(routineId);

  if (routine === null) {
    return;
  }

  const workout = getDataById(routine?.workouts, workoutId);

  if (workout === null) {
    return;
  }

  setTemplateRoutineFull(
    routineId,
    { ...routine, workouts: updateDataById(routine.workouts, workoutId, data) },
    qc,
  );
};

/**
 * Updates the standalone template workouts cache
 * @param templateWorkouts Standalone template workouts we want to set
 * @param qc               QueryClient
 */
export const setStandaloneTemplateWorkouts = (
  templateWorkouts: null | StandaloneTemplateWorkout[] | undefined,
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData(QueryKey.TemplateWorkouts(), templateWorkouts);
};

/**
 * Updates the standalone template workouts cache
 * @param workoutId       The id of the workout we want to update
 * @param templateWorkout Standalone template workout we want to set
 * @param qc              QueryClient
 */
export const setStandaloneTemplateWorkout = (
  workoutId: string,
  templateWorkout: null | Partial<StandaloneTemplateWorkout> | undefined,
  qc: QueryClient = queryClient,
) => {
  const workout = getStandaloneTemplateWorkout(workoutId, qc);

  const payload =
    workout === null ? templateWorkout : { ...workout, ...templateWorkout };
  qc.setQueryData(QueryKey.TemplateWorkout(workoutId), payload);
};
