import { Affix, Button, rem, Tooltip, Transition } from '@mantine/core';
import { useMounted } from '@mantine/hooks';
import { TablerIcon } from '@tabler/icons-react';
import { FC } from 'react';

import { BOTTOM_NAVIGATION__HEIGHT } from '../../../constants/preferences';
import useIsMobile from '../../../hooks/useIsMobile';
import { vars } from '../../../theme';
import classes from './styles.css';

interface Props {
  'data-target': string;
  hidden?: boolean;
  Icon: TablerIcon;
  onClick: () => void;
  tooltip: string;
}

const StickyButton: FC<Props> = ({
  hidden,
  Icon,
  onClick,
  tooltip,
  ...props
}) => {
  const isMobile = useIsMobile();
  const isMounted = useMounted();
  return (
    <Transition
      enterDelay={100}
      exitDelay={300}
      mounted={isMounted}
      transition="scale"
    >
      {transitionStyle => (
        <Affix
          hidden={hidden}
          position={{
            bottom: isMobile
              ? BOTTOM_NAVIGATION__HEIGHT * 1.2
              : vars.spacing.xl,
            right: vars.spacing.md,
          }}
        >
          <Tooltip
            events={{ focus: true, hover: true, touch: false }}
            keepMounted={true}
            label={tooltip}
          >
            <Button
              aria-label={tooltip}
              classNames={classes}
              gradient={{ deg: 90, from: 'branding.7', to: 'branding.9' }}
              h={50}
              onClick={onClick}
              p={0}
              radius="xl"
              style={transitionStyle}
              variant="gradient"
              w={rem(50)}
              {...props}
            >
              <Icon size={32} stroke={1.5} />
            </Button>
          </Tooltip>
        </Affix>
      )}
    </Transition>
  );
};

export default StickyButton;
