import React, { lazy, memo, Suspense, useEffect } from 'react';

import SplashScreen from '../../components/layout/SplashScreen';
import { CognitoError } from '../../constants/errors/cognito';
import { useUser, useUserData } from '../../features/user/api/queries/useUser';
import { UserType } from '../../features/user/types';
import { refreshSession, setUser } from '../../features/user/utils/cognito';
import { getCognitoErrorMessage } from '../../utils/errors';
import AppUserSubscribed from './UserSubscribed/AppUserSubscribed';

const AppUserNotSubscribed = lazy(
  () => import('./UserNotSubscribed/AppUserNotSubscribed'),
);

const unAuthorizedErrors = new Set([
  CognitoError.UserUnAuthenticatedException,
  CognitoError.UserNotFoundException,
]);

const AppAuthenticated: React.FC = () => {
  useUserData();
  const { data: user, error, isLoading, isRefetching } = useUser();

  useEffect(() => {
    if (user) {
      setUser(user);
    }
  }, [user]);

  useEffect(() => {
    const msg = getCognitoErrorMessage(error);

    if (unAuthorizedErrors.has(msg) === true) {
      refreshSession()
        .then(sessionUser => {
          setUser(sessionUser, sessionUser === null);
        })
        .catch(() => {
          setUser(null, true);
        });
    }
  }, [error, isLoading, isRefetching]);

  if (user === null || user === undefined) {
    return <SplashScreen />;
  }

  if (user?.type === UserType.New) {
    return (
      <Suspense fallback={null}>
        <AppUserNotSubscribed />
      </Suspense>
    );
  }

  return <AppUserSubscribed />;
};

export default memo(AppAuthenticated);
