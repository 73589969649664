import { useMutation } from '@tanstack/react-query';

import http from '../../../../config/http';
import { UserStatus } from '../../../../types';
import { UpdateClientAttributes } from '../../../../types/clients';
import { UserType } from '../../../user/types';
import {
  ClientMutationContext,
  clientMutationOnErrorHandler,
  clientMutationOnMutateHandler,
  clientMutationOnSettledHandler,
} from './handler';

const updateClientStatus = async ({
  clientId,
  status,
}: {
  clientId: string;
  status: UserStatus;
}) => {
  const { data } = await http.patch(`/clients/${clientId}`, {
    status,
    type: UserType.Client,
  });

  return data.data;
};

type UpdateClientPayload = {
  clientId: string;
  coachId?: null | string;
  facilityId?: null | string;
} & Omit<UpdateClientAttributes, 'email' | 'status'>;

const updateClient = async ({ clientId, ...payload }: UpdateClientPayload) => {
  const { data } = await http.patch(`/clients/${clientId}`, payload);

  return data.data;
};

export const useClientUpdateMutation = () => {
  return useMutation({
    mutationFn: updateClient,
    async onMutate({ clientId, ...variables }) {
      return await clientMutationOnMutateHandler(clientId, variables);
    },
    onSettled(_, error, variables, context) {
      if (error) {
        clientMutationOnErrorHandler(context as ClientMutationContext);
      }
      clientMutationOnSettledHandler(variables.clientId);
    },
  });
};

export const useClientActivateMutation = () => {
  return useMutation({
    mutationFn: (clientId: string) =>
      updateClientStatus({ clientId, status: UserStatus.Active }),
    async onMutate(variables) {
      return await clientMutationOnMutateHandler(variables, {
        status: UserStatus.Active,
      });
    },
    onSettled(_, error, clientId, context) {
      if (error) {
        clientMutationOnErrorHandler(context as ClientMutationContext);
      }
      clientMutationOnSettledHandler(clientId);
    },
  });
};

export const useClientDeactivateMutation = () => {
  return useMutation({
    mutationFn: (clientId: string) =>
      updateClientStatus({ clientId, status: UserStatus.Inactive }),
    async onMutate(variables) {
      return await clientMutationOnMutateHandler(variables, {
        status: UserStatus.Active,
      });
    },
    onSettled(_, error, clientId, context) {
      if (error) {
        clientMutationOnErrorHandler(context as ClientMutationContext);
      }
      clientMutationOnSettledHandler(clientId);
    },
  });
};
