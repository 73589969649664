import { BreadcrumbItem } from '../../../components/common/Breadcrumbs';
import { PageId } from '../../../constants/page-ids';
import { useTemplateRoutinesQuery } from '../../../features/routine/@template/api/queries/useTemplateRoutinesQuery';
import { useTemplateRoutineWorkoutQuery } from '../../../features/routine/@template/api/queries/useTemplateRoutineWorkoutQuery';
import { translate } from '../../../i18n';
import { getPageLabel } from '../../../i18n/helpers/page';
import {
  getBreadcrumbWorkoutLabel,
  getBreadcrumbWorkoutName,
} from '../../../router/breadcrumbs/helpers';
import {
  useParamRoutineId,
  useParamWorkoutId,
} from '../../../router/hooks/path-params';
import { routes } from '../../../router/routes';
import { getDataById } from '../../../utils/array';

const useBreadcrumbs = (): BreadcrumbItem[] => {
  const routineId = useParamRoutineId();
  const workoutId = useParamWorkoutId();

  const { data: routines } = useTemplateRoutinesQuery();
  const { data: workout } = useTemplateRoutineWorkoutQuery(workoutId);

  const items: BreadcrumbItem[] = [
    {
      content: getPageLabel(PageId.TemplateRoutines),
      href: routes.library.routines.root(),
    },
  ];

  const routine = getDataById(routines, routineId);

  if (routineId !== null) {
    items.push({
      content: routine?.name,
      href: routes.library.routines.byId(routineId),
      isLoading: routine === null,
      label: translate('routine.template.label'),
    });

    const workoutItem = getDataById(routine?.workouts, workoutId) ?? workout;

    if (workoutId !== null) {
      items.push({
        content: getBreadcrumbWorkoutName(workoutItem ?? workout),
        href: routes.library.routines.workouts(routineId, workoutId),
        isLoading: workoutItem === null,
        label: getBreadcrumbWorkoutLabel(routine, workoutId),
      });
    }
  }

  return items;
};

export default useBreadcrumbs;
