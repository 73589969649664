import { alpha, Avatar, AvatarProps } from '@mantine/core';
import { FC } from 'react';

import { vars } from '../../../theme';

interface Props {
  borderColor?: string;
  firstName?: null | string;
  lastName?: null | string;
  loading?: boolean;
  shadowColor?: string;
}

const getCalcSize = (
  size: number | string | undefined,
  defaultSize: string,
  factor: number,
) => {
  return size
    ? `calc(${typeof size === 'number' ? size + 'px' : size} * ${factor})`
    : defaultSize;
};

const UserAvatar: FC<AvatarProps & Props> = ({
  borderColor,
  color = vars.colors.branding[8],
  firstName,
  lastName,
  loading,
  name,
  radius = 'md',
  shadowColor,
  ...props
}) => {
  const alt = name ?? `${firstName} ${lastName}`;

  return (
    <Avatar
      alt={alt}
      color={color}
      name={alt} // Includes letters if empty
      radius={radius}
      style={{
        boxShadow: shadowColor
          ? `0 0px ${getCalcSize(props.size, '8px', 0.08)} ${alpha(shadowColor, 0.8)}`
          : undefined,
      }}
      variant="light"
      {...props}
    />
  );
};

export default UserAvatar;
