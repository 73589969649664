import {
  IconAlertCircle,
  IconAlertTriangle,
  IconCircleCheck,
  IconInfoCircle,
} from '@tabler/icons-react';

import { AlertId } from '../../../constants/alert';
import { AlertLevel } from '../../../types';
import { AlertConfig, CustomAlertLevelConfig } from './types';

export const alertLevelConfig: CustomAlertLevelConfig = {
  [AlertLevel.Critical]: {
    color: 'red',
    Icon: IconAlertTriangle,
  },
  [AlertLevel.Info]: {
    color: 'blue',
    Icon: IconInfoCircle,
  },
  [AlertLevel.Success]: {
    color: 'green',
    Icon: IconCircleCheck,
  },
  [AlertLevel.Warning]: {
    color: 'yellow',
    Icon: IconAlertCircle,
  },
};

export const alertConfig: AlertConfig = {
  [AlertId.NotSubscribed]: {
    level: AlertLevel.Critical,
  },
  [AlertId.SubscriptionCanceled]: {
    level: AlertLevel.Critical,
  },
  [AlertId.SubscriptionPastDue]: {
    level: AlertLevel.Critical,
  },
  [AlertId.SubscriptionPaused]: {
    level: AlertLevel.Warning,
  },
  [AlertId.SubscriptionTrialEndingSoon]: {
    level: AlertLevel.Warning,
  },
};
