import { MantineColor } from '@mantine/core';

import { ExerciseVolumeUnit } from '../../types';
import { CLIENT_WORKOUT__STATUS_COLORS } from '../constants';
import {
  ClientExerciseInstruction,
  ClientSetAttributes,
  ClientWorkout,
  SetMeasureType,
  WorkoutStatus,
} from '../types';
import { getHasSetAllRequiredMeasures } from './exercise-instructions';

export const getSetCounts = (
  exerciseInstructions: Pick<ClientExerciseInstruction, 'sets'>[],
) => {
  const completed = new Set<string>();
  const completedWithMissingDetails = new Set<string>();
  const skipped = new Set<string>();

  let total = 0;

  exerciseInstructions.forEach(exInstr => {
    if (exInstr) {
      total += exInstr.sets.length;
      exInstr.sets.forEach(set => {
        if (set.accomplished) {
          if (set.isSkipped === true) {
            skipped.add(set.id);
          } else {
            completed.add(set.id);
            set.accomplished.measures.forEach(m => {
              if (set.volumeUnit === ExerciseVolumeUnit.Reps) {
                const requiredMeasures = [
                  SetMeasureType.Reps,
                  SetMeasureType.Weight,
                ];
                if (requiredMeasures.includes(m.type) && m.value === null) {
                  completedWithMissingDetails.add(set.id);
                }
              }
            });
          }
        }
      });
    }
  });

  return {
    completed: completed.size,
    completedWithMissingDetails: completedWithMissingDetails.size,
    skipped: skipped.size,
    total,
  };
};

export const isWorkoutCompleted = (
  status: ClientWorkout['status'] | undefined,
  includeSkipped = false,
): status is
  | WorkoutStatus.Completed
  | WorkoutStatus.CompletedWithMissingData => {
  const isCompleted =
    status === WorkoutStatus.Completed ||
    status === WorkoutStatus.CompletedWithMissingData;

  if (includeSkipped === false) {
    return isCompleted;
  }

  return isCompleted || status === WorkoutStatus.Skipped;
};

const getHasWorkoutAllSetsWithRequiredMeasures = (
  sets: ClientSetAttributes[],
) => {
  return sets.every(getHasSetAllRequiredMeasures);
};

export const getExpectedWorkoutStatus = (
  workout: Pick<ClientWorkout, 'exerciseInstructions' | 'status'>,
) => {
  if (workout.status === WorkoutStatus.Skipped) {
    return WorkoutStatus.Skipped;
  }

  const setsAll = workout.exerciseInstructions.flatMap(exInstr => exInstr.sets);

  const setsAccomplished = setsAll.filter(set => set.accomplished);

  if (setsAccomplished.length === 0) {
    return WorkoutStatus.None;
  }

  if (setsAccomplished.length !== setsAll.length) {
    return WorkoutStatus.InProgress;
  }

  // Workout is completed, we check if every set has all the required data
  return getHasWorkoutAllSetsWithRequiredMeasures(setsAccomplished)
    ? WorkoutStatus.Completed
    : WorkoutStatus.CompletedWithMissingData;
};

/**
 * Get the appropriate color for each workout status
 * @param status Workout status
 * @returns      Mantine Color
 */
export const getWorkoutStatusColor = (status: WorkoutStatus): MantineColor => {
  return CLIENT_WORKOUT__STATUS_COLORS[status];
};
