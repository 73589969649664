import { Exercise } from '../../../types';
import { getExerciseById, getExercises } from '../cache/getters';
import { removeExercise, upsertExercise } from '../cache/mutations';
import { setExercise, setExercises } from '../cache/setters';
import {
  cancelExerciseQuery,
  invalidateExercise,
} from '../queries/useExerciseQuery';
import {
  cancelExercisesQuery,
  invalidateExercises,
} from '../queries/useExercises';

export interface ExerciseMutationContext {
  exerciseFull: ReturnType<typeof getExerciseById>;
  exercisesAll: ReturnType<typeof getExercises>;
}

export const exerciseMutationOnMutateHandler = async (
  exerciseId: string,
  data?: Partial<Exercise>,
): Promise<ExerciseMutationContext> => {
  const exerciseFull = getExerciseById(exerciseId);
  const exercisesAll = getExercises();

  await Promise.all([cancelExerciseQuery(exerciseId), cancelExercisesQuery()]);

  if (data !== undefined && exerciseFull !== null) {
    upsertExercise({ ...exerciseFull, ...data });
  } else if (data === undefined) {
    // delete mutation
    removeExercise(exerciseId);
  }

  return { exerciseFull, exercisesAll };
};

export const exerciseMutationOnSettledHandler = (exerciseId: string) => {
  invalidateExercise(exerciseId);
  invalidateExercises();
};

export const exerciseMutationOnErrorHandler = (
  context: ExerciseMutationContext | undefined,
) => {
  const { exerciseFull = null, exercisesAll = null } = context ?? {};

  if (exerciseFull !== null) {
    setExercise(exerciseFull);
  }

  if (exercisesAll !== null) {
    setExercises(exercisesAll);
  }
};
