import { Button, Text, Tooltip } from '@mantine/core';
import { TablerIcon } from '@tabler/icons-react';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  DRAWER_NAV_ITEM_ICON__ACTIVE_SIZE_FACTOR,
  DRAWER_NAV_ITEM_ICON__COLOR_DEFAULT,
  DRAWER_NAV_ITEM_ICON__SIZE_DEFAULT,
} from '../../../constants/preferences';
import { getIsNavItemActive } from '../../../router/helpers/navigation';
import { vars } from '../../../theme';
import { INavItem } from '../../layout/NavigationDesktop/types';

interface Props extends Omit<INavItem, 'icon'> {
  Icon?: TablerIcon;
  iconColor?: string;
  iconSize?: number;
  iconTooltip?: string;
  isActive?: boolean;
}

const DrawerNavItem: FC<Props> = ({
  Icon,
  iconColor = DRAWER_NAV_ITEM_ICON__COLOR_DEFAULT,
  iconSize = DRAWER_NAV_ITEM_ICON__SIZE_DEFAULT,
  iconTooltip,
  isActive,
  label,
  to,
}) => {
  const { pathname } = useLocation();

  const isCurrent =
    isActive === undefined ? getIsNavItemActive(pathname, to, []) : isActive;

  const getIcon = () => {
    if (Icon === undefined) {
      return null;
    }

    const iconEl = (
      <Icon
        color={isActive ? vars.colors.branding[6] : iconColor}
        size={
          isCurrent
            ? iconSize * DRAWER_NAV_ITEM_ICON__ACTIVE_SIZE_FACTOR
            : iconSize
        }
      />
    );

    return iconTooltip ? (
      <Tooltip label={iconTooltip}>{iconEl}</Tooltip>
    ) : (
      iconEl
    );
  };

  return (
    <Button
      aria-current={isCurrent ? 'page' : false}
      color={isCurrent ? 'branding' : 'white'}
      component={Link}
      fullWidth
      justify="start"
      leftSection={getIcon()}
      pl="sm"
      size="sm"
      to={to}
      variant={isCurrent ? 'light' : 'subtle'}
    >
      <Text fw="500" size="sm">
        {label}
      </Text>
    </Button>
  );
};

export default DrawerNavItem;
