import { List } from '@mantine/core';
import { ComponentPropsWithoutRef, FC } from 'react';

import DrawerNavItem from '../DrawerNavItem';
import DrawerNavItemUser, {
  DrawerNavItemUserSkeleton,
} from '../DrawerNavItemUser';

interface Props {
  items?: ComponentPropsWithoutRef<typeof DrawerNavItem>[];
  users?: ComponentPropsWithoutRef<typeof DrawerNavItemUser>[];
}

const DrawerNavItemsList: FC<Props> = ({ items, users }) => {
  return (
    <List listStyleType="none" spacing="xs">
      {items?.map((item, index) => (
        <List.Item key={index} px="xs">
          <DrawerNavItem {...item} />
        </List.Item>
      ))}
      {users?.map(user => (
        <List.Item key={user.to}>
          <DrawerNavItemUser {...user} />
        </List.Item>
      ))}
    </List>
  );
};

export const DrawerNavItemsListSkeleton: FC<{ usersCount: number }> = ({
  usersCount,
}) => {
  return (
    <List listStyleType="none" p={0} px={0} spacing="xs">
      {Array.from({ length: usersCount }, (_, i) => (
        <List.Item key={i} px={0}>
          <DrawerNavItemUserSkeleton />
        </List.Item>
      ))}
    </List>
  );
};

export default DrawerNavItemsList;
