import { InvalidateQueryFilters, useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../config/http';
import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { BusinessFull } from '../../../../types/business';
import { getDataById } from '../../../../utils/array';
import { getUser } from '../../../user/api/cache/getters';
import { UserType } from '../../../user/types';
import { canViewBusiness } from '../../permissions';

const fetchBusiness = async () => {
  const { data } = await http.get<ApiResponse<BusinessFull>>('/businesses');

  return data.data;
};

// const fetchFacility = async (facilityId: null | string | undefined) => {
//   if (!facilityId) {
//     return;
//   }

//   const { data } = await http.get<ApiResponse<BusinessFull>>(
//     `/facilities/${facilityId}`,
//   );

//   return data.data;
// };

export const useBusinessQuery = () => {
  const query = useQuery({
    enabled: canViewBusiness() && getUser()?.type !== UserType.CompanyCoach,
    queryFn: fetchBusiness,
    queryKey: QueryKey.Business(),
  });

  return query;
};

export const invalidateBusiness = (filters?: InvalidateQueryFilters) =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.Business(),
    ...filters,
  });

export const cancelBusinessQueries = () =>
  queryClient.cancelQueries({
    queryKey: QueryKey.Business(),
  });

export const useFacilityQuery = (facilityId: null | string | undefined) => {
  const { data: business, ...rest } = useBusinessQuery();

  return {
    ...rest,
    data: getDataById(business?.facilities, facilityId),
  };
};
