/** @file Contains setters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import {
  getDataById,
  insertData,
  removeDataById,
  updateDataById,
} from '../../../../../utils/array';
import { MoveExerciseInstruction, MoveWorkout } from '../../../types';
import { getIsStandaloneTemplateWorkout } from '../../helpers';
import {
  StandaloneTemplateWorkout,
  TemplateExerciseInstruction,
  TemplateRoutineFull,
  TemplateRoutineWorkout,
} from '../../types';
import {
  getStandaloneTemplateWorkout,
  getStandaloneTemplateWorkouts,
  getTemplateRoutineFull,
  getTemplateRoutines,
} from './getters';
import { transformTemplateRoutineFullToList } from './helpers';
import {
  setStandaloneTemplateWorkout,
  setStandaloneTemplateWorkouts,
  setTemplateRoutineFull,
  setTemplateRoutines,
} from './setters';

/**
 * Updates the cache when routine is created
 * @param routine The new routine we want to set
 * @param qc      QueryClient
 */
export const createTemplateRoutine = (
  routine: TemplateRoutineFull,
  qc: QueryClient = queryClient,
) => {
  setTemplateRoutineFull(routine.id, routine);
  const templateRoutines = getTemplateRoutines(qc) ?? [];
  setTemplateRoutines(
    [...templateRoutines, transformTemplateRoutineFullToList(routine)],
    qc,
  );
};

/**
 * Updates the cache when routine is deleted
 * @param routineId The ID of the routine we want to delete
 * @param qc        QueryClient
 */
export const deleteTemplateRoutine = (
  routineId: string,
  qc: QueryClient = queryClient,
) => {
  setTemplateRoutineFull(routineId, undefined);
  const templateRoutines = getTemplateRoutines(qc);

  if (templateRoutines !== null) {
    setTemplateRoutines(removeDataById(templateRoutines, routineId));
  }

  setTemplateRoutineFull(routineId, undefined);
};

/**
 * Updates the cache for full routine and list routine
 * @param routineId The ID of the routine we want to delete
 * @param data      The routine data we want to update
 * @param qc        QueryClient
 */
export const setTemplateRoutineAll = (
  routineId: string,
  data: TemplateRoutineFull,
  qc = queryClient,
) => {
  setTemplateRoutineFull(routineId, data);

  const templateRoutines = getTemplateRoutines(qc);

  if (templateRoutines !== null) {
    setTemplateRoutines(
      updateDataById(
        templateRoutines,
        routineId,
        transformTemplateRoutineFullToList(data),
      ),
    );
  }
};

/**
 * Updates the cache when routine is edited
 * @param routineId The ID of the routine we want to delete
 * @param data      The routine data we want to update
 * @param qc        QueryClient
 */
export const editTemplateRoutine = (
  routineId: string,
  data: Partial<TemplateRoutineFull>,
  qc: QueryClient = queryClient,
) => {
  const routineFull = getTemplateRoutineFull(routineId);

  if (routineFull === null) {
    return;
  }

  const updated: TemplateRoutineFull = { ...routineFull, ...data };

  setTemplateRoutineAll(routineId, updated, qc);
};

/**
 * Deletes a cycle and related workouts from the template routine
 * @param routineId The routine ID to whom the cycle belongs
 * @param cycleId   The INDEX of the cycle we want to delete
 * @param qc        QueryClient
 */
export const deleteTemplateRoutineCycle = (
  routineId: string,
  cycleId: number,
  qc = queryClient,
) => {
  const routine = getTemplateRoutineFull(routineId, qc);

  if (routine !== null) {
    editTemplateRoutine(
      routineId,
      {
        cycles: routine.cycles.filter((_, i) => i !== cycleId),
        workouts: routine.workouts
          .filter(workout => workout.cycleId !== cycleId)
          .map(workout =>
            workout.cycleId > cycleId
              ? { ...workout, cycleId: workout.cycleId - 1 }
              : workout,
          ),
      },
      qc,
    );
  }
};

/**
 * Updates the cache when template workout is edited
 * @param routineId The ID of the routine the workout belongs to
 * @param workoutId The ID of the workout we want to edit.
 * @param data      The workout data we want to update
 * @param qc        QueryClient
 */
export const editTemplateWorkout = (
  routineId: null | string,
  workoutId: string,
  data: Partial<StandaloneTemplateWorkout | TemplateRoutineWorkout>,
  qc: QueryClient = queryClient,
) => {
  if (routineId === null) {
    const templateWorkout = getStandaloneTemplateWorkout(workoutId, qc);

    if (templateWorkout !== null) {
      setStandaloneTemplateWorkout(
        workoutId,
        data as Partial<StandaloneTemplateWorkout>,
      );
    }

    const templateWorkouts = getStandaloneTemplateWorkouts(qc);

    if (templateWorkouts !== null) {
      setStandaloneTemplateWorkouts(
        updateDataById(
          templateWorkouts,
          workoutId,
          data as Partial<StandaloneTemplateWorkout>,
        ),
      );
    }
  } else {
    const routineFull = getTemplateRoutineFull(routineId);

    if (routineFull === null) {
      return;
    }

    const workout = getDataById(routineFull.workouts, workoutId);

    if (workout === null) {
      return;
    }

    const updatedRoutine: TemplateRoutineFull = {
      ...routineFull,
      workouts: updateDataById(
        routineFull.workouts,
        workoutId,
        data as Partial<TemplateRoutineWorkout>,
      ),
    };

    setTemplateRoutineAll(routineId, updatedRoutine, qc);
  }
};

/**
 * Updates the cache when template workout is created
 * @param workout New workout
 * @param qc      QueryClient
 */
export const createTemplateWorkout = (
  workout: StandaloneTemplateWorkout | TemplateRoutineWorkout,
  qc: QueryClient = queryClient,
) => {
  const isStandaloneTemplateWorkout = getIsStandaloneTemplateWorkout(workout);

  if (isStandaloneTemplateWorkout) {
    setStandaloneTemplateWorkout(workout.id, workout, qc);
    const templateWorkouts = getStandaloneTemplateWorkouts(qc) ?? [];
    setStandaloneTemplateWorkouts(templateWorkouts.concat(workout));
  } else {
    const routineFull = getTemplateRoutineFull(workout.routineId, qc);

    if (routineFull === null) {
      return;
    }

    const positionToInsert = routineFull.workouts.findLastIndex(wo => {
      if (wo.cycleId > workout.cycleId) return false;

      return true;
    });

    const updated = {
      ...routineFull,
      workouts: insertData(
        routineFull.workouts,
        workout,
        positionToInsert < 0 ? 0 : positionToInsert + 1,
      ),
    };

    setTemplateRoutineAll(workout.routineId, updated, qc);
  }
};

/**
 * Updates the template routines cache after there was a change in exercise instruction ordering
 * @param arg                       Drag and drop payload
 * @param arg.exerciseInstructionId The ID of the exercise instruction we want to move
 * @param arg.groupId               The groupId it was moved to
 * @param arg.position              The position where it was moved
 * @param arg.routineId             The id of the routine
 * @param arg.workoutId             The id of the workout
 */
// eslint-disable-next-line complexity
export const moveExerciseInstruction = ({
  exerciseInstructionId,
  groupId,
  position,
  routineId,
  workoutId,
}: { routineId: null | string } & Omit<
  MoveExerciseInstruction,
  'routineId'
>) => {
  const routine = getTemplateRoutineFull(routineId ?? '');
  const templateWorkouts = getStandaloneTemplateWorkouts();

  const workout =
    getDataById(routine?.workouts, workoutId) ??
    getDataById(templateWorkouts, workoutId);

  if (workout === null) {
    console.error('Workout not found');
    return;
  }

  const copy = structuredClone(workout);

  const mappedByGroup = copy.exerciseInstructions.reduce((mapped, exInstr) => {
    if (mapped[exInstr.groupId] === undefined) {
      mapped[exInstr.groupId] = [exInstr];
    } else {
      mapped[exInstr.groupId].push(exInstr);
    }
    return mapped;
  }, [] as TemplateExerciseInstruction[][]);

  const toMoveExerciseInstruction = getDataById(
    copy.exerciseInstructions,
    exerciseInstructionId,
  );

  if (toMoveExerciseInstruction === null) {
    console.error('Exercise Instruction Not Found');
    return;
  }

  // Remove from source position
  mappedByGroup[toMoveExerciseInstruction.groupId].splice(
    toMoveExerciseInstruction.position,
    1,
  );

  // Moved to an empty group
  if (mappedByGroup[groupId] === undefined) {
    mappedByGroup[groupId] = [];
  }

  // insert at destination group
  mappedByGroup[groupId].splice(position, 0, {
    ...toMoveExerciseInstruction,
    groupId,
    position,
  });

  // re index destination group
  mappedByGroup[groupId].forEach((exInstr, i) => (exInstr.position = i));

  // moved to different group
  if (toMoveExerciseInstruction.groupId !== groupId) {
    // re index source group
    mappedByGroup[toMoveExerciseInstruction.groupId].forEach(
      (exInstr, i) => (exInstr.position = i),
    );
  }

  const updatedWorkout = {
    ...workout,
    exerciseInstructions: mappedByGroup.flat(),
  };

  editTemplateWorkout(routineId, workoutId, updatedWorkout);
};

/**
 * Updates the template routine cache after there was a change in exercise instruction ordering
 * @param arg           Drag and drop payload
 * @param arg.cycleId   The cycleId it was moved to
 * @param arg.position  The position where it was moved
 * @param arg.routineId The id of the routine
 * @param arg.workoutId The id of the workout
 */
export const moveTemplateRoutineWorkout = ({
  cycleId,
  position,
  routineId,
  workoutId,
}: MoveWorkout) => {
  const routine = getTemplateRoutineFull(routineId);

  if (routine === null) {
    console.error('Routine not found');
    return;
  }

  const copy = structuredClone(routine);

  const toMoveWorkout = getDataById(copy.workouts, workoutId);

  if (toMoveWorkout === null) {
    console.error('Workout Not Found');
    return;
  }

  const mappedByCycle = copy.workouts.reduce((mapped, workout) => {
    if (mapped[workout.cycleId] === undefined) {
      mapped[workout.cycleId] = [workout];
    } else {
      mapped[workout.cycleId].push(workout);
    }
    return mapped;
  }, [] as TemplateRoutineWorkout[][]);

  // Remove from source position
  mappedByCycle[toMoveWorkout.cycleId].splice(toMoveWorkout.position, 1);

  // Moved to a cycle that has no workouts. Empty cycles are not included in reduce
  if (mappedByCycle[cycleId] === undefined) {
    mappedByCycle[cycleId] = [];
  }

  // insert at destination group
  mappedByCycle[cycleId].splice(position, 0, {
    ...toMoveWorkout,
    cycleId,
    position,
  });

  // re index destination group
  mappedByCycle[cycleId].forEach((exInstr, i) => (exInstr.position = i));

  // moved to different group
  if (toMoveWorkout.cycleId !== cycleId) {
    // re index source group
    mappedByCycle[toMoveWorkout.cycleId].forEach(
      (exInstr, i) => (exInstr.position = i),
    );
  }

  const updatedRoutine = {
    ...routine,
    workouts: mappedByCycle.flat(),
  };

  editTemplateRoutine(routineId, updatedRoutine);
};
