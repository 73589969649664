/** @file Contains translations for api errors */
import { ApiErrorType } from '../../constants/errors/api-errors';
import { CognitoError } from '../../constants/errors/cognito';

interface Props {
  text: string;
  title: string;
}

const translationCognitoErrors: Record<CognitoError, Props> = {
  CodeMismatchException: {
    text: 'The verification code provided does not match the one sent to your email or phone.',
    title: 'Code Mismatch',
  },
  CognitoDefaultError: {
    text: 'An error occurred while processing your request. Please try again.',
    title: 'Something Went Wrong',
  },
  [CognitoError.ExpiredCodeException]: {
    text: 'The verification code you provided has expired. Please request a new code.',
    title: 'Expired Code',
  },
  [CognitoError.UserAlreadyAuthenticatedException]: {
    text: 'User Already Authenticated',
    title: 'User Already Authenticated',
  },
  InternalErrorException: {
    text: 'An internal error occurred while processing your request. Please try again later.',
    title: 'Internal Error',
  },
  InvalidPasswordException: {
    text: 'The password you provided is invalid. Please check your password and try again.',
    title: 'Invalid Password',
  },
  LimitExceededException: {
    text: 'The request exceeded a limit. Please try again later.',
    title: 'Limit Exceeded',
  },
  NotAuthorizedException: {
    text: 'You are not authorized to perform this action. Please sign in with appropriate credentials.',
    title: 'Not Authorized',
  },
  PasswordResetRequiredException: {
    text: 'You must reset your password before you can continue.',
    title: 'Password Reset Required',
  },
  TooManyRequestsException: {
    text: 'Too many requests have been made. Please try again later.',
    title: 'Too Many Requests',
  },
  UsernameExistsException: {
    text: 'The provided username already exists. Please choose a different username.',
    title: 'Username Exists',
  },
  UserNotFoundException: {
    text: 'The user does not exist. Please sign up or check the username.',
    title: 'User Not Found',
  },
  UserUnAuthenticatedException: {
    text: 'Please log in to continue. Your session may have expired.',
    title: 'Authentication Required',
  },
};

const translationApiErrors: Record<ApiErrorType, Props> = {
  [ApiErrorType.BadRequest]: {
    text: 'The request could not be processed due to invalid parameters.',
    title: 'Invalid Request',
  },
  [ApiErrorType.Conflict]: {
    text: 'The request conflicts with the current state of the resource.',
    title: 'Conflict',
  },
  [ApiErrorType.Database]: {
    text: 'An error occurred while processing database operations.',
    title: 'Database Error',
  },
  [ApiErrorType.DatabaseConnection]: {
    text: 'Unable to establish a connection to the database.',
    title: 'Connection Failed',
  },
  [ApiErrorType.DatabaseValidation]: {
    text: 'The data failed database validation checks.',
    title: 'Data Validation Error',
  },
  [ApiErrorType.Forbidden]: {
    text: 'You do not have permission to perform this action.',
    title: 'Access Denied',
  },
  [ApiErrorType.General]: {
    text: 'An unexpected error occurred on the server.',
    title: 'Server Error',
  },
  [ApiErrorType.NotAllowed]: {
    text: 'The requested method is not supported for this resource.',
    title: 'Method Not Allowed',
  },
  [ApiErrorType.NotFound]: {
    text: 'The requested resource could not be found.',
    title: 'Not Found',
  },
  [ApiErrorType.RequestValidation]: {
    text: 'The request data failed validation checks.',
    title: 'Validation Error',
  },
  [ApiErrorType.Unauthorized]: {
    text: 'You must be authenticated to access this resource.',
    title: 'Unauthorized',
  },
  [ApiErrorType.UnprocessableEntity]: {
    text: 'The request was unable to be processed as submitted.',
    title: 'Unprocessable Entity',
  },
};

const translationsApiErrors = {
  ...translationCognitoErrors,
  ...translationApiErrors,
  ExerciseDeleteUsedInExerciseInstructions: {
    text: 'This exercise is still used in some workouts. Please delete workouts or change the exercise.',
    title: 'Attention!',
  },
};

export default translationsApiErrors;
