import { Portal } from '@mantine/core';
import { FC } from 'react';

import CustomBreadcrumbs from '../../components/common/Breadcrumbs';
import HeaderMobile from '../../components/layout/HeaderMobile';
import { PageId } from '../../constants/page-ids';
import { PORTAL__HEADER_CONTENT } from '../../constants/portals';
import useIsMobile from '../../hooks/useIsMobile';
import { getPageLabel } from '../../i18n/helpers/page';
import useBreadcrumbs from './useBreadcrumbs';

const Breadcrumbs: FC = () => {
  const breadcrumbs = useBreadcrumbs();
  return (
    <Portal target={PORTAL__HEADER_CONTENT}>
      <CustomBreadcrumbs items={breadcrumbs} />
    </Portal>
  );
};

const HomeLayout: FC = () => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile === false && <Breadcrumbs />}
      <HeaderMobile
        Action={null}
        title={getPageLabel(PageId.Home)}
        withBackButton={false}
      />
    </>
  );
};

export default HomeLayout;
