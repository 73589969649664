/**
 * @file contains string utility functions
 */

/**
 * Trim whitespace and truncate string to maximum length
 * (if it exceeds it, append '...')
 * @param maxTextLength The maximum text length
 * @param originalText  The full text
 * @returns             The truncated text
 */
export const truncateText = (
  maxTextLength: number,
  originalText?: null | string,
) => {
  if (originalText === null || originalText === undefined) {
    return '';
  }

  if (maxTextLength < 0) {
    return originalText;
  }

  const trimmedText = originalText.trim();

  return trimmedText.length > maxTextLength
    ? trimmedText.substring(0, maxTextLength) + '...'
    : trimmedText;
};
