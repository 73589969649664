export enum ApiErrorType {
  BadRequest = 'Bad Request',
  Conflict = 'Conflict',
  Database = 'Database Error',
  DatabaseConnection = 'Database Connection Error',
  DatabaseValidation = 'Database Validation Error',
  Forbidden = 'Forbidden',
  General = 'Server Error',
  NotAllowed = 'Not Allowed',
  NotFound = 'Not Found',
  RequestValidation = 'Request Validation Error',
  Unauthorized = 'Unauthorized',
  UnprocessableEntity = 'Unprocessable Entity',
}
