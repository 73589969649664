import { translate } from '../../../../../../i18n';
import { isSuperAdmin } from '../../../../../user/utils';
import { Exercise } from '../../../../types';

export const getDuplicateTooltip = (exercise: Pick<Exercise, 'isPublic'>) => {
  if (exercise.isPublic && isSuperAdmin()) {
    return translate('exercise.duplicate');
  }

  return translate('exercise.personalize');
};
