import { useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../../config/http';
import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { ClientRoutineFull } from '../../types';

async function getFullRoutine(routineId: string): Promise<ClientRoutineFull> {
  const { data } = await http.get<ApiResponse<ClientRoutineFull>>(
    `/client-routines/${routineId}`,
  );
  return {
    ...data.data,
    workouts: data.data.workouts.map(wo => ({
      ...wo,
      exerciseInstructions: wo.exerciseInstructions.map(exInstr => ({
        ...exInstr,
        workoutId: wo.id,
      })),
    })),
  };
}

export const useClientRoutineFullQuery = (
  routineId: null | string,
  opt?: { enabled?: boolean },
) => {
  return useQuery({
    enabled: routineId !== null && opt?.enabled !== false,
    queryFn: () => getFullRoutine(routineId as string),
    queryKey: QueryKey.ClientRoutine(routineId as string),
  });
};

export const invalidateClientRoutine = (routineId: string) =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.ClientRoutine(routineId),
    refetchType: 'all',
  });

export const cancelClientRoutineQuery = (routineId: string) =>
  queryClient.cancelQueries({
    queryKey: QueryKey.ClientRoutine(routineId),
  });
