import { getUser } from '../user/api/cache/getters';
import {
  isCompanyAdmin,
  isCompanyCoach,
  isCompanyOwner,
  isStrencoAdmin,
  isSuperAdmin,
} from '../user/utils';

const canGeneral = () => {
  return (
    isSuperAdmin() || isStrencoAdmin() || isCompanyOwner() || isCompanyAdmin()
  );
};

export const canViewBusiness = () =>
  !!getUser()?.businessId && (canGeneral() || isCompanyCoach());

export const canViewBusinessAdmin = () => {
  return canGeneral();
};

export const canDeleteBusinessAdmin = () => {
  return isSuperAdmin() || isStrencoAdmin() || isCompanyOwner();
};

export const canUpdateBusinessAdmin = () => canGeneral();

export const canViewBusinessCoach = () => {
  return canGeneral() || isCompanyCoach();
};
