import { Anchor, Breadcrumbs, rem, Skeleton, Stack, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { translate } from '../../../i18n';
import { vars } from '../../../theme';
import classes from './styles.css';

export interface BreadcrumbItem {
  content: React.ReactNode | string;
  href: string;
  isLoading?: boolean;
  label?: string;
}
interface Props {
  isLoading?: boolean;
  items: Array<BreadcrumbItem>;
  loadingPlaceholdersCount?: number;
}

const CustomBreadcrumbs: React.FC<Props> = ({ items }) => {
  return (
    <Breadcrumbs
      aria-label={translate('aria.breadcrumb')}
      c="white"
      classNames={classes}
      component="nav"
      separator={
        <span style={{ color: vars.colors.dark[2] }}>
          <IconChevronRight size={20} />
        </span>
      }
      separatorMargin={0}
      style={{ borderRadius: vars.radius.md }}
    >
      {/* eslint-disable-next-line complexity */}
      {items.map((item, i) => {
        const isFirst = i === 0;
        const isLarge = isFirst || !item.label;

        const size = isLarge ? 'md' : 'sm';
        const pl = isFirst ? 0 : 'md';
        const skeletonHeight = isLarge ? 24 : 16;

        const label = (
          <Text
            c="white"
            component="label"
            fw={500}
            opacity={0.75}
            size={rem(11)}
            tt="uppercase"
          >
            {item.label}
          </Text>
        );

        if (items.length - 1 === i) {
          return (
            <Stack aria-current="page" gap={2} key={item.href} pl={pl} pr="md">
              {label}
              {item.isLoading === true ? (
                <Skeleton
                  h={skeletonHeight}
                  mih={skeletonHeight}
                  mt={4}
                  opacity={0.2}
                  w={100}
                />
              ) : (
                <Text c="branding.6" component="h5" fw="500" size={size}>
                  {item.content}
                </Text>
              )}
            </Stack>
          );
        }

        return (
          <Anchor
            className={classes.anchor}
            component={Link}
            h="100%"
            key={item.href}
            pl={pl}
            pr="md"
            py="sm"
            to={item.href}
            underline="never"
          >
            <Stack gap={0}>
              {label}
              {item.isLoading === true ? (
                <Skeleton
                  h={skeletonHeight}
                  mih={skeletonHeight}
                  mt={4}
                  opacity={0.2}
                  w={100}
                />
              ) : (
                <Text fw="500" size={size}>
                  {item.content}
                </Text>
              )}
            </Stack>
          </Anchor>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
