import { useEffect } from 'react';

const useScrollActiveLinkIntoView = (enabled?: boolean) => {
  useEffect(() => {
    if (enabled === false) {
      return;
    }
    setTimeout(() => {
      document
        .querySelector('a[aria-current="page"]')
        ?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }, [enabled]);
};

export default useScrollActiveLinkIntoView;
