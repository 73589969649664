import {
  ClientRoutineFull,
  ClientRoutineList,
} from '../../features/routine/@client/types';
import { translate } from '../../i18n';

export const getBreadcrumbRoutineItem = (
  routineFull: ClientRoutineFull | undefined,
  routineList: ClientRoutineList | null,
) => routineFull ?? routineList ?? null;

export const getBreadcrumbWorkoutName = <T extends { name: string }>(
  workout: null | T,
) => workout?.name ?? '';

export const getBreadcrumbWorkoutLabel = (
  routineFull: { workouts: { id: string; name: string }[] } | null | undefined,
  workoutId: string,
) => {
  const workoutIdx =
    routineFull?.workouts.findIndex(wo => wo.id === workoutId) ?? -1;
  const total = routineFull?.workouts?.length ?? 0;

  const suffix =
    workoutIdx > -1
      ? ` (${translate('common.of_double', {
          value1: workoutIdx + 1,
          value2: total,
        })})`
      : '';

  return `${translate('workout.workouts_one')}${suffix}`;
};
