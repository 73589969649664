import { rem, ScrollArea, Space, Stack, StackProps, Text } from '@mantine/core';
import { useHash } from '@mantine/hooks';
import { TablerIcon } from '@tabler/icons-react';
import { FC, memo, PropsWithChildren, useEffect, useRef } from 'react';

import { CLASS_NAME__EMPTY_HIDDEN } from '../../../constants/selectors';
import { vars } from '../../../theme';

export const DrawerGroupTitle = Text.withProps({
  c: 'gray.1',
  component: 'h4',
  fw: 600,
  lts: rem(1),
  size: 'xs',
  tt: 'uppercase',
});

export const DrawerGroupIcon: FC<{ Icon: TablerIcon }> = ({ Icon }) => {
  return <Icon style={{ height: rem(16), width: rem(16) }} />;
};

export const DrawerSidebarHeader: FC<
  PropsWithChildren<{ scrolled: boolean } & StackProps>
> = ({ children, scrolled, ...props }) => {
  return (
    <Stack
      align="flex-start"
      className={CLASS_NAME__EMPTY_HIDDEN}
      gap="xs"
      pb="sm"
      pos="sticky"
      px="sm"
      {...props}
      style={{
        boxShadow: scrolled ? '0 4px 12px -4px rgba(0,0,0,0.4)' : 'none',
        ...props.style,
        borderBottom: `1px solid ${vars.colors.dark[4]}`,
      }}
    >
      {children}
    </Stack>
  );
};

export const DrawerSidebarScrollArea: FC<
  PropsWithChildren<{ onScrolled?: (scrolled: boolean) => void }>
> = memo(({ children, onScrolled }) => {
  const scrollTopRef = useRef(0);
  const [hash] = useHash();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = scrollTopRef.current;
    }
  }, [hash]);

  return (
    <ScrollArea
      className={CLASS_NAME__EMPTY_HIDDEN}
      flex={1}
      onScroll={e => {
        scrollTopRef.current = e.currentTarget.scrollTop;
      }}
      onScrollPositionChange={({ y }) => onScrolled?.(y !== 0)}
      pt="sm"
      ref={ref}
      style={{ overflow: 'auto' }}
    >
      {children}
      <Space h={rem(64)} />
    </ScrollArea>
  );
});
