/** @file Contains helpers for ExercisePickerAutocomplete component */
import { Exercise } from '../../../types';

/**
 * Get the full name of an exercise including its name and variation (if available).
 * @param exercise The exercise object.
 * @returns        The full name of the exercise.
 */
export function getFullName(exercise: Exercise): string {
  return `${exercise.name}${
    exercise.variation ? ', ' + exercise.variation : ''
  }`;
}

/**
 * Create a memoized filter function for exercises based on a search query.
 * @returns A memoized function that filters exercises and returns an array of matching exercises, sorted by name and variation.
 */
export function createExerciseFilter() {
  const cachedResults = new Map<string, Exercise[]>();

  return (exercises: Exercise[], inputValue = ''): Exercise[] => {
    const input = (inputValue ?? '').toLowerCase().trim();

    if (!input) {
      // No input, return all exercises
      return exercises
        .slice()
        .sort((a, b) => getFullName(b).localeCompare(getFullName(a)));
    }

    if (cachedResults.has(input)) {
      // Return cached results if available
      return cachedResults.get(input) ?? [];
    }

    const inputSplitted = input.split(/[\s,]+/);

    const filteredExercises = exercises.filter(exercise => {
      const { name, primaryMuscles, variation } = exercise;

      const searchString = `${name}, ${variation}, ${primaryMuscles.join(
        ',',
      )}`.toLowerCase();

      return inputSplitted.every(searchWord =>
        searchString.includes(searchWord),
      );
    });

    const sortedExercises = sortExercises(filteredExercises);

    // Cache the result for future calls with the same input
    cachedResults.set(input, sortedExercises);

    return sortedExercises;
  };
}

/**
 * Sort exercises by name and then by name + variation.
 * @param exercises An array of exercises to sort.
 * @returns         An array of sorted exercises.
 */
function sortExercises(exercises: Exercise[]): Exercise[] {
  return exercises.sort((a, b) => {
    const nameComparison = a.name.localeCompare(b.name, undefined, {
      sensitivity: 'base',
    });
    if (nameComparison === 0) {
      return (
        (a.variation || '').localeCompare(b.variation ?? '', undefined, {
          sensitivity: 'base',
        }) || 0
      );
    }
    return nameComparison;
  });
}
