import { useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../../config/http';
import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { ClientRoutineList } from '../../types';

async function getAll({ clientId }: { clientId: null | string | undefined }) {
  if (!clientId) {
    return;
  }

  const { data } = await http.get<ApiResponse<ClientRoutineList[]>>(
    `/clients/${clientId}/routines`,
  );
  return data.data.map(r => ({
    ...r,
    workouts: r.workouts.map(w => ({
      ...w,
      clientId: r.clientId,
      routineId: r.id,
    })),
  }));
}

export const useClientRoutinesQuery = (clientId: null | string | undefined) => {
  return useQuery({
    enabled: clientId !== null && clientId !== undefined,
    queryFn: () => getAll({ clientId }),
    queryKey: QueryKey.ClientRoutines(clientId ?? null),
  });
};

export const invalidateClientRoutines = (clientId: string) =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.ClientRoutines(clientId),
  });

export const cancelClientRoutinesQuery = (clientId: string) =>
  queryClient.cancelQueries({
    queryKey: QueryKey.ClientRoutines(clientId),
  });
