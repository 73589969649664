/** @file Contains helpers for page stuff */

import { translate } from '..';
import { type PageId } from '../../constants/page-ids';

/**
 * Gets the label for page (is used for the sidebar links and document titles)
 * @param pageId The PageId
 * @returns      Translation for page label
 */
export const getPageLabel = (pageId: PageId) => {
  return translate(`page.${pageId}.label`);
};
