import { Group, rem, Stack, Text } from '@mantine/core';
import { FC, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { BOTTOM_NAVIGATION__HEIGHT } from '../../../constants/preferences';
import useBackNavigation from '../../../hooks/useGetBackUrl';
import {
  getIsMoreActive,
  getIsNavItemActive,
  getNavItemsMobile,
} from '../../../router/helpers/navigation';
import { createLocationState } from '../../../router/routes';
import { RailNavigationItemName } from '../../../router/types';
import { vars } from '../../../theme';

const NavigationMobile: FC = () => {
  const { getBackUrl } = useBackNavigation();
  const lastUrl = useRef<Record<string, string>>({});
  const location = useLocation();

  // eslint-disable-next-line complexity
  const links = getNavItemsMobile().map((item, i) => {
    if (item === null) {
      return null;
    }

    const isMorePage = item.name === RailNavigationItemName.More;

    // the last url when leaving the stack
    const current = item.name ? lastUrl.current[item.name] : null;

    const Icon = item.icon;

    const isActive = isMorePage
      ? getIsMoreActive(location.pathname)
      : getIsNavItemActive(location.pathname, item.to, []);

    const to = isActive ? getBackUrl() : (current ?? item.to);

    return (
      <NavLink
        key={i}
        onClick={() => {
          // We need this to track the last url for this particular stack.
          // When the user returns to this stack, it should be on the route it was left to
          if (isActive && item.name) {
            lastUrl.current[item.name] = to;
          }
        }}
        state={createLocationState()}
        style={{ textDecoration: 'none' }}
        to={to}
      >
        <Stack align="center" gap="xs" h="100%" justify="flex-end" pb="sm">
          {Icon !== null && (
            <Icon
              color={isActive ? vars.colors.branding[8] : vars.colors.gray[0]}
              size={isActive ? 28 : 24}
              stroke={1.5}
              style={{ transition: 'all .2s' }}
            />
          )}
          <Text
            c={isActive ? '#fff' : 'gray.2'}
            fw={isActive ? 600 : 500}
            size={rem(12)}
            style={{ transition: 'all .2s' }}
          >
            {item.label}
          </Text>
        </Stack>
      </NavLink>
    );
  });

  return (
    <Group
      align="stretch"
      bg="dark.8"
      bottom="0"
      component="nav"
      grow
      h={rem(BOTTOM_NAVIGATION__HEIGHT)}
      hiddenFrom="sm"
      justify="space-between"
      left={0}
      pos="fixed"
      px="xs"
      right={0}
      style={{
        boxShadow: '0px -3px 8px rgba(255, 255, 255, 0.1)',
        zIndex: 9999,
      }}
      w="100vw"
    >
      {links}
    </Group>
  );
};

export default NavigationMobile;
