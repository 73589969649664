/** @file Contains api and mutation hook to delete an exercise */
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import http from '../../../../../config/http';
import { MutationDelete } from '../../../../../constants/mutation-keys';
import {
  ExerciseMutationContext,
  exerciseMutationOnErrorHandler,
  exerciseMutationOnMutateHandler,
  exerciseMutationOnSettledHandler,
} from './handler';

async function deleteExercise(id: string): Promise<void> {
  return await http.delete(`/exercises/${id}`);
}

/**
 * Get exercise delete mutation (optimistic)
 * @returns Exercise delete mutation
 */
export const useExerciseDelete = () => {
  return useMutation<void, AxiosError, string, ExerciseMutationContext>({
    mutationFn: deleteExercise,
    mutationKey: [MutationDelete.Exercise],

    async onMutate(exerciseId: string) {
      return await exerciseMutationOnMutateHandler(exerciseId);
    },
    onSettled(_, error, exerciseId, context) {
      if (error) {
        exerciseMutationOnErrorHandler(context);
      }
      exerciseMutationOnSettledHandler(exerciseId);
    },
  });
};
