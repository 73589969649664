import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import React, {
  FC,
  lazy,
  PropsWithChildren,
  Suspense,
  useEffect,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from '../components/common/ErrorFallback';
import SplashScreen from '../components/layout/SplashScreen';
import envs from '../config/envs';
import { queryClient } from '../config/react-query';
import { useUser } from '../features/user/api/queries/useUser';
import { getCurrentStorageUser } from '../features/user/utils';
import { init18n } from '../i18n/';
import SidebarProvider from '../providers/sidebar/provider';
import { isDev, isLocal } from '../utils/environment';
import AppAuthenticated from './AppAuthenticated/AppAuthenticated';

const AppUnauthenticated = lazy(
  () => import('./AppUnauthenticated/AppUnauthenticated'),
);

const OfflineIndicator = lazy(
  () => import('../components/common/OfflineIndicator'),
);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: envs.cognito.clientId,
      userPoolId: envs.cognito.userPoolId,
    },
  },
});

const lazyImport = async () => {
  await Promise.all([init18n()]);
};

const Boundary: FC<PropsWithChildren> = ({ children }) => {
  if (isLocal()) {
    return children;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={console.error}>
      {children}
    </ErrorBoundary>
  );
};

const App: React.FC = () => {
  const storageUser = getCurrentStorageUser();

  const [loaded, setLoaded] = useState(false);
  const { data: sessionUser = storageUser } = useUser();

  const isAppReady = loaded === true;

  useEffect(() => {
    lazyImport().then(() =>
      setTimeout(() => {
        setLoaded(true);
      }, 100),
    );
  }, []);

  return (
    <>
      <Suspense fallback={null}>
        <OfflineIndicator />
      </Suspense>
      {isAppReady === false ? (
        <SplashScreen />
      ) : (
        <Boundary>
          {sessionUser === null ? (
            <Suspense fallback={null}>
              <AppUnauthenticated />
            </Suspense>
          ) : (
            <SidebarProvider>
              <AppAuthenticated />
            </SidebarProvider>
          )}
        </Boundary>
      )}

      {isDev() === true && (
        <ReactQueryDevtools client={queryClient} initialIsOpen={false} />
      )}
    </>
  );
};

export default App;
