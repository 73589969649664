import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { useClients } from '../../features/clients/api/queries/useClients';
import { getFullName } from '../../features/clients/helpers';
import { useClientRoutinesQuery } from '../../features/routine/@client/api/queries/useClientRoutinesQuery';
import { useClientWorkoutQuery } from '../../features/routine/@client/api/queries/useClientWorkoutQuery';
import { translate } from '../../i18n';
import {
  getBreadcrumbWorkoutLabel,
  getBreadcrumbWorkoutName,
} from '../../router/breadcrumbs/helpers';
import {
  useParamClientId,
  useParamRoutineId,
  useParamWorkoutId,
} from '../../router/hooks/path-params';
import { routes } from '../../router/routes';
import { getDataById } from '../../utils/array';

// eslint-disable-next-line complexity
const useBreadcrumbs = (): BreadcrumbItem[] => {
  const clientId = useParamClientId();
  const routineId = useParamRoutineId();
  const workoutId = useParamWorkoutId();

  const { data: clients } = useClients();
  const { data: routines } = useClientRoutinesQuery(clientId);
  const { data: workout } = useClientWorkoutQuery(workoutId);

  const client = clients?.find(c => c.id === clientId);

  const items: BreadcrumbItem[] = [];

  if (clientId !== null) {
    items.push({
      content: client ? getFullName(client) : '',
      href: routes.clients.byId(clientId),
      isLoading: client === undefined,
      label: translate('client.label_one'),
    });

    const routine = getDataById(routines, routineId);

    if (routineId !== null) {
      items.push({
        content: routine?.name,
        href: routes.clients.routines(clientId, routineId),
        isLoading: routine === null,
        label: translate('routine.routines_one'),
      });

      const workoutItem = getDataById(routine?.workouts, workoutId) ?? workout;

      if (workoutId !== null) {
        items.push({
          content: getBreadcrumbWorkoutName(workoutItem ?? workout),
          href: routes.clients.workouts(clientId, routineId, workoutId),
          isLoading: workoutItem === null,
          label: getBreadcrumbWorkoutLabel(routine, workoutId),
        });
      }
    }
  }

  return items;
};

export default useBreadcrumbs;
