import { FC } from 'react';

import HeaderMobile from '../../../../components/layout/HeaderMobile';
import { PageId } from '../../../../constants/page-ids';
import BusinessAdminsHeaderAction from '../../../../features/business/components/AdminsHeaderAction';
import BusinessSecondaryNavigation from '../../../../features/business/components/BusinessLayout/BusinessSecondaryNavigation';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getPageLabel } from '../../../../i18n/helpers/page';

const BusinessAdminsLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={BusinessAdminsHeaderAction}
        title={getPageLabel(PageId.Admins)}
      />
    );
  }

  return <BusinessSecondaryNavigation />;
};

export default BusinessAdminsLayout;
