import { IconPoint } from '@tabler/icons-react';
import { ComponentProps } from 'react';

import DrawerNavItem from '../../../../components/common/DrawerNavItem';
import { MenuItem } from '../../../../components/ui/menus/CustomMenu';
import { PageId } from '../../../../constants/page-ids';
import { translate } from '../../../../i18n';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { routes } from '../../../../router/routes';
import { BusinessFull } from '../../../../types/business';
import { ModalId, ModalOpenHandler } from '../../../../utils/modal';
import { isCompanyOwner } from '../../../user/utils';

export const getLinksPeople = (isMobile = false) => {
  const Icon = isMobile ? undefined : IconPoint;
  const linksPeople: ComponentProps<typeof DrawerNavItem>[] = [
    {
      Icon,
      label: getPageLabel(PageId.Clients),
      to: routes.business.clients.root(),
    },
    {
      Icon,
      label: getPageLabel(PageId.Coaches),
      to: routes.business.coaches.root(),
    },
  ];

  if (isCompanyOwner()) {
    linksPeople.push({
      Icon,
      label: getPageLabel(PageId.Admins),
      to: routes.business.admins.root(),
    });
  }

  return linksPeople;
};

export const getFacilitiesLinks = (
  business: BusinessFull | undefined,
  isMobile = false,
) => {
  const facilityLinks: ComponentProps<typeof DrawerNavItem>[] = [];
  const Icon = isMobile ? undefined : IconPoint;

  if (business !== undefined) {
    business.facilities.forEach(facility => {
      facilityLinks.push({
        Icon,
        label: facility.name,
        to: routes.business.facilities.byId(facility.id),
      });
    });
  }

  return facilityLinks;
};

export const getUserCreateMenuItems = (
  openModal: ModalOpenHandler,
): MenuItem[] => {
  const items = [
    {
      label: translate('business.people_create_client'),
      onClick: () => openModal(ModalId.BusinessClientCreate),
    },
    {
      label: translate('business.people_create_coach'),
      onClick: () => openModal(ModalId.BusinessCoachCreate),
    },
  ];

  if (isCompanyOwner()) {
    items.push({
      label: translate('business.people_create_admin'),
      onClick: () => openModal(ModalId.BusinessAdminCreate),
    });
  }

  return items;
};
