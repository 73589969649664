/** @file Contains getters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { Client, ClientListItem } from '../../../../types/clients';

/**
 * Updates the client full in the cache
 * @param clientId The id of the client
 * @param data     The data we want to set
 * @param qc       QueryClient
 * @returns        Client Routine
 */
export const setClientFull = (
  clientId: string,
  data: Client | null | undefined,
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData(QueryKey.Client(clientId), data);
};

/**
 * Updates the clients list cache
 * @param clients Clients lit we want to set
 * @param qc      QueryClient
 * @returns       Client Routine
 */
export const setClients = (
  clients: ClientListItem[] | null | undefined,
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData(QueryKey.Clients(), clients);
};
