import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActionIconHeaderActions } from '../../../../../components/common/ActionIcons';
import { iconsMap } from '../../../../../components/common/ActionIcons/config';
import CustomMenu, {
  MenuItem,
} from '../../../../../components/ui/menus/CustomMenu';
import { MutationDelete } from '../../../../../constants/mutation-keys';
import { translate } from '../../../../../i18n';
import { useParamRoutineId } from '../../../../../router/hooks/path-params';
import { routes } from '../../../../../router/routes';
import { SearchParam } from '../../../../../router/types';
import { ModalId, useModal } from '../../../../../utils/modal';
import { confirmDanger } from '../../../../../utils/modal/confirm';
import { useTemplateRoutineDelete } from '../../api/mutations/useTemplateRoutineDelete';

const TemplateRoutineHeaderActions: FC = () => {
  const navigate = useNavigate();
  const routineId = useParamRoutineId() ?? '';
  const { openModal } = useModal();
  const { isPending: isDeleting, mutateAsync: deleteRoutineMutation } =
    useTemplateRoutineDelete();

  const backUrl = routes.library.routines.root();

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.TemplateRoutine,
      onConfirm: () => {
        deleteRoutineMutation(routineId);
        navigate(backUrl, { replace: true });
      },
    });
  };

  const requestEdit = () => {
    openModal(ModalId.TemplateRoutineDetailsEdit, {
      [SearchParam.EntityId]: routineId,
    });
  };

  const items: MenuItem[] = [
    {
      Icon: iconsMap.edit.Icon,
      label: translate('routine.edit'),
      onClick: requestEdit,
    },
    {
      Icon: iconsMap.delete.Icon,
      label: translate('routine.delete'),
      onClick: requestDelete,
    },
  ];

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      tooltip={translate('routine.actions')}
      trigger={<ActionIconHeaderActions loading={isDeleting} />}
    />
  );
};

export default TemplateRoutineHeaderActions;
