/** @file Contains helpers for forms */
import { translate } from '..';
import { type FormId } from '../../constants/forms/form';
import { type FieldId } from '../../constants/forms/form-fields';
import { type FormValidationError } from '../../constants/forms/form-validation-errors';

/**
 * Gets the translated description for a form field
 * @param fieldId The form field id
 * @returns       Translation for field description
 */
export const getFieldDescription = (fieldId: FieldId): string =>
  translate(`formField.${fieldId}.description`);

/**
 * Gets the translated info for a form field
 * @param fieldId The form field id
 * @returns       Translation for field info
 */
export const getFieldInfo = (fieldId: FieldId): string =>
  translate(`formField.${fieldId}.info`);

/**
 * Gets the translated label for a form field
 * @param fieldId The form field id
 * @returns       Translation for field label
 */
export const getFieldLabel = (fieldId: FieldId): string =>
  translate(`formField.${fieldId}.label`);

/**
 * Gets the translated placeholder for a form field
 * @param fieldId The form field id
 * @returns       Translation for field placeholder
 */
export const getFieldPlaceholder = (fieldId: FieldId): string =>
  translate(`formField.${fieldId}.placeholder`);

/**
 * Gets the translated title for a form title
 * @param formId The form id
 * @returns      Translation for field title
 */
export const getFormTitle = (formId: FormId): string =>
  translate(`form.${formId}.title`);

/**
 * Gets the translated info for a form
 * @param formId The form id
 * @returns      Translation for form info
 */
export const getFormInfo = (formId: FormId): string =>
  translate(`form.${formId}.info`);

/**
 * Gets the translated description for a form
 * @param formId The form id
 * @returns      Translation for form description
 */
export const getFormDescription = (formId: FormId): string =>
  translate(`form.${formId}.description`);

/**
 * Gets the translated info for a form
 * @param formId The form id
 * @returns      Translation for form info
 */
export const getFormButtonTooltip = (formId: FormId): string =>
  translate(`form.${formId}.description`);

/**
 * Gets the translated description for a form validation error message
 * @param validationError The form validation error
 * @returns               Translation for field message
 */
export const getFieldValidationErrorMessage = (
  validationError: FormValidationError,
): string => translate(`formFieldValidationError.${validationError}.message`);

/**
 * Gets the translated description for a form validation error info
 * @param validationError The form validation error
 * @returns               Translation for field info
 */
export const getFieldValidationErrorInfo = (
  validationError: FormValidationError,
): string => translate(`formFieldValidationError.${validationError}.info`);
