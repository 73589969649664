import {
  PATH__BUSINESS,
  PATH__BUSINESS__CLIENTS__BY_ID,
  PATH__BUSINESS__COACHES__BY_ID,
  PATH__BUSINESS__FACILITIES,
  PATH__CLIENTS,
  PATH__LIBRARY,
  PATH__ME__ROUTINE__BY_ID,
  PATH__SETTINGS,
} from '../../router/paths/paths';

const withSidebar = [
  PATH__BUSINESS,
  PATH__BUSINESS__FACILITIES,
  PATH__CLIENTS,
  PATH__LIBRARY,
  PATH__SETTINGS,
  PATH__ME__ROUTINE__BY_ID,
  PATH__BUSINESS__CLIENTS__BY_ID,
  PATH__BUSINESS__COACHES__BY_ID,
];

export const getHasSidebar = (currentPath: null | string) => {
  const hasSidebarContent = withSidebar?.some(path =>
    currentPath?.startsWith(path),
  );

  return hasSidebarContent;
};
