import { FC, lazy, Suspense } from 'react';

import PageLayout from '../../components/layout/PageLayout';
import { PageId } from '../../constants/page-ids';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { getPageLabel } from '../../i18n/helpers/page';
import Layout from './layout';

const Content = lazy(() => import('./content'));

const HomePage: FC = () => {
  useDocumentTitle(getPageLabel(PageId.Home));

  return (
    <PageLayout>
      <Layout />
      <Suspense fallback={null}>
        <Content />
      </Suspense>
    </PageLayout>
  );
};

export default HomePage;
