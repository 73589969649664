import { useQuery, UseQueryResult } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../../config/http';
import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { Exercise } from '../../../types';

/**
 * Description placeholder
 * @param exerciseId ExerciseId
 * @returns          Promise with the requested exercise
 */
async function getExercise(exerciseId: string): Promise<Exercise> {
  const { data } = await http.get<ApiResponse<Exercise>>(
    `/exercises/${exerciseId}`,
  );
  return data.data;
}

export const useExerciseQuery = (
  exerciseId: null | string,
): UseQueryResult<Exercise> => {
  return useQuery({
    enabled: exerciseId !== null,
    queryFn: () => getExercise(exerciseId as string),
    queryKey: QueryKey.Exercise(exerciseId),
  });
};

export const invalidateExercise = (exerciseId: string) =>
  queryClient.invalidateQueries({ queryKey: QueryKey.Exercise(exerciseId) });

export const cancelExerciseQuery = (exerciseId: string) =>
  queryClient.cancelQueries({ queryKey: QueryKey.Exercise(exerciseId) });
