import { FC } from 'react';

import HeaderMobile from '../../../components/layout/HeaderMobile';
import { PageId } from '../../../constants/page-ids';
import { useClientRoutineFullQuery } from '../../../features/routine/@client/api/queries/useClientRoutineFullQuery';
import ClientRoutineFullHeaderActions from '../../../features/routine/@client/components/RoutineFullHeaderActions';
import RoutineFullSecondaryNavigation from '../../../features/routine/components/routine/RoutineFullSecondaryNavigation';
import useIsMobile from '../../../hooks/useIsMobile';
import { getPageLabel } from '../../../i18n/helpers/page';
import { useParamRoutineId } from '../../../router/hooks/path-params';

const RoutineFullLayout: FC = () => {
  const isMobile = useIsMobile();
  const routineId = useParamRoutineId();
  const { data: routine } = useClientRoutineFullQuery(routineId);

  if (isMobile) {
    return (
      <HeaderMobile
        Action={ClientRoutineFullHeaderActions}
        title={getPageLabel(PageId.ClientsRoutineDetails)}
      />
    );
  }

  return (
    <RoutineFullSecondaryNavigation
      pageId={PageId.ClientsRoutineDetails}
      routine={routine}
    />
  );
};

export default RoutineFullLayout;
