import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../../constants/page-ids';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../../i18n/helpers/page';
import Layout from './layout';

const Content = lazy(() => import('./content'));

const BusinessClientsRootPage: FC = () => {
  useDocumentTitle(getPageLabel(PageId.Clients));

  return (
    <>
      <Layout />
      <Suspense fallback={null}>
        <Content />
      </Suspense>
    </>
  );
};

export default BusinessClientsRootPage;
