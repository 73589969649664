import { isAxiosError } from 'axios';
import { FC, useLayoutEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Navigate } from 'react-router-dom';

import { routes } from '../../../router/routes';
import { ErrorParam } from '../../../types';

const statusCodesMap: Record<number, ErrorParam> = {
  403: ErrorParam.Forbidden,
  404: ErrorParam.NotFound,
  500: ErrorParam.General,
};

const getErrorParam = (error: any): ErrorParam => {
  if (isAxiosError(error) === false) {
    return ErrorParam.General;
  }

  return statusCodesMap[error.status ?? 500];
};

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  useLayoutEffect(() => {
    resetErrorBoundary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetErrorBoundary]);

  return <Navigate to={routes.error.byErrorType(getErrorParam(error))} />;
};

export default ErrorFallback;
