import { useLocation } from 'react-router-dom';

import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { useBusinessQuery } from '../../features/business/api/queries/business';
import { useClientFull } from '../../features/clients/api/queries/useClientFull';
import { useClientRoutineFullQuery } from '../../features/routine/@client/api/queries/useClientRoutineFullQuery';
import { useClientWorkoutQuery } from '../../features/routine/@client/api/queries/useClientWorkoutQuery';
import {
  getBreadcrumbBusiness,
  getBreadcrumbBusinessAdmin,
  getBreadcrumbBusinessCoach,
  getBreadcrumbBusinessFacility,
} from '../../router/breadcrumbs/business';
import { getBreadCrumbClientFull } from '../../router/breadcrumbs/clients';
import { getBreadcrumbRoutineFull } from '../../router/breadcrumbs/routines';
import { getBreadcrumbWorkoutFull } from '../../router/breadcrumbs/workouts';
import {
  useParamAdminId,
  useParamClientId,
  useParamCoachId,
  useParamFacilityId,
  useParamRoutineId,
  useParamWorkoutId,
} from '../../router/hooks/path-params';
import useCurrentPath from '../../router/hooks/useCurrentPath';
import { getDataById } from '../../utils/array';

// eslint-disable-next-line complexity
const useBreadcrumbs = (): BreadcrumbItem[] => {
  const currentPath = useCurrentPath();
  const location = useLocation();

  const paramAdminId = useParamAdminId();
  const paramClientId = useParamClientId();
  const paramCoachId = useParamCoachId();
  const paramFacilityId = useParamFacilityId();
  const paramRoutineId = useParamRoutineId();
  const paramWorkoutId = useParamWorkoutId();

  const { data: business } = useBusinessQuery();
  const { data: clientFull } = useClientFull(paramClientId);
  const { data: routineFull } = useClientRoutineFullQuery(paramRoutineId);
  const { data: workoutFull } = useClientWorkoutQuery(paramWorkoutId);

  const rootBreadcrumb = getBreadcrumbBusiness(currentPath);

  const items: BreadcrumbItem[] =
    rootBreadcrumb === null ? [] : [rootBreadcrumb];

  if (paramFacilityId !== null) {
    const facility = getDataById(business?.facilities, paramFacilityId);
    items.push(getBreadcrumbBusinessFacility(facility));
  }

  if (paramAdminId !== null) {
    const admin = business?.admins?.find(admin => admin.id === paramAdminId);

    items.push(getBreadcrumbBusinessAdmin(admin, currentPath, location));
  }

  if (paramCoachId !== null) {
    const coach = business?.coaches?.find(coach => coach.id === paramCoachId);

    items.push(getBreadcrumbBusinessCoach(coach, currentPath, location));
  }

  if (paramClientId !== null) {
    items.push(getBreadCrumbClientFull(clientFull, currentPath, location));
  }

  if (paramRoutineId !== null && paramClientId !== null) {
    items.push(getBreadcrumbRoutineFull(routineFull, currentPath, location));

    if (paramWorkoutId !== null) {
      items.push(getBreadcrumbWorkoutFull(workoutFull, currentPath, location));
    }
  }

  return items;
};

export default useBreadcrumbs;
