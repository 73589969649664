/**
 * @file Contains hooks for params
 */
import { useParams } from 'react-router-dom';

import { ErrorParam, ProxyParam } from '../../types';
import { PaddleProductType } from '../../types/product.types';

/**
 * Get the clientId param from the URL
 * @returns Client Id or null
 */
export const useParamClientId = () => {
  const { clientId } = useParams();

  return clientId ?? null;
};

/**
 * Get the adminId param from the URL
 * @returns Client Id or null
 */
export const useParamAdminId = () => {
  const { adminId } = useParams();

  return adminId ?? null;
};

/**
 * Get the coachId param from the URL
 * @returns Coach Id or null
 */
export const useParamCoachId = () => {
  const { coachId } = useParams();

  return coachId ?? null;
};

/**
 * Get the routineId param from the URL
 * @returns Routine Id or null
 */
export const useParamRoutineId = () => {
  const { routineId } = useParams();

  return routineId ?? null;
};

/**
 * Get the workoutId param from the URL
 * @returns Workout Id or null
 */
export const useParamWorkoutId = () => {
  const { workoutId } = useParams();

  return workoutId ?? null;
};

/**
 * Get the priceId param from the URL
 * @returns Price Id or null
 */
export const useParamPriceId = () => {
  const { priceId } = useParams();

  return priceId ?? null;
};

/**
 * Get the proxy type param from the URL
 * @returns Param or null
 */
export const useParamProxyType = () => {
  const { proxyType } = useParams();

  return (proxyType as ProxyParam) ?? null;
};

/**
 * Used for onboarding flow
 * @returns the current step the user is in
 */
export const useParamProductType = () => {
  const { productType } = useParams();

  return (productType ?? null) as null | PaddleProductType;
};

/**
 * Used for error page
 * @returns the current error type in the url
 */
export const useParamError = () => {
  const { error } = useParams();

  return (error as ErrorParam) ?? null;
};

/**
 * Used for exercise full page
 * @returns the current exerciseId type in the url
 */
export const useParamExerciseId = () => {
  const { exerciseId } = useParams();

  return exerciseId ?? null;
};

/**
 * Used for facility page
 * @returns the current facility id in the url
 */
export const useParamFacilityId = () => {
  const { facilityId } = useParams();

  return facilityId ?? null;
};
