import {
  Client,
  ClientListItem,
  UpdateClientAttributes,
} from '../../../../types/clients';
import { getClientFull, getClients } from '../cache/getters';
import { deleteClient, updateClient } from '../cache/mutations';
import { setClientFull, setClients } from '../cache/setters';
import {
  cancelClientFullQueries,
  invalidateClientFull,
} from '../queries/useClientFull';
import { cancelClientsQuery, invalidateClients } from '../queries/useClients';

export const clientMutationOnMutateHandler = async (
  clientId: string,
  data?: Partial<UpdateClientAttributes>,
): Promise<ClientMutationContext> => {
  const clientFull = getClientFull(clientId);
  const clientsAll = getClients();

  await Promise.all([cancelClientFullQueries(clientId), cancelClientsQuery()]);

  if (data !== undefined) {
    updateClient(clientId, data);
  } else {
    // delete mutation
    deleteClient(clientId);
  }

  return { clientFull, clientsAll };
};

export const clientMutationOnSettledHandler = (clientId: string) => {
  invalidateClientFull(clientId);
  invalidateClients();
};

export const clientMutationOnErrorHandler = (
  context: ClientMutationContext | undefined,
) => {
  const { clientFull = null, clientsAll = null } = context ?? {};

  if (clientFull !== null) {
    setClientFull(clientFull.userId, clientFull);
  }

  if (clientsAll !== null) {
    setClients(clientsAll);
  }
};

export interface ClientMutationContext {
  clientFull: Client | null;
  clientsAll: ClientListItem[] | null;
}
