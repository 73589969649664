const translationsAria = {
  add_bodyweight: 'Add bodyweight',
  add_check_in: 'Add new check in',
  breadcrumb: 'breadcrumb',
  change_exercise: 'Click to change exercise',
  edit_avatar: 'Change Avatar Image',
  show_chart_view: 'Show chart view',
  show_list_view: 'Show list view',
  user_status: `{{name}}'s status: {{status}}`,
};

export default translationsAria;
