import { Portal, Transition } from '@mantine/core';
import { TablerIcon } from '@tabler/icons-react';
import { FC, PropsWithChildren } from 'react';

import { PORTAL__SIDEBAR_CONTENT } from '../../../constants/portals';
import useSidebarContext from '../../../providers/sidebar/useSidebarContext';
import * as El from './elements';

interface Props {
  icon?: TablerIcon;
  title: string;
}

const scaleX = {
  common: { transformOrigin: 'left' },
  in: { transform: 'scaleX(1)' },
  out: { transform: 'scaleX(0)' },
  transitionProperty: 'transform',
};

const DrawerSidebar: FC<PropsWithChildren<Props>> = ({ children, title }) => {
  const { isOpen } = useSidebarContext();

  return (
    <Portal target={PORTAL__SIDEBAR_CONTENT}>
      <Transition
        duration={0}
        mounted={isOpen}
        timingFunction="linear"
        transition={scaleX}
      >
        {transitionStyles => (
          <El.Wrapper style={transitionStyles}>
            <El.Header component="header">
              <El.Heading>{title}</El.Heading>
            </El.Header>
            <El.WrapperInner>{children}</El.WrapperInner>
          </El.Wrapper>
        )}
      </Transition>
    </Portal>
  );
};

export default DrawerSidebar;
