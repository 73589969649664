/**
 * @file Contains types used for modals
 */
import { SearchParam } from '../../router/types';

export enum ModalId {
  BusinessAdminCreate = 'Modal_BusinessAdminCreate',
  BusinessAdminEdit = 'Modal_BusinessAdminEdit',
  BusinessClientCreate = 'Modal_BusinessClientCreate',
  BusinessClientEdit = 'Modal_BusinessClientEdit',
  BusinessCoachCreate = 'Modal_BusinessCoachCreate',
  BusinessCoachEdit = 'Modal_BusinessCoachEdit',
  BusinessCoachRankCreate = 'Modal_BusinessCoachRankCreate',
  BusinessCoachRankEdit = 'Modal_BusinessCoachRankEdit',
  BusinessFacilityCreate = 'Modal_BusinessFacilityCreate',
  BusinessFacilityEdit = 'Modal_BusinessFacilityEdit',
  ChangePassword = 'Modal_ChangePassword',
  ClientCreate = 'Modal_ClientCreate',
  ClientEdit = 'Modal_ClientEdit',
  ClientRoutineCreate = 'Modal_ClientRoutineCreate',
  ClientRoutineCycleCreate = 'Modal_ClientRoutineCycleCreate',
  ClientRoutineCycleEdit = 'Modal_ClientRoutineCycleEdit',
  ClientRoutineDetailsEdit = 'Modal_ClientRoutineDetailsEdit',
  ClientWorkoutCreate = 'Modal_ClientWorkoutCreate',
  ClientWorkoutDetailsEdit = 'Modal_ClientWorkoutDetailsEdit',
  Exercise = 'Modal_Exercise',
  GenerateClientRoutines = 'Modal_GenerateClientRoutines',
  SubscriptionChangePlan = 'Modal_SubscriptionChangePlan',
  TemplateRoutineCreate = 'Modal_TemplateRoutineCreate',
  TemplateRoutineCycleCreate = 'Modal_TemplateRoutineCycleCreate',
  TemplateRoutineCycleEdit = 'Modal_TemplateRoutineCycleEdit',
  TemplateRoutineDetailsEdit = 'Modal_TemplateRoutineDetailsEdit',
  TemplateWorkoutCreate = 'Modal_TemplateWorkoutCreate',
  TemplateWorkoutDetailsEdit = 'Modal_TemplateWorkoutDetailsEdit',
  UserProfileEdit = 'Modal_UserProfileEdit',
}

export interface ModalAccessibleOptions {
  /**
   * Indicates whether any data required for validating the modal's accessibility
   * is currently being fetched. This check is performed before more complex
   * validation operations, allowing for early handling of fetching state.
   */
  fetching?: boolean;
  /**
   * A collection of conditions that must be true in order to display the modal.
   * These conditions can include permissions checks, existence of entities, etc.
   * Using validators helps manage code complexity when many checks are required.
   */
  validators?: boolean[];
}

export interface ParamConfig {
  /**
   * Optional params that can be added for modals
   */
  optional?: SearchParam[];
  /**
   * The params that are required to show the modal
   */
  required?: SearchParam[];
}

export interface ModalParams {
  [ModalId.BusinessAdminCreate]: {
    [SearchParam.Facilities]: null | string;
  };
  [ModalId.BusinessAdminEdit]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.BusinessClientCreate]: {
    [SearchParam.CoachId]: null | string;
    [SearchParam.Facilities]: null | string;
  };

  [ModalId.BusinessClientEdit]: {
    [SearchParam.ClientId]: null | string;
  };
  [ModalId.BusinessCoachCreate]: {
    [SearchParam.Facilities]: null | string;
  };
  [ModalId.BusinessCoachEdit]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.BusinessCoachRankCreate]: undefined;
  [ModalId.BusinessCoachRankEdit]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.BusinessFacilityCreate]: undefined;
  [ModalId.BusinessFacilityEdit]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.ChangePassword]: undefined;
  [ModalId.ClientCreate]: undefined;
  [ModalId.ClientEdit]: {
    [SearchParam.ClientId]: null | string;
  };
  [ModalId.ClientRoutineCreate]: {
    [SearchParam.ClientId]: null | string;
  };
  [ModalId.ClientRoutineCycleCreate]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.ClientRoutineCycleEdit]: {
    [SearchParam.CycleId]: null | number;
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.ClientRoutineDetailsEdit]:
    | { [SearchParam.EntityId]: null | string }
    | undefined;
  [ModalId.ClientWorkoutCreate]: {
    [SearchParam.ClientId]: null | string;
    [SearchParam.CycleId]: null | number;
  };
  [ModalId.ClientWorkoutDetailsEdit]: { [SearchParam.EntityId]: null | string };
  [ModalId.Exercise]?: { [SearchParam.ExerciseId]: string };
  [ModalId.GenerateClientRoutines]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.SubscriptionChangePlan]: undefined;
  [ModalId.TemplateRoutineCreate]: undefined;
  [ModalId.TemplateRoutineCycleCreate]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.TemplateRoutineCycleEdit]: {
    [SearchParam.CycleId]: null | number;
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.TemplateRoutineDetailsEdit]:
    | { [SearchParam.EntityId]: null | string }
    | undefined;
  [ModalId.TemplateWorkoutCreate]: {
    [SearchParam.CycleId]: null | number;
  };
  [ModalId.TemplateWorkoutDetailsEdit]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.UserProfileEdit]: undefined;
}

export type ModalOpenHandler = <T extends ModalId>(
  modalId: T,
  paramConfig?: ModalParams[T],
) => void;
