import { InvalidateQueryFilters, useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../../config/http';
import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { TemplateRoutineList } from '../../types';

async function getAll() {
  const { data } =
    await http.get<ApiResponse<TemplateRoutineList[]>>('/template-routines');
  return data.data.map(r => ({
    ...r,
    workouts: r.workouts.map(w => ({
      ...w,
      routineId: r.id,
    })),
  }));
}

export const useTemplateRoutinesQuery = (arg?: { enabled?: boolean }) => {
  return useQuery({
    queryFn: getAll,
    queryKey: QueryKey.TemplateRoutines(),
    ...arg,
  });
};

export const invalidateTemplateRoutines = (filters?: InvalidateQueryFilters) =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.TemplateRoutines(),
    ...filters,
  });

export const cancelTemplateRoutinesQuery = () =>
  queryClient.cancelQueries({
    queryKey: QueryKey.TemplateRoutines(),
  });
