import {
  ActionIcon,
  AppShell,
  Center,
  Group,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { HEADER_HEIGHT } from '../../../constants/preferences';
import useBackNavigation from '../../../hooks/useGetBackUrl';
import useIsMobile from '../../../hooks/useIsMobile';
import { vars } from '../../../theme';
import { truncateText } from '../../../utils/string';

interface Props {
  Action?: null | React.FC;
  backUrl?: null | string;
  label?: string;
  title: string;
  withBackButton?: boolean;
}

const HeaderMobile: FC<Props> = ({
  Action = null,
  backUrl = null,
  label,
  title,
  withBackButton = true,
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { goBack } = useBackNavigation();

  if (isMobile === false) {
    return null;
  }

  const handleBackClick = () => {
    if (backUrl) {
      navigate(backUrl, { replace: true });
    } else {
      goBack();
    }
  };

  return (
    <AppShell.Header
      c="dark"
      component="header"
      display="flex"
      h={HEADER_HEIGHT}
      hiddenFrom="sm"
      style={{ boxShadow: vars.shadows.xs, overflow: 'hidden' }}
    >
      <Group
        align="center"
        justify="space-between"
        p="sm"
        pos="relative"
        w="100%"
      >
        {withBackButton === true && (
          <ActionIcon c="dark" onClick={handleBackClick} variant="transparent">
            <IconArrowLeft style={{ height: '90%', width: '90%' }} />
          </ActionIcon>
        )}

        <Stack
          align="center"
          c="dark"
          flex="1"
          gap={0}
          justify="center"
          left={48}
          pos="absolute"
          right={48}
        >
          {label !== undefined && (
            <Text c="dimmed" size="xs">
              {label}
            </Text>
          )}
          {title === undefined ? (
            <Skeleton h={16} mt={9} w={180} />
          ) : (
            <Text c="dark" component="h1">
              {truncateText(30, title)}
            </Text>
          )}
        </Stack>

        <Center>{Action && <Action />}</Center>
      </Group>
    </AppShell.Header>
  );
};

export default HeaderMobile;
