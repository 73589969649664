import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActionIconHeaderActions } from '../../../../../components/common/ActionIcons';
import { iconsMap } from '../../../../../components/common/ActionIcons/config';
import CustomMenu, {
  MenuItem,
} from '../../../../../components/ui/menus/CustomMenu';
import { MutationDelete } from '../../../../../constants/mutation-keys';
import { translate } from '../../../../../i18n';
import {
  useParamRoutineId,
  useParamWorkoutId,
} from '../../../../../router/hooks/path-params';
import { routes } from '../../../../../router/routes';
import { SearchParam } from '../../../../../router/types';
import { ModalId, useModal } from '../../../../../utils/modal';
import { confirmDanger } from '../../../../../utils/modal/confirm';
import toast, { ToastSuccessId } from '../../../../../utils/toast';
import { useConversion } from '../../../@conversion/api/mutations/useConversion';
import { ConversionType } from '../../../types/conversion';
import { useTemplateWorkoutDelete } from '../../api/mutations/workout/useTemplateWorkoutDelete';
import { invalidateTemplateWorkouts } from '../../api/queries/useTemplateWorkoutsQuery';

const TemplateWorkoutHeaderActions: FC = () => {
  const navigate = useNavigate();
  const workoutId = useParamWorkoutId();
  const routineId = useParamRoutineId();
  const { openModal } = useModal();
  const { isPending: isDeleting, mutate } = useTemplateWorkoutDelete();
  const { isPending: isConverting, mutateAsync: convert } = useConversion();

  const { backUrl, conversionType } =
    routineId === null
      ? { backUrl: routes.library.workouts.root(), conversionType: null }
      : {
          backUrl: routes.library.routines.byId(routineId),
          conversionType:
            ConversionType.TemplateRoutineWorkoutToStandaloneTemplateWorkout,
        };

  const requestDelete = () => {
    if (workoutId === null) {
      return;
    }

    confirmDanger({
      key: MutationDelete.ClientWorkout,
      onConfirm() {
        mutate({ routineId, workoutId });
        navigate(backUrl, {
          replace: true,
        });
      },
    });
  };

  const requestEdit = () => {
    openModal(ModalId.TemplateWorkoutDetailsEdit, {
      [SearchParam.EntityId]: workoutId,
    });
  };

  const requestConvert = async () => {
    if (conversionType === null || workoutId === null) {
      return;
    }

    await convert({
      conversionType:
        ConversionType.TemplateRoutineWorkoutToStandaloneTemplateWorkout,
      params: { workoutId },
    });

    invalidateTemplateWorkouts({ refetchType: 'all' });
    toast.success(ToastSuccessId.TemplateWorkoutGenerated);
  };

  const items: MenuItem[] = [
    {
      Icon: iconsMap.edit.Icon,
      label: translate('workout.edit'),
      onClick: requestEdit,
    },
  ];

  if (conversionType !== null) {
    const labelConvert = translate(`common.conversion.${conversionType}.title`);

    items.push({
      Icon: iconsMap.export.Icon,
      isLoading: isConverting,
      label: labelConvert,
      onClick: requestConvert,
    });
  }

  items.push({
    Icon: iconsMap.delete.Icon,
    label: translate('workout.delete'),
    onClick: requestDelete,
  });

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      tooltip={translate('workout.actions')}
      trigger={<ActionIconHeaderActions loading={isDeleting || isConverting} />}
    />
  );
};

export default TemplateWorkoutHeaderActions;
