import {
  Anchor,
  Group,
  LoadingOverlay,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { Link } from 'react-router-dom';

import http from '../../../../../config/http';
import { translate } from '../../../../../i18n';
import { getRoutineDetailsUrl } from '../../../../../router/urls/clients';
import toast, { ToastErrorId } from '../../../../../utils/toast';
import { invalidateClientRoutine } from '../../../@client/api/queries/useClientRoutineFullQuery';
import { invalidateClientRoutines } from '../../../@client/api/queries/useClientRoutinesQuery';
import { ExerciseInUseApiErrorDetails } from '../../types';
import ExercisePickerAutocomplete from '../ExercisePickerAutocomplete';

/** @todo - when templates are added, handle them as well */

export default function ExerciseInUseModalContent({
  clientWorkouts,
  exerciseId,
  onChange,
  templateWorkouts,
}: {
  exerciseId: string;
  onChange: () => void;
} & ExerciseInUseApiErrorDetails) {
  const { isPending, mutate } = useMutation({
    mutationFn: async (newExerciseId: null | string) => {
      if (newExerciseId === null) {
        return;
      }
      const clientExerciseInstructions = clientWorkouts.flatMap(
        workout => workout.exerciseInstructions,
      );

      const routineIds = [
        ...new Set(clientWorkouts.map(workout => workout.routineId)),
      ];

      const clientIds = [
        ...new Set(clientWorkouts.map(workout => workout.clientId)),
      ];

      await Promise.all(
        clientExerciseInstructions.map(({ id }) =>
          http.patch(`/client-exercise-instructions/${id}`, {
            exerciseId: newExerciseId,
          }),
        ),
      );

      await Promise.all([
        ...routineIds.map(invalidateClientRoutine),
        ...clientIds.map(invalidateClientRoutines),
      ]);
      modals.closeAll();
      onChange();
    },
    onError: error => {
      if (isAxiosError(error)) {
        toast.apiError(error);
      } else {
        toast.error(ToastErrorId.General);
      }
    },
  });

  return (
    <Stack h="60vh" mah={800} mih={400}>
      <Text c="red.6">
        {translate('toast.ExerciseDeleteUsedInExerciseInstructions.text')}
      </Text>
      <Text component="label" lh={2} size="sm">
        {translate('exerciseInstruction.selectExercise')}
        <ExercisePickerAutocomplete
          autoFocus={true}
          defaultSelected={exerciseId}
          isLoading={isPending}
          onChange={mutate}
        />
      </Text>
      <LoadingOverlay visible={isPending} />
      <Space h={10} />
      {clientWorkouts.length > 0 && (
        <>
          <Text fw="bold" size="sm">
            {translate('workout.client_other')}
          </Text>
          <Group>
            {clientWorkouts.map(workout => (
              <Anchor
                component={Link}
                key={workout.id}
                to={`${getRoutineDetailsUrl(
                  workout.routineId,
                  workout.clientId,
                )}#workout-${workout.id}`}
              >
                <Text size="sm">{workout.name}</Text>
              </Anchor>
            ))}
          </Group>
        </>
      )}

      {templateWorkouts.length > 0 && (
        <Text fw="bold" size="sm">
          {translate('workout.template_other')}
        </Text>
      )}
    </Stack>
  );
}
