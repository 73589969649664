import { MutationDelete } from '../../../constants/mutation-keys';
import useBackNavigation from '../../../hooks/useGetBackUrl';
import { useParamClientId } from '../../../router/hooks/path-params';
import { SearchParam } from '../../../router/types';
import { UserStatus } from '../../../types';
import { Client } from '../../../types/clients';
import { ModalId, useModal } from '../../../utils/modal';
import { confirmDanger } from '../../../utils/modal/confirm';
import { useClientDeleteMutation } from '../api/mutations/delete';
import {
  useClientActivateMutation,
  useClientDeactivateMutation,
} from '../api/mutations/update';

const useClientActions = (
  client: Pick<Client, 'businessId' | 'status' | 'userId'>,
) => {
  const paramClientId = useParamClientId();
  const { businessId, status, userId } = client;
  const { openModal } = useModal();
  const { goBack } = useBackNavigation();

  const { isPending: isActivating, mutateAsync: activate } =
    useClientActivateMutation();
  const { isPending: isDeactivating, mutateAsync: deactivate } =
    useClientDeactivateMutation();
  const { isPending: isDeleting, mutateAsync: deleteClient } =
    useClientDeleteMutation();

  const isDeleteDisabled = status === UserStatus.Active;

  const requestActivate = () => activate(client.userId);

  const requestDeactivate = () => deactivate(client.userId);

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.Client,
      onConfirm: () =>
        deleteClient(userId).then(() => {
          if (paramClientId) {
            goBack();
          }
        }),
    });
  };

  const requestEdit = () => {
    openModal(businessId ? ModalId.BusinessClientEdit : ModalId.ClientEdit, {
      [SearchParam.ClientId]: userId,
    });
  };

  return {
    isDeleteDisabled,
    isDeleting,
    isUpdating: isDeactivating || isActivating,
    requestActivate,
    requestDeactivate,
    requestDelete,
    requestEdit,
  };
};

export default useClientActions;
