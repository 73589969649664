import { useParamRoutineId } from '../../../../../router/hooks/path-params';
import { getDataById } from '../../../../../utils/array';
import { useClientRoutineFullQuery } from './useClientRoutineFullQuery';

export const useClientWorkoutQuery = (
  workoutId: null | string,
  options?: { enabled?: boolean },
) => {
  const routineId = useParamRoutineId();

  const routineQuery = useClientRoutineFullQuery(routineId, {
    enabled:
      options?.enabled !== false && routineId !== null && workoutId !== null,
    ...options,
  });

  return {
    ...routineQuery,
    data: getDataById(routineQuery.data?.workouts, workoutId),
  };
};
