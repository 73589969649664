import { FC } from 'react';

import HeaderMobile from '../../../../components/layout/HeaderMobile';
import { PageId } from '../../../../constants/page-ids';
import TemplateWorkoutHeaderActions from '../../../../features/routine/@template/components/WorkoutHeaderActions';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getPageLabel } from '../../../../i18n/helpers/page';

const TemplateWorkoutFullLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={TemplateWorkoutHeaderActions}
        title={getPageLabel(PageId.WorkoutBuilder)}
      />
    );
  }

  return null;
};

export default TemplateWorkoutFullLayout;
