/** @file Contains helpers to display toast messages */
import { Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconExclamationCircle } from '@tabler/icons-react';

import { CognitoError } from '../constants/errors/cognito';
import {
  ToastErrorId,
  ToastInfoId,
  ToastSuccessId,
  ToastWarningId,
} from '../constants/toast-ids';
import { translate } from '../i18n';
import { getApiErrorType } from './errors';

type ErrorId = CognitoError | ToastErrorId;

/**
 * Shows a notification of type error
 * @param errorId Id of toast we want to show
 */
const toastError = (errorId: ErrorId) => {
  const text = translate(`toast.${errorId}.text`);
  const title = translate(`toast.${errorId}.title`);

  notifications.show({
    color: 'red',
    icon: <IconExclamationCircle color="red" />,
    id: errorId,
    message: <Text dangerouslySetInnerHTML={{ __html: text }} size="sm" />,
    title: title,
    withBorder: true,
    withCloseButton: true,
  });
};

/**
 * Shows a notification of type error
 * @param error axios error
 */
const toastApiError = (error: never | unknown) => {
  const title = translate(`toast.${getApiErrorType(error)}.title`);
  const message = (error as any).response?.data?.message;

  notifications.show({
    color: 'red',
    icon: <IconExclamationCircle color="red" />,
    id: title,
    message: <Text dangerouslySetInnerHTML={{ __html: message }} size="sm" />,
    title: title,
    withBorder: true,
    withCloseButton: true,
  });
};

/**
 * Shows a notification of type success
 * @param successId Id of toast we want to show
 */
const toastSuccess = (successId: ToastSuccessId) => {
  const text = translate(`toast.${successId}.text`);
  const title = translate(`toast.${successId}.title`);

  notifications.show({
    color: 'green',
    icon: null,
    id: successId,
    message: text,
    title: title,
    withBorder: true,
    withCloseButton: true,
  });
};

/**
 * Shows a notification of type warning
 * @param warningId Id of toast we want to show
 */
const toastWarning = (warningId: ToastWarningId) => {
  const text = translate(`toast.${warningId}.text`);
  const title = translate(`toast.${warningId}.title`);

  notifications.show({
    color: 'yellow',
    icon: null,
    id: warningId,
    message: text,
    title: title,
    withBorder: true,
    withCloseButton: true,
  });
};

/**
 * Shows a notification of type info
 * @param infoId Id of toast we want to show
 */
const toastInfo = (infoId: ToastInfoId) => {
  const text = translate(`toast.${infoId}.text`);
  const title = translate(`toast.${infoId}.title`);

  notifications.show({
    color: 'blue',
    icon: null,
    id: infoId,
    message: text,
    title: title,
    withBorder: true,
    withCloseButton: true,
  });
};

const toast = {
  apiError: toastApiError,
  error: toastError,
  info: toastInfo,
  success: toastSuccess,
  warning: toastWarning,
};

export { ToastErrorId, ToastSuccessId };

// eslint-disable-next-line react-refresh/only-export-components
export default toast;
