import { useLocation } from 'react-router-dom';

export const useHashWorkoutId = () => {
  const { hash } = useLocation();

  if (!hash) {
    return null;
  }

  const [entity, id] = hash.split('-');

  if (entity !== '#workout' || !id) {
    return null;
  }

  return id;
};
