import { Button } from '@mantine/core';
import { SubscriptionStatus } from '@paddle/paddle-node-sdk';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { AlertId } from '../../../constants/alert';
import { getUser } from '../../../features/user/api/cache/getters';
import { User, UserType } from '../../../features/user/types';
import { isSubscriptionUser } from '../../../features/user/utils';
import { translate } from '../../../i18n';
import { PATH__SETTINGS__SUBSCRIPTIONS } from '../../../router/paths/paths';
import CustomAlert from '../CustomAlert';

const alertIdByStatus: Record<SubscriptionStatus, AlertId | null> = {
  active: null,
  canceled: AlertId.SubscriptionCanceled,
  past_due: AlertId.SubscriptionPastDue,
  paused: AlertId.SubscriptionPaused,
  trialing: AlertId.SubscriptionTrialEndingSoon,
};

const getUserAlertId = <
  T extends { subscriptionStatus: User['subscriptionStatus']; type: UserType },
>(
  user: null | T,
) => {
  if (user === null || isSubscriptionUser() === false) {
    return null;
  }

  if (user.subscriptionStatus === null) {
    return AlertId.NotSubscribed;
  }

  return alertIdByStatus[user.subscriptionStatus] ?? null;
};

const AlertSubscriptionStatus: FC = () => {
  const user = getUser();
  const dismissed = useRef(new Set<AlertId>());

  const [alertId, setAlertId] = useState<AlertId | null>(null);
  const shouldShowAlert =
    alertId !== null && dismissed.current.has(alertId) === false;

  const handleClose = (id: AlertId) => {
    return () => {
      dismissed.current.add(id);
      setAlertId(null);
    };
  };

  useEffect(() => {
    if (user === null) {
      return;
    }

    const nextAlertId = getUserAlertId(user);

    // User already dismissed this alert
    if (nextAlertId !== null && dismissed.current.has(nextAlertId)) {
      return;
    }

    if (nextAlertId !== alertId) {
      setAlertId(nextAlertId);
    }
  }, [user, alertId]);

  return shouldShowAlert ? (
    <CustomAlert
      alertId={alertId}
      button={
        <Button
          color="dark"
          component={Link}
          size="xs"
          to={PATH__SETTINGS__SUBSCRIPTIONS}
          variant="outline"
        >
          {translate('product.subscription_settings')}
        </Button>
      }
      onClose={handleClose(alertId)}
    />
  ) : null;
};

export default AlertSubscriptionStatus;
