import { Location } from 'react-router-dom';

import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { PageId } from '../../constants/page-ids';
import { translate } from '../../i18n';
import { getPageLabel } from '../../i18n/helpers/page';
import { Client } from '../../types/clients';
import { getBackUrl } from '../paths/back-paths';
import {
  PATH_FRAGMENT__CLIENT_ID,
  PATH_FRAGMENT__CLIENTS,
} from '../paths/path-fragments';

export const getBreadcrumbClients = (
  currentPath: null | string,
  location: Location | null = null,
): BreadcrumbItem => {
  return {
    content: getPageLabel(PageId.Clients),
    href: getBackUrl(currentPath, location, {
      toPathFragment: PATH_FRAGMENT__CLIENTS,
    }),
  };
};

export const getBreadCrumbClientFull = (
  client: Client | null | undefined = null,
  currentPath: null | string,
  location: Location | null = null,
): BreadcrumbItem => {
  return {
    content: `${client?.identity.firstName} ${client?.identity.lastName}`,
    href: getBackUrl(currentPath, location, {
      toPathFragment: PATH_FRAGMENT__CLIENT_ID,
    }),
    isLoading: client === null,
    label: translate('client.label_one'),
  };
};
