import { FC, lazy, Suspense } from 'react';

import PageLayout from '../../components/layout/PageLayout';
import Layout from './layout';

const Content = lazy(() => import('./content'));

const MorePage: FC = () => {
  return (
    <PageLayout>
      <Layout />
      <Suspense fallback={null}>
        <Content />
      </Suspense>
    </PageLayout>
  );
};

export default MorePage;
