import {
  IconArrowBackUp,
  IconChevronsRight,
  IconRotateClockwise,
} from '@tabler/icons-react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActionIconHeaderActions } from '../../../../../components/common/ActionIcons';
import { iconsMap } from '../../../../../components/common/ActionIcons/config';
import CustomMenu, {
  MenuItem,
} from '../../../../../components/ui/menus/CustomMenu';
import {
  MutationDelete,
  MutationUpdate,
} from '../../../../../constants/mutation-keys';
import { translate } from '../../../../../i18n';
import { useParamWorkoutId } from '../../../../../router/hooks/path-params';
import { SearchParam } from '../../../../../router/types';
import { getRoutineDetailsUrl } from '../../../../../router/urls/clients';
import { ModalId, useModal } from '../../../../../utils/modal';
import { confirmDanger } from '../../../../../utils/modal/confirm';
import toast, { ToastSuccessId } from '../../../../../utils/toast';
import { useConversion } from '../../../@conversion/api/mutations/useConversion';
import { invalidateTemplateWorkouts } from '../../../@template/api/queries/useTemplateWorkoutsQuery';
import { ConversionType } from '../../../types/conversion';
import {
  useClientWorkoutReset,
  useClientWorkoutSkip,
  useClientWorkoutUnSkip,
} from '../../api/mutations/workout/actions';
import { useClientWorkoutDelete } from '../../api/mutations/workout/useClientWorkoutDelete';
import { useClientWorkoutQuery } from '../../api/queries/useClientWorkoutQuery';
import { isWorkoutCompleted } from '../../helpers/workout';
import { WorkoutStatus } from '../../types';

const conversionType = ConversionType.ClientWorkoutToStandaloneTemplateWorkout;

const ClientWorkoutBuilderHeaderActions: FC = () => {
  const navigate = useNavigate();
  const workoutId = useParamWorkoutId();
  const { openModal } = useModal();
  const { data: workout } = useClientWorkoutQuery(workoutId);
  const { isPending: isDeleting, mutate } = useClientWorkoutDelete();
  const { isPending: isConverting, mutateAsync: convert } = useConversion();
  const { clientId = '', status } = workout ?? {};

  const { isPending: isResetting, mutate: resetWorkout } =
    useClientWorkoutReset(clientId);
  const { isPending: isSkipping, mutate: skipWorkout } =
    useClientWorkoutSkip(clientId);
  const { isPending: isUnSkipping, mutate: unSkipWorkout } =
    useClientWorkoutUnSkip(clientId);

  const labelConvert = translate(`common.conversion.${conversionType}.title`);

  const requestDelete = () => {
    if (workout?.id === undefined) {
      return;
    }

    confirmDanger({
      key: MutationDelete.ClientWorkout,
      onConfirm() {
        mutate({ routineId: workout.routineId, workoutId: workout.id });
        navigate(getRoutineDetailsUrl(workout.routineId, workout.clientId), {
          replace: true,
        });
      },
    });
  };

  const requestEdit = () => {
    openModal(ModalId.ClientWorkoutDetailsEdit, {
      [SearchParam.EntityId]: workoutId,
    });
  };

  const requestConvert = async () => {
    if (workoutId === null) {
      return;
    }

    await convert({ conversionType, params: { workoutId } });
    invalidateTemplateWorkouts({ refetchType: 'all' });
    toast.success(ToastSuccessId.TemplateRoutineGenerated);
  };

  const requestSkipWorkout = () => {
    if (workoutId === null) {
      return;
    }

    confirmDanger({
      key: MutationUpdate.ClientWorkoutSkip,
      onConfirm: () => skipWorkout(workoutId),
    });
  };

  const requestUnSkipWorkout = () => {
    if (workoutId === null) {
      return;
    }
    unSkipWorkout(workoutId);
  };

  const requestResetWorkout = () => {
    if (workoutId === null) {
      return;
    }

    confirmDanger({
      key: MutationUpdate.ClientWorkoutReset,
      onConfirm: () => resetWorkout(workoutId),
    });
  };

  const items: MenuItem[] = [
    {
      Icon: iconsMap.edit.Icon,
      label: translate('workout.edit'),
      onClick: requestEdit,
    },
    {
      Icon: iconsMap.export.Icon,
      label: labelConvert,
      onClick: requestConvert,
    },
    { isSectionLabel: true, label: translate('common.quick_actions') },
    {
      Icon: iconsMap.delete.Icon,
      label: translate('workout.delete'),
      onClick: requestDelete,
    },
  ];

  if (status === WorkoutStatus.Skipped) {
    items.push({
      Icon: IconArrowBackUp,
      label: translate('workout.unSkip'),
      onClick: requestUnSkipWorkout,
    });
  } else if (isWorkoutCompleted(status) === false) {
    items.push({
      Icon: IconChevronsRight,
      label: translate('workout.skip'),
      onClick: requestSkipWorkout,
    });
  }

  if (status !== WorkoutStatus.None) {
    items.push({
      Icon: IconRotateClockwise,
      label: translate('workout.reset'),
      onClick: requestResetWorkout,
    });
  }

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      trigger={
        <ActionIconHeaderActions
          label={translate('workout.actions')}
          loading={[
            isDeleting,
            isConverting,
            isResetting,
            isSkipping,
            isUnSkipping,
          ].some(Boolean)}
        />
      }
    />
  );
};

export default ClientWorkoutBuilderHeaderActions;
