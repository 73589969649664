import { Portal } from '@mantine/core';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import CustomBreadcrumbs from '../../../components/common/Breadcrumbs';
import { PORTAL__HEADER_CONTENT } from '../../../constants/portals';
import useIsMobile from '../../../hooks/useIsMobile';
import useBreadcrumbs from './useBreadcrumbs';

const Breadcrumbs: FC = () => {
  const breadcrumbs = useBreadcrumbs();
  return (
    <Portal target={PORTAL__HEADER_CONTENT}>
      <CustomBreadcrumbs items={breadcrumbs} />
    </Portal>
  );
};

const ExercisesLayout: FC = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile === false && <Breadcrumbs />}
      <Outlet />
    </>
  );
};

export default ExercisesLayout;
