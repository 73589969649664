import { Button, Group, Stack } from '@mantine/core';
import { FC, useState } from 'react';

import {
  DrawerSidebarHeader,
  DrawerSidebarScrollArea,
} from '../../../../components/common/DrawerComponents';
import DrawerNavGroup from '../../../../components/common/DrawerNavGroup';
import DrawerSidebar from '../../../../components/common/DrawerSidebar';
import SearchInputUrl from '../../../../components/ui/forms/SearchInputUrl';
import { FieldId } from '../../../../constants/forms/form-fields';
import { PageId } from '../../../../constants/page-ids';
import { USER_STATUS_ORDER } from '../../../../constants/preferences';
import { CLASS_NAME__EMPTY_HIDDEN } from '../../../../constants/selectors';
import useScrollActiveLinkIntoView from '../../../../hooks/useScrollCurrentLinkIntoView';
import { translate } from '../../../../i18n';
import { getFieldPlaceholder } from '../../../../i18n/helpers/forms';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { ModalId, useModal } from '../../../../utils/modal';
import { getUser } from '../../../user/api/cache/getters';
import { UserType } from '../../../user/types';
import BackButton from './BackButton';
import useNavigationClients from './useNavigationClients';

const ClientsSecondaryNavigation: FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const { openModal } = useModal();
  const { clients, isLoading } = useNavigationClients();

  useScrollActiveLinkIntoView(isLoading === false);

  const userType = getUser()?.type;

  const modalId =
    userType === UserType.Coach || userType === UserType.CompanyCoach
      ? ModalId.ClientCreate
      : ModalId.BusinessClientCreate;

  return (
    <DrawerSidebar title={getPageLabel(PageId.Clients)}>
      <DrawerSidebarHeader px="sm" scrolled={scrolled}>
        <Group className={CLASS_NAME__EMPTY_HIDDEN}>
          <BackButton />
        </Group>
        <Stack w="100%">
          <SearchInputUrl
            placeholder={getFieldPlaceholder(FieldId.ClientsSearch)}
            radius="md"
            size="xs"
          />
          <Button
            onClick={() => openModal(modalId)}
            size="compact-sm"
            variant="filled"
          >
            {translate('client.add')}
          </Button>
        </Stack>
      </DrawerSidebarHeader>
      <DrawerSidebarScrollArea onScrolled={setScrolled}>
        <Stack pt="sm">
          {USER_STATUS_ORDER.map(status => {
            const users = clients[status];
            if (isLoading === false && users.length === 0) {
              return null;
            }

            return (
              <DrawerNavGroup
                isLoading={isLoading}
                key={status}
                label={translate(`client.status.${status}.label`)}
                users={clients[status]}
              />
            );
          })}
        </Stack>
      </DrawerSidebarScrollArea>
    </DrawerSidebar>
  );
};

export default ClientsSecondaryNavigation;
