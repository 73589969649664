import { Portal } from '@mantine/core';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import CustomBreadcrumbs from '../../components/common/Breadcrumbs';
import PageLayout from '../../components/layout/PageLayout';
import { PageId } from '../../constants/page-ids';
import { PORTAL__HEADER_CONTENT } from '../../constants/portals';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useIsMobile from '../../hooks/useIsMobile';
import { getPageLabel } from '../../i18n/helpers/page';
import useBreadcrumbs from './useBreadcrumbs';

const Breadcrumbs: FC = () => {
  const breadcrumbs = useBreadcrumbs();
  return (
    <Portal target={PORTAL__HEADER_CONTENT}>
      <CustomBreadcrumbs items={breadcrumbs} />
    </Portal>
  );
};

const MeLayout: FC = () => {
  useDocumentTitle(getPageLabel(PageId.MyStuff));
  const isMobile = useIsMobile();
  return (
    <PageLayout>
      {isMobile === false && <Breadcrumbs />}
      <Outlet />
    </PageLayout>
  );
};

export default MeLayout;
