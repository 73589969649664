import { Center, Grid, Group, rem, Skeleton } from '@mantine/core';
import { IconPoint } from '@tabler/icons-react';
import { FC, Fragment } from 'react';

interface Props {
  isLoading: boolean;
  placeholderCount?: number;
}

const DrawerNavGroupLinkSkeleton: FC<Props> = ({
  isLoading,
  placeholderCount = 2,
}) => {
  if (isLoading === false) {
    return null;
  }

  return Array.from({ length: placeholderCount }, (_, index) => (
    <Fragment key={index}>
      <Grid.Col span={1}>
        <Center h="100%">
          <IconPoint opacity={0.4} size={12} />
        </Center>
      </Grid.Col>
      <Grid.Col span={11}>
        <Group align="center" h={rem(40)} pl="sm" w="85%">
          <Skeleton mih={rem(14)} opacity={0.1} />
        </Group>
      </Grid.Col>
    </Fragment>
  ));
};

export default DrawerNavGroupLinkSkeleton;
