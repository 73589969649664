import { TemplateRoutineFull, TemplateRoutineList } from '../../types';

/**
 * Transforms the full routine to match the structure of an routine list item
 * @param routine The full routine object
 * @returns       Transformed routine object
 */
export const transformTemplateRoutineFullToList = (
  routine: TemplateRoutineFull,
): TemplateRoutineList => {
  const { isPublic = false, workouts, ...routineDetails } = routine;

  return {
    ...routineDetails,
    isPublic,
    workouts: workouts.map(wo => ({
      cycleId: wo.cycleId,
      id: wo.id,
      name: wo.name,
      position: wo.position,
      restHours: wo.restHours,
      routineId: routine.id,
    })),
  };
};
