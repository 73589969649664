import { FC } from 'react';

import ErrorContent from '../../components/common/ErrorContent';
import HeaderMobile from '../../components/layout/HeaderMobile';
import PageLayout from '../../components/layout/PageLayout';
import { ButtonId } from '../../constants/button-ids';
import { PageId } from '../../constants/page-ids';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useIsMobile from '../../hooks/useIsMobile';
import { translate } from '../../i18n';
import { getButtonLabel } from '../../i18n/helpers/buttons';
import { getPageLabel } from '../../i18n/helpers/page';
import { useParamError } from '../../router/hooks/path-params';
import { PATH__HOME } from '../../router/paths/paths';
import { ErrorParam } from '../../types';

const ErrorPage: FC = () => {
  const isMobile = useIsMobile();
  useDocumentTitle(getPageLabel(PageId.Error));
  const paramError = useParamError() ?? ErrorParam.General;

  const title = translate(`errorPages.${paramError}.title`);
  const description = translate(`errorPages.${paramError}.description`);

  return (
    <>
      {isMobile && (
        <HeaderMobile
          Action={null}
          backUrl={PATH__HOME}
          title={getPageLabel(PageId.Error)}
        />
      )}
      <PageLayout withHeader={false}>
        <ErrorContent
          buttonProps={{
            children: getButtonLabel(ButtonId.GoToHome),
            fullWidth: false,
            onClick: () => (window.location.href = PATH__HOME),
            size: 'md',
            w: 'max-content',
          }}
          description={description}
          title={title}
        />
      </PageLayout>
    </>
  );
};

export default ErrorPage;
