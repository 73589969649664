import { useDisclosure } from '@mantine/hooks';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import useCurrentPath from '../../router/hooks/useCurrentPath';
import { StorageKeys, StorageService } from '../../utils/storage';
import SidebarContext from './context';
import { getHasSidebar } from './helpers';

const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const initialIsOpen =
    StorageService.read<boolean>(StorageKeys.SidebarOpen) ?? false;

  const currentPath = useCurrentPath();
  const hasSidebarContent = getHasSidebar(currentPath);
  const lastPathHasSidebar = useRef(hasSidebarContent);

  const [isOpen, { close, open: _open }] = useDisclosure(
    hasSidebarContent && initialIsOpen,
  );

  useLayoutEffect(() => {
    if (isOpen && hasSidebarContent === false) {
      close();
    }
  }, [isOpen, hasSidebarContent, close]);

  /**
   * When we navigate from a screen that has no sidebar, we want to open it
   * for the next screen that has it
   */
  useEffect(() => {
    if (lastPathHasSidebar.current === false && hasSidebarContent) {
      _open();
    }
    lastPathHasSidebar.current = hasSidebarContent;
  }, [hasSidebarContent, _open]);

  useEffect(() => {
    StorageService.save(StorageKeys.SidebarOpen, isOpen);
  }, [isOpen]);

  const open = useCallback(() => {
    if (isOpen === false) {
      _open();
    }
  }, [isOpen, _open]);

  const toggle = useCallback(() => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  }, [isOpen, open, close]);

  const value = useMemo(
    () => ({ close, isOpen, open, toggle }),
    [close, isOpen, open, toggle],
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

export default SidebarProvider;
