import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { PageId } from '../../constants/page-ids';
import { getPageLabel } from '../../i18n/helpers/page';
import { PATH__HOME } from '../../router/paths/paths';

const useBreadcrumbs = (): BreadcrumbItem[] => {
  return [{ content: getPageLabel(PageId.Home), href: PATH__HOME }];
};

export default useBreadcrumbs;
