import { ActionIcon, Group, Tooltip } from '@mantine/core';
import { IconPlus, TablerIcon } from '@tabler/icons-react';
import { FC } from 'react';

import CustomMenu, { MenuItem } from '../../ui/menus/CustomMenu';

interface Props {
  Icon?: TablerIcon;
  items?: MenuItem[];
  onClick?: () => void;
  tooltip: string;
}

const NavGroupActionIcon: FC<Props> = ({
  Icon = IconPlus,
  items,
  onClick,
  tooltip,
}) => {
  const targetElement = (
    <ActionIcon ml="auto" onClick={onClick} radius="xl" size="sm">
      <Icon style={{ height: '75%', width: '75%' }} />
    </ActionIcon>
  );

  return (
    <Group align="center" justify="flex-end">
      {items === undefined ? (
        <Tooltip label={tooltip}>{targetElement}</Tooltip>
      ) : (
        <CustomMenu
          menuItems={items}
          position="right-start"
          tooltip={tooltip}
          trigger={targetElement}
        />
      )}
    </Group>
  );
};

export default NavGroupActionIcon;
