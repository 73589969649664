import { IconBarbell, IconTemplate } from '@tabler/icons-react';
import { FC, Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import DrawerNavGroup from '../../components/common/DrawerNavGroup';
import DrawerSidebar from '../../components/common/DrawerSidebar';
import PageLayout from '../../components/layout/PageLayout';
import { PageId } from '../../constants/page-ids';
import useIsMobile from '../../hooks/useIsMobile';
import { translate } from '../../i18n';
import { getPageLabel } from '../../i18n/helpers/page';
import { ModalId, useModal } from '../../utils/modal';
import { getLinksExercises, getTemplateItems, linksTemplates } from './helpers';

const LibraryLayout: FC = () => {
  const isMobile = useIsMobile();
  const { openModal } = useModal();
  const location = useLocation();

  const labelExercises = getPageLabel(PageId.Exercises);
  const labelTemplates = translate('common.templates');
  const linksExercises = getLinksExercises(location);

  return (
    <PageLayout>
      {isMobile === false && (
        <DrawerSidebar title={getPageLabel(PageId.Library)}>
          <DrawerNavGroup
            actionIcon={{
              onClick: () => openModal(ModalId.Exercise),
              tooltip: translate('exercise.create'),
            }}
            Icon={IconBarbell}
            items={linksExercises}
            label={labelExercises}
          />
          <DrawerNavGroup
            actionIcon={{
              items: getTemplateItems(openModal),
              tooltip: translate('common.template_create'),
            }}
            Icon={IconTemplate}
            items={linksTemplates}
            label={labelTemplates}
          />
        </DrawerSidebar>
      )}
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </PageLayout>
  );
};

export default LibraryLayout;
