import { useMutation } from '@tanstack/react-query';

import http from '../../../../../config/http';
import { useParamClientId } from '../../../../../router/hooks/path-params';
import { getClientRoutineFull, getClientRoutines } from '../cache/getters';
import { setClientRoutines } from '../cache/setters';
import { cancelClientRoutineQuery } from '../queries/useClientRoutineFullQuery';
import {
  cancelClientRoutinesQuery,
  invalidateClientRoutines,
} from '../queries/useClientRoutinesQuery';
import { clientRoutineMutationErrorHandler } from './handlers';
import { ClientRoutineMutationContext } from './types';

async function deleteRoutine(routineId: string) {
  await http.delete(`/client-routines/${routineId}`);
}

export const useClientRoutineDelete = () => {
  const clientId = useParamClientId();

  return useMutation({
    mutationFn: deleteRoutine,
    onError(_, routineId, context) {
      clientRoutineMutationErrorHandler(
        routineId,
        context as ClientRoutineMutationContext,
      );
    },
    onMutate: async routineId => {
      const routine = getClientRoutineFull(routineId);
      const _clientId = routine?.clientId ?? clientId ?? null;

      if (_clientId === null) {
        return;
      }

      await Promise.all([
        cancelClientRoutineQuery(routineId),
        cancelClientRoutinesQuery(_clientId),
      ]);

      const routinesAll = getClientRoutines(_clientId);

      if (routinesAll !== null) {
        setClientRoutines(
          _clientId,
          routinesAll.filter(r => r.id !== routineId),
        );
      }

      return { routine, routinesAll };
    },
    onSettled(_, __, ___, context) {
      const { routine } = context as ClientRoutineMutationContext;
      const _clientId = clientId ?? routine.clientId;
      if (_clientId) {
        invalidateClientRoutines(_clientId);
      }
    },
  });
};
