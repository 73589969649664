import { FC } from 'react';

import { ActionIconButton } from '../../../../components/common/ActionIcons';
import { translate } from '../../../../i18n';
import { ModalId, useModal } from '../../../../utils/modal';
import { useUserData } from '../../api/queries/useUser';

const SettingsUserProfileHeaderActions: FC = () => {
  const { isRefetching } = useUserData();
  const label = translate('common.profile_edit');

  const { openModal } = useModal();

  const requestEditProfile = () => {
    openModal(ModalId.UserProfileEdit);
  };

  return (
    <ActionIconButton
      colorVariant="dark"
      icon="edit"
      loading={isRefetching}
      onClick={requestEditProfile}
      tooltip={label}
      variant="default"
    />
  );
};

export default SettingsUserProfileHeaderActions;
