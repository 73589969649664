/**
 * @file Search Params modal configuration
 */
import { SearchParam } from '../../router/types';
import { ModalId, ParamConfig } from './types';

export const modalParamsConfig: Record<ModalId, null | ParamConfig> = {
  [ModalId.BusinessAdminCreate]: {
    optional: [SearchParam.Facilities],
  },
  [ModalId.BusinessAdminEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.BusinessClientCreate]: {
    optional: [SearchParam.Facilities, SearchParam.CoachId],
  },
  [ModalId.BusinessClientEdit]: {
    required: [SearchParam.ClientId],
  },
  [ModalId.BusinessCoachCreate]: {
    optional: [SearchParam.Facilities],
  },
  [ModalId.BusinessCoachEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.BusinessCoachRankCreate]: null,
  [ModalId.BusinessCoachRankEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.BusinessFacilityCreate]: null,
  [ModalId.BusinessFacilityEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.ChangePassword]: null,
  [ModalId.ClientCreate]: null,
  [ModalId.ClientEdit]: {
    required: [SearchParam.ClientId],
  },
  [ModalId.ClientRoutineCreate]: {
    required: [SearchParam.ClientId],
  },
  [ModalId.ClientRoutineCycleCreate]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.ClientRoutineCycleEdit]: {
    required: [SearchParam.EntityId, SearchParam.CycleId],
  },
  [ModalId.ClientRoutineDetailsEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.ClientWorkoutCreate]: {
    required: [SearchParam.CycleId, SearchParam.ClientId],
  },
  [ModalId.ClientWorkoutDetailsEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.Exercise]: {
    optional: [SearchParam.ExerciseId],
  },
  [ModalId.GenerateClientRoutines]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.SubscriptionChangePlan]: null,
  [ModalId.TemplateRoutineCreate]: null,
  [ModalId.TemplateRoutineCycleCreate]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.TemplateRoutineCycleEdit]: {
    required: [SearchParam.EntityId, SearchParam.CycleId],
  },
  [ModalId.TemplateRoutineDetailsEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.TemplateWorkoutCreate]: {
    optional: [SearchParam.CycleId],
  },
  [ModalId.TemplateWorkoutDetailsEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.UserProfileEdit]: null,
};
