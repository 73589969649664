import { Location } from 'react-router-dom';

export const addHashToUrl = (hash: string, location?: Location) => {
  const { pathname, search } = location ?? window.location;
  const searchValue = search ? '?' + search : '';
  return `${pathname}${searchValue}#${hash.replace('#', '')}`;
};

export const removeHashFromUrl = (location?: Location) => {
  const { pathname, search } = location ?? window.location;
  const searchValue = search ? '?' + search : '';
  return pathname + searchValue;
};

export const getHashWorkoutId = (workoutId: string) => `#workout-${workoutId}`;
