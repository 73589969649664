/** @file Contains getters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { getDataById, updateDataById } from '../../../../../utils/array';
import {
  ClientRoutineFull,
  ClientRoutineList,
  ClientWorkout,
} from '../../types';
import { getClientRoutineFull } from './getters';

/**
 * Updates the client routine in the cache
 * @param routineId The id of the routine
 * @param data      The data we want to set
 * @param qc        QueryClient
 */
export const setClientRoutineFull = (
  routineId: string,
  data: ClientRoutineFull | null | undefined,
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData(QueryKey.ClientRoutine(routineId), data);
};

/**
 * Updates the client routines cache
 * @param clientId The client ID to whom the routines belong
 * @param routines Client routines we want to set
 * @param qc       QueryClient
 */
export const setClientRoutines = (
  clientId: string,
  routines: ClientRoutineList[] | null | undefined,
  qc: QueryClient = queryClient,
) => {
  qc.setQueryData(QueryKey.ClientRoutines(clientId), routines);
};

/**
 * Updates the client workout in the cache
 * @param routineId The id of the routine
 * @param workoutId the id of the workout we want to update
 * @param data      The data we want to set
 * @param qc        QueryClient
 */
export const setClientWorkout = (
  routineId: string,
  workoutId: string,
  data: Partial<ClientWorkout>,
  qc: QueryClient = queryClient,
) => {
  const routine = getClientRoutineFull(routineId);

  if (routine === null) {
    return;
  }

  const workout = getDataById(routine?.workouts, workoutId);

  if (workout === null) {
    return;
  }

  setClientRoutineFull(
    routineId,
    { ...routine, workouts: updateDataById(routine.workouts, workoutId, data) },
    qc,
  );
};
