/** @file Contains getters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { getDataById } from '../../../../../utils/array';
import {
  StandaloneTemplateWorkout,
  TemplateRoutineFull,
  TemplateRoutineList,
  TemplateRoutineWorkout,
} from '../../types';

/**
 * Retrieves full template routine from the cache
 * @param routineId The ID of the routine
 * @param qc        QueryClient
 * @returns         Template Routine
 */
export const getTemplateRoutineFull = (
  routineId: string,
  qc: QueryClient = queryClient,
): null | TemplateRoutineFull => {
  const data = qc.getQueryData<TemplateRoutineFull>(
    QueryKey.TemplateRoutine(routineId),
  );
  return data ?? null;
};

/**
 * Retrieves template routines from the cache
 * @param qc QueryClient
 * @returns  Client Routine
 */
export const getTemplateRoutines = (
  qc: QueryClient = queryClient,
): null | TemplateRoutineList[] => {
  const data = qc.getQueryData<TemplateRoutineList[]>(
    QueryKey.TemplateRoutines(),
  );

  return data ?? null;
};

/**
 * Retrieves template routine list item from the cache
 * @param routineId The ID of the routine
 * @returns         Template Routine
 */
export const getTemplateRoutineListItem = (
  routineId: string,
): null | TemplateRoutineList => {
  const TemplateRoutines = getTemplateRoutines();

  return getDataById(TemplateRoutines, routineId);
};

/**
 * Get routine workout
 * @param routineId The ID of the routine
 * @param workoutId The ID of the workout we want
 * @param qc        QueryClient
 * @returns         Template Workout
 */
export const getTemplateRoutineWorkout = (
  routineId: null | string,
  workoutId: null | string,
  qc: QueryClient = queryClient,
): null | TemplateRoutineWorkout => {
  if (routineId === null || workoutId === null) {
    return null;
  }

  const data = getTemplateRoutineFull(routineId, qc);

  return getDataById(data?.workouts, workoutId);
};

/**
 * Get standalone template workouts
 * @param qc QueryClient
 * @returns  Standalone template workouts
 */
export const getStandaloneTemplateWorkouts = (
  qc: QueryClient = queryClient,
): null | StandaloneTemplateWorkout[] => {
  const data = qc.getQueryData<StandaloneTemplateWorkout[]>(
    QueryKey.TemplateWorkouts(),
  );

  return data ?? null;
};

/**
 * Get standalone template workout
 * @param workoutId The ID of the workout we want
 * @param qc        QueryClient
 * @returns         Template Workout
 */
export const getStandaloneTemplateWorkout = (
  workoutId: null | string,
  qc: QueryClient = queryClient,
): null | StandaloneTemplateWorkout => {
  if (workoutId === null) {
    return null;
  }

  const data = getStandaloneTemplateWorkouts(qc);

  return getDataById(data, workoutId);
};
