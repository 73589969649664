import { QueryCache, QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

export const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
    },
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retry: false,
      throwOnError(error) {
        if (isAxiosError(error)) {
          const method = error.config?.method;
          const status = error.status;

          return method === 'get' && !!status && [403, 404].includes(status);
        }

        return false;
      },
    },
  },
  queryCache,
});
