import {
  getChangedCyclePositions,
  isRoutineCycleOrderChanged,
} from '../../../helpers/routine';
import { ClientRoutineFull, ClientRoutineList } from '../../types';

export const getUpdatedRoutineFull = (
  routineFull: ClientRoutineFull,
  data: Partial<ClientRoutineFull>,
) => {
  const updated = { ...routineFull, ...data };
  if (isRoutineCycleOrderChanged(routineFull, data.cycles)) {
    const changed = getChangedCyclePositions(routineFull, data.cycles);
    updated.workouts.forEach(workout => {
      workout.cycleId = changed[workout.cycleId] ?? workout.cycleId;
    });
  }

  return updated;
};

export const getUpdatedRoutineList = (
  routineFull: ClientRoutineList,
  data: Partial<ClientRoutineList>,
) => {
  const updated = { ...routineFull, ...data };
  if (isRoutineCycleOrderChanged(routineFull, data.cycles)) {
    const changed = getChangedCyclePositions(routineFull, data.cycles);
    updated.workouts.forEach(workout => {
      workout.cycleId = changed[workout.cycleId] ?? workout.cycleId;
    });
  }

  return updated;
};
