import { Center, Divider, Grid, rem, Skeleton, Tooltip } from '@mantine/core';
import { TablerIcon } from '@tabler/icons-react';
import { ComponentPropsWithoutRef, FC, Fragment, ReactNode } from 'react';

import {
  DRAWER_NAV_ITEM_ICON__ACTIVE_SIZE_FACTOR,
  DRAWER_NAV_ITEM_ICON__COLOR_DEFAULT,
  DRAWER_NAV_ITEM_ICON__SIZE_DEFAULT,
} from '../../../constants/preferences';
import { vars } from '../../../theme';
import { DrawerGroupIcon, DrawerGroupTitle } from '../DrawerComponents';
import DrawerNavItem from '../DrawerNavItem';
import DrawerNavItemsList from '../DrawerNavItemsList';
import DrawerNavItemUser from '../DrawerNavItemUser';
import NavGroupActionIcon from './NavGroupActionIcon';
import DrawerNavGroupLinkSkeleton from './skeleton';

const columns = 12;
const actionIconSpan = 2;
const iconSpan = 1;

interface Props {
  actionIcon?: ComponentPropsWithoutRef<typeof NavGroupActionIcon>;
  Icon?: TablerIcon;
  isLoading?: boolean;
  items?: ComponentPropsWithoutRef<typeof DrawerNavItem>[];
  label: string;
  users?: ComponentPropsWithoutRef<typeof DrawerNavItemUser>[];
  withDivider?: boolean;
}

const withTooltip = (content: ReactNode, tooltip?: string) => {
  return tooltip ? <Tooltip label={tooltip}>{content}</Tooltip> : content;
};

const renderItems = (items: Props['items']) => {
  return items?.map(
    (
      {
        Icon: ItemIcon,
        iconColor = DRAWER_NAV_ITEM_ICON__COLOR_DEFAULT,
        iconSize = DRAWER_NAV_ITEM_ICON__SIZE_DEFAULT,
        iconTooltip,
        ...rest
      },
      i,
    ) => {
      return (
        <Fragment key={i}>
          {ItemIcon && (
            <Grid.Col mb={4} span={1}>
              {withTooltip(
                <Center h="100%" w="100%">
                  <ItemIcon
                    color={rest.isActive ? vars.colors.branding[6] : iconColor}
                    size={
                      rest.isActive
                        ? iconSize * DRAWER_NAV_ITEM_ICON__ACTIVE_SIZE_FACTOR
                        : iconSize
                    }
                  />
                </Center>,
                iconTooltip,
              )}
            </Grid.Col>
          )}
          <Grid.Col mb={4} span={ItemIcon ? columns - iconSpan : columns}>
            <DrawerNavItem {...rest} />
          </Grid.Col>
        </Fragment>
      );
    },
  );
};

// eslint-disable-next-line complexity
const DrawerNavGroup: FC<Props> = ({
  actionIcon,
  Icon,
  isLoading = false,
  items = [],
  label,
  users = [],
  withDivider = true,
}) => {
  const spanBase = Icon === undefined ? columns : columns - iconSpan;
  const hasActionIcon = actionIcon !== undefined;
  const span = hasActionIcon ? spanBase - actionIconSpan : spanBase;

  return (
    <Grid c="white" component="section" gutter={0} maw="100%" mb="md" px="sm">
      {Icon !== undefined && (
        <Grid.Col opacity={0.8} span={iconSpan}>
          <Center>
            <DrawerGroupIcon Icon={Icon} />
          </Center>
        </Grid.Col>
      )}
      <Grid.Col opacity={0.8} pl={Icon === undefined ? 0 : 'sm'} span={span}>
        <DrawerGroupTitle>
          {!label && isLoading ? (
            <Skeleton h={vars.fontSizes.sm} opacity={0.1} w={rem(100)} />
          ) : (
            label
          )}
        </DrawerGroupTitle>
      </Grid.Col>
      {hasActionIcon && (
        <Grid.Col span={actionIconSpan}>
          <NavGroupActionIcon {...actionIcon} />
        </Grid.Col>
      )}
      {withDivider && (
        <Grid.Col span={12}>
          <Divider my="xs" opacity={0.05} />
        </Grid.Col>
      )}
      <DrawerNavGroupLinkSkeleton isLoading={isLoading} placeholderCount={3} />
      {renderItems(items)}
      {users.length > 0 && (
        <Grid.Col>
          <DrawerNavItemsList users={users} />
        </Grid.Col>
      )}
    </Grid>
  );
};

export default DrawerNavGroup;
