import { FC } from 'react';

import HeaderMobile from '../../../components/layout/HeaderMobile';
import { PageId } from '../../../constants/page-ids';
import { useClientRoutineFullQuery } from '../../../features/routine/@client/api/queries/useClientRoutineFullQuery';
import ClientWorkoutBuilderHeaderActions from '../../../features/routine/@client/components/WorkoutBuilderHeaderActions';
import RoutineFullSecondaryNavigation from '../../../features/routine/components/routine/RoutineFullSecondaryNavigation';
import useIsMobile from '../../../hooks/useIsMobile';
import { getPageLabel } from '../../../i18n/helpers/page';
import { useParamRoutineId } from '../../../router/hooks/path-params';

const WorkoutBuilderLayout: FC = () => {
  const isMobile = useIsMobile();
  const routineId = useParamRoutineId();

  const { data: routine } = useClientRoutineFullQuery(routineId);

  if (isMobile) {
    return (
      <HeaderMobile
        Action={ClientWorkoutBuilderHeaderActions}
        // backUrl={getBackUrl({
        //   toPathFragment: PATH_FRAGMENT__ROUTINE_ID,
        // })}
        title={getPageLabel(PageId.WorkoutBuilder)}
      />
    );
  }

  return (
    <RoutineFullSecondaryNavigation
      pageId={PageId.WorkoutBuilder}
      routine={routine}
    />
  );
};

export default WorkoutBuilderLayout;
