/** @file Contains getters for exercises */
import { QueryClient } from '@tanstack/react-query';

import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { getDataById } from '../../../../../utils/array';
import {
  ClientRoutineFull,
  ClientRoutineList,
  ClientWorkout,
} from '../../types';

/**
 * Retrieves full client routine from the cache
 * @param routineId The ID of the routine
 * @param qc        QueryClient
 * @returns         Client Routine
 */
export const getClientRoutineFull = (
  routineId: string,
  qc: QueryClient = queryClient,
): ClientRoutineFull | null => {
  const data = qc.getQueryData<ClientRoutineFull>(
    QueryKey.ClientRoutine(routineId),
  );
  return data ?? null;
};

/**
 * Retrieves client routines from the cache
 * @param clientId The client ID to whom the routines belong
 * @param qc       QueryClient
 * @returns        Client Routine
 */
export const getClientRoutines = (
  clientId: string,
  qc: QueryClient = queryClient,
): ClientRoutineList[] | null => {
  const data = qc.getQueryData<ClientRoutineList[]>(
    QueryKey.ClientRoutines(clientId),
  );

  return data ?? null;
};

/**
 * Retrieves client routine list item from the cache
 * @param clientId  The client ID to whom the routines belong
 * @param routineId The ID of the routine
 * @returns         Client Routine
 */
export const getClientRoutineListItem = (
  clientId: string,
  routineId: string,
): ClientRoutineList | null => {
  const clientRoutines = getClientRoutines(clientId);

  return getDataById(clientRoutines, routineId);
};

/**
 * Get routine workout
 * @param routineId The ID of the routine
 * @param workoutId The ID of the workout we want
 * @param qc        QueryClient
 * @returns         Client Workout
 */
export const getClientRoutineWorkout = (
  routineId: null | string,
  workoutId: null | string,
  qc: QueryClient = queryClient,
): ClientWorkout | null => {
  if (routineId === null || workoutId === null) {
    return null;
  }

  const data = qc.getQueryData<ClientRoutineFull>(
    QueryKey.ClientRoutine(routineId),
  );

  return getDataById(data?.workouts, workoutId);
};
