import { updateDataById } from '../../../../../../utils/array';
import { ClientRoutineFull, ClientWorkout } from '../../../types';
import { getClientRoutineFull, getClientRoutines } from '../../cache/getters';
import { moveClientWorkout, setClientRoutineAll } from '../../cache/mutations';
import { cancelClientRoutineQuery } from '../../queries/useClientRoutineFullQuery';
import { cancelClientRoutinesQuery } from '../../queries/useClientRoutinesQuery';

interface Props {
  payload: Partial<ClientWorkout>;
  routineId: null | string;
  workoutId: string;
}

export const clientWorkoutOptimisticHandler = async ({
  payload,
  routineId,
  workoutId,
}: Props) => {
  if (routineId === null) {
    return;
  }

  const routine = getClientRoutineFull(routineId);

  if (routine === null) {
    return;
  }

  const routinesAll = getClientRoutines(routine.clientId);

  if (payload.position !== undefined && payload.cycleId !== undefined) {
    moveClientWorkout({
      cycleId: payload.cycleId,
      position: payload.position,
      routineId,
      workoutId,
    });
  } else {
    const updated: ClientRoutineFull = {
      ...routine,
      workouts: updateDataById(routine.workouts, workoutId, payload),
    };

    setClientRoutineAll(routineId, updated);
  }

  await Promise.all([
    cancelClientRoutineQuery(routineId),
    cancelClientRoutinesQuery(routine.clientId),
  ]);

  return { routine, routinesAll };
};
