import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import http from '../../../../../../config/http';
import { MutationDelete } from '../../../../../../constants/mutation-keys';
import { removeDataById } from '../../../../../../utils/array';
import {
  StandaloneTemplateWorkout,
  TemplateRoutineFull,
  TemplateRoutineList,
} from '../../../types';
import {
  getStandaloneTemplateWorkouts,
  getTemplateRoutineFull,
  getTemplateRoutines,
} from '../../cache/getters';
import { setTemplateRoutineAll } from '../../cache/mutations';
import { setStandaloneTemplateWorkouts } from '../../cache/setters';
import {
  cancelTemplateRoutineQuery,
  invalidateTemplateRoutine,
} from '../../queries/useTemplateRoutineFullQuery';
import {
  cancelTemplateRoutinesQuery,
  invalidateTemplateRoutines,
} from '../../queries/useTemplateRoutinesQuery';
import { invalidateTemplateWorkouts } from '../../queries/useTemplateWorkoutsQuery';

interface TContext {
  routine?: TemplateRoutineFull;
  routinesAll?: TemplateRoutineList[];
  templateWorkouts?: StandaloneTemplateWorkout[];
}

interface TApi {
  routineId: null | string;
  workoutId: string;
}

async function deleteWorkout({ workoutId }: TApi) {
  await http.delete(`/template-workouts/${workoutId}`);
}

export const useTemplateWorkoutDelete = () => {
  return useMutation<void, AxiosError, TApi, TContext | undefined>({
    mutationFn: deleteWorkout,
    mutationKey: [MutationDelete.TemplateWorkout],
    // onError: (_, { routineId }, context) => {
    //   if (context === undefined) {
    //     return;
    //   }
    //   const { routine, routinesAll } = context ?? {};
    //   if (routineId === null) {
    //     set;
    //   } else {
    //     setTemplateRoutineFull(routineId, routine);
    //   }
    //   if (routine) {
    //     setTemplateRoutines(routinesAll);
    //   }
    // },
    onMutate: async ({ routineId, workoutId }) => {
      if (routineId === null) {
        const templateWorkouts = getStandaloneTemplateWorkouts() ?? [];

        setStandaloneTemplateWorkouts(
          removeDataById(templateWorkouts, workoutId),
        );

        return { routine: undefined, routinesAll: undefined, templateWorkouts };
      } else {
        const routine = getTemplateRoutineFull(routineId);

        if (routine === null) {
          return;
        }

        await Promise.all([
          cancelTemplateRoutineQuery(routineId),
          cancelTemplateRoutinesQuery(),
        ]);

        const updated = {
          ...routine,
          workouts: removeDataById(routine.workouts, workoutId),
        };

        setTemplateRoutineAll(routineId, updated);

        const routinesAll = getTemplateRoutines() ?? [];

        return { routine, routinesAll, templateWorkouts: undefined };
      }
    },
    onSettled(_, __, { routineId }, _ctx) {
      if (routineId === null) {
        invalidateTemplateWorkouts();
      } else {
        invalidateTemplateRoutine(routineId);
        invalidateTemplateRoutines();
      }
    },
  });
};
