import { FC } from 'react';

import HeaderMobile from '../../../components/layout/HeaderMobile';
import { PageId } from '../../../constants/page-ids';
import SettingsUserProfileHeaderActions from '../../../features/user/components/SettingsUserProfileHeaderActions';
import useIsMobile from '../../../hooks/useIsMobile';
import { getPageLabel } from '../../../i18n/helpers/page';

const SettingsProfileLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={SettingsUserProfileHeaderActions}
        title={getPageLabel(PageId.SettingsProfile)}
      />
    );
  }
  return null;
};

export default SettingsProfileLayout;
