import { useMutation } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../config/http';
import {
  CreateBusinessFacilityPayload,
  Facility,
  UpdateBusinessFacilityPayload,
} from '../../../../types/business';
import { invalidateClients } from '../../../clients/api/queries/useClients';
import { invalidateBusiness } from '../queries/business';

interface FacilityId {
  id: string;
}

// Create
const createFacility = async (payload: CreateBusinessFacilityPayload) => {
  const { data } = await http.post<ApiResponse<Facility>>(
    '/facilities',
    payload,
  );

  return data.data;
};

export const useFacilityCreateMutation = () => {
  return useMutation({
    mutationFn: createFacility,
    onMutate(_variables) {},
    onSettled(_data, error, _variables, _context) {
      invalidateBusiness();
      if (error !== null) {
      } else {
      }
    },
  });
};

// Update
const patchFacility = async (
  payload: FacilityId & UpdateBusinessFacilityPayload,
) => {
  const { id, ...update } = payload;
  const { data } = await http.patch<ApiResponse<Facility>>(
    `/facilities/${id}`,
    update,
  );

  return data.data;
};

export const useFacilityUpdateMutation = () => {
  return useMutation({
    mutationFn: patchFacility,
    onMutate(_variables) {},
    onSettled(_data, error, _variables, _context) {
      invalidateBusiness();
      if (error !== null) {
      } else {
      }
    },
  });
};

// Delete
const deleteFacility = async (id: string) => {
  await http.delete(`/facilities/${id}`);
};

export const useFacilityDeleteMutation = () => {
  return useMutation({
    mutationFn: deleteFacility,
    onMutate(_variables) {},
    onSettled(_data, error, _variables, _context) {
      invalidateBusiness();
      invalidateClients();
      if (error !== null) {
      } else {
      }
    },
  });
};
