import { SearchParam } from '../../../router/types';
import { ModalId, useModal } from '../../../utils/modal';
import { useFacilityDeleteMutation } from '../api/mutations/facility';

export const useFacilityActions = () => {
  const { openModal } = useModal();
  const { isPending: isDeleting, mutateAsync: deleteFacility } =
    useFacilityDeleteMutation();
    
  const requestEdit = (facilityId: string) => {
    openModal(ModalId.BusinessFacilityEdit, {
      [SearchParam.EntityId]: facilityId,
    });
  };

  return { isDeleting, requestDelete: deleteFacility, requestEdit };
};
