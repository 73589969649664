import { FC } from 'react';

import BusinessSecondaryNavigation from '../../../../features/business/components/BusinessLayout/BusinessSecondaryNavigation';
import useIsMobile from '../../../../hooks/useIsMobile';

const FacilitiesRootPageLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  return <BusinessSecondaryNavigation />;
};

export default FacilitiesRootPageLayout;
