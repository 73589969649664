/**
 * @file contains helper functions for manipulating url search params
 */
import { Location } from 'react-router-dom';

import { IUrlSearchParams, SearchParam, SearchParamValues } from '../types';

export type ParamValue =
  | Array<number | string>
  | null
  | number
  | string
  | undefined;

const serializeUrl = (pathname: string, params: URLSearchParams) => {
  const val = params.toString();

  return val.length === 0 ? pathname : `${pathname}?${val}`;
};

const serializeParamValues = (values: ParamValue | unknown) => {
  const valuesArray = Array.isArray(values) ? values : [values];
  const filtered = valuesArray.filter(
    value => value !== undefined && value !== null,
  );

  return filtered.map(String);
};

/**
 * Get the provided search param value
 * @param paramKey The search param key
 * @param location History Location object (if not provided, we get search from window.location)
 * @returns        Search param value
 */
export const getSearchParam = <T extends SearchParam>(
  paramKey: T,
  location?: Location,
): null | SearchParamValues[T] => {
  const search = location?.search ?? window.location?.search;
  const params = new URLSearchParams(search);
  const value = params.get(paramKey);

  if (value === null) {
    return null;
  }

  return decodeURIComponent(value) as SearchParamValues[T];
};

/**
 * Get the provided search param for array based params
 * @param paramKey The search param key
 * @param location History Location object (if not provided, we get search from window.location)
 * @returns        Array of values from param
 */
export const getSearchParamAll = <T extends SearchParam>(
  paramKey: T,
  location?: Location,
): string[] => {
  const search = location?.search ?? window.location?.search;
  const params = new URLSearchParams(search);
  const value = params.getAll(String(paramKey));

  return value;
};

/**
 * Appends param with values to current url. Duplicates are ignored, only unique
 * values get appended
 * @param config       The object of param keys and values to be appended
 * @param config.key   The search param key
 * @param config.value values to append to url
 * @param location     History Location object (if not provided, we get search from window.location)
 * @returns            The modified url
 */
export const appendParamValuesToUrl = <T extends SearchParam>(
  config: Partial<Record<T, ParamValue>>,
  location?: Location,
): string => {
  const { pathname, search } = location ?? window.location;
  const params = new URLSearchParams(search);

  Object.entries(config).forEach(([paramKey, values]) => {
    const paramValuesToAppend = serializeParamValues(values);

    paramValuesToAppend.forEach(paramValue => {
      if (params.has(paramKey, paramValue) === false) {
        params.append(paramKey, paramValue);
      }
    });
  });

  return serializeUrl(pathname, params);
};

/**
 * Removes params from url by value
 * @param config       The params and values to be removed
 * @param config.key   The search param key
 * @param config.value values to remove from url
 * @param location     History Location object (if not provided, we get search from window.location)
 * @returns            The modified url
 */
export const removeParamValuesFromUrl = <T extends SearchParam>(
  config: Partial<Record<T, ParamValue>>,
  location?: Location,
): string => {
  const { pathname, search } = location ?? window.location;
  const params = new URLSearchParams(search);

  Object.entries(config).forEach(([paramKey, values]) => {
    const paramValuesToRemove = serializeParamValues(values);

    paramValuesToRemove.forEach(paramValue =>
      params.delete(paramKey, paramValue),
    );
  });

  return serializeUrl(pathname, params);
};

/**
 * Replaces or adds params to the current URL based on the location
 * @param paramsObj an object containing key-value pairs of search parameters to replace/add
 * @param location  location object from the history
 * @returns         a string in a URL format
 */
export const replaceOrAddParamsToUrl = (
  paramsObj: Partial<Record<SearchParam, ParamValue>>,
  location?: Pick<Location, 'pathname' | 'search'>,
): string => {
  const { pathname, search } = location ?? window.location;
  const params: IUrlSearchParams = new URLSearchParams(search);

  Object.entries(paramsObj).forEach(([key, values]) => {
    const currentParam = params.get(key);

    const valuesArray = serializeParamValues(values);

    if (currentParam === null) {
      valuesArray?.forEach(value => params.append(key, value));
    } else {
      params.set(key, encodeURIComponent(String(valuesArray)));
    }
  });

  return serializeUrl(pathname, params);
};

/**
 * Removes params from the current URL based on the location
 * @param location  location object from the history
 * @param paramsObj an object containing key-value pairs of search parameters to remove
 * @returns         a string in a URL format
 */
export const removeParamsFromUrl = (
  location: Location,
  paramsObj: Partial<Record<SearchParam, string | string[]>>,
): string => {
  const { pathname, search } = location ?? window.location;
  const params: IUrlSearchParams = new URLSearchParams(search);

  Object.entries(paramsObj).forEach(([key, values]) => {
    const currentParams = params.get(key);

    const valuesArr = serializeParamValues(values);

    if (currentParams) {
      const newParams = currentParams
        .split(',')
        .filter(param => !valuesArr.includes(param as SearchParam))
        .join(',');

      if (newParams) {
        params.set(key, newParams);
      } else {
        params.delete(key);
      }
    }
  });

  return serializeUrl(pathname, params);
};

/**
 * Removes params from the current URL based on the location
 * @param keys     param keys that we want to remove
 * @param location location object from the history
 * @returns        a string in a URL format
 */
export const removeParamsKeysFromUrl = (
  keys: SearchParam[],
  location?: Location,
): string => {
  const { pathname, search } = location ?? window.location;
  const params: IUrlSearchParams = new URLSearchParams(search);

  keys.forEach(key => {
    params.delete(key);
  });

  if (Array.from(params).length === 0) {
    return pathname;
  }

  return serializeUrl(pathname, params);
};

/**
 * Parses URL string
 * @param routerUrl a partial url string (React Router type, without http)
 * @returns         a tuple of pathname and search string
 */
export const parseUrlString = (routerUrl: string): [string, string] => {
  const [pathname, searchString] = routerUrl.split('?');

  const search = searchString ? `?${searchString}` : '';
  return [pathname, search];
};

interface Configuration {
  params?: Partial<Record<SearchParam, string | string[]>>;
  paramsToRemove?: SearchParam[];
}

/**
 * Modifies the URL based on the location by adding/replace-ing existing params and removing params
 * @param location               History location object
 * @param options                Object containing params to be set, replaced or removed
 * @param options.params         Params to be set or replaced
 * @param options.paramsToRemove Param keys we want to remove from the url
 * @returns                      a string in a URL format
 */
export const modifyUrlParams = (location: Location, options: Configuration) => {
  const { pathname, search } = location;
  const params: IUrlSearchParams = new URLSearchParams(search);

  const { params: paramsObj, paramsToRemove } = options ?? {};

  if (paramsObj) {
    Object.entries(paramsObj).forEach(([key, values]) => {
      const currentParam = params.get(key);

      const valuesArray = serializeParamValues(values);

      if (currentParam === null) {
        params.append(key, valuesArray.join(','));
      } else {
        params.set(key, valuesArray.join(','));
      }
    });
  }

  if (paramsToRemove) {
    paramsToRemove.forEach(key => {
      params.delete(key);
    });
  }

  return serializeUrl(pathname, params);
};

/**
 * Checks wether provided params exist and match in the URL based on the location
 * @param location  History location object
 * @param paramsObj Object containing search param key value pairs
 * @returns         Wether the params match
 */
export const hasMatchingParams = (
  location: Location,
  paramsObj: Partial<Record<SearchParam, string | string[]>>,
) => {
  const { search } = location;
  const params: URLSearchParams = new URLSearchParams(search);

  return Object.entries(paramsObj).every(([param, providedP]) => {
    const p = params.get(param);
    return p !== null && providedP !== undefined && p === providedP.toString();
  });
};
