import { setTemplateRoutineFull, setTemplateRoutines } from '../cache/setters';
import { invalidateTemplateRoutine } from '../queries/useTemplateRoutineFullQuery';
import { invalidateTemplateRoutines } from '../queries/useTemplateRoutinesQuery';
import { TemplateRoutineMutationContext } from './types';

export const templateRoutineMutationSettledHandler = (
  routineId: string,
  _: TemplateRoutineMutationContext,
) => {
  invalidateTemplateRoutine(routineId);
  invalidateTemplateRoutines();
};

export const templateRoutineMutationErrorHandler = (
  routineId: string,
  context: TemplateRoutineMutationContext,
) => {
  if (context === undefined) {
    return;
  }

  const { routine, routinesAll } = context as TemplateRoutineMutationContext;

  setTemplateRoutineFull(routineId, routine);
  setTemplateRoutines(routinesAll);
};
