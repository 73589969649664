import { useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../config/http';
import { queryClient } from '../../../../config/react-query';
import {
  QueryKey,
  QueryRefetchOptions,
} from '../../../../constants/react-query';
import { ClientListItem } from '../../../../types/clients';
import { getFullName } from '../../helpers';

async function fetchClients(): Promise<ClientListItem[]> {
  const { data } = await http.get<ApiResponse<ClientListItem[]>>('/clients');
  return data.data.sort((a, b) => {
    const aFullName = getFullName(a);
    const bFullName = getFullName(b);

    return aFullName.localeCompare(bFullName);
  });
}

export const useClients = (options?: QueryRefetchOptions) => {
  return useQuery({
    queryFn: fetchClients,
    queryKey: QueryKey.Clients(),
    ...options,
  });
};

export const invalidateClients = () =>
  queryClient.invalidateQueries({ queryKey: QueryKey.Clients() });

export const cancelClientsQuery = () =>
  queryClient.cancelQueries({ queryKey: QueryKey.Clients() });
