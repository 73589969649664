import { Location } from 'react-router-dom';

import { getCurrentStorageUser } from '../../features/user/utils';
import { PATH_FRAGMENT__CLIENTS } from '../paths/path-fragments';
import { routes } from '../routes';

/**
 * Get the url for routine details. The coach has also his routines, so we
 * use /me for the current user and /clients/{id} for clients as initial pathname
 * @param routineId Id of the routine
 * @param clientId  Id of the client
 * @returns         String in URL format
 */
export const getRoutineDetailsUrl = (
  routineId: string,
  clientId: string,
): string => {
  const user = getCurrentStorageUser();

  if (clientId !== user?.id) {
    return routes.clients.routines(clientId, routineId);
  }

  return routes.me.routine(routineId);
};

export const getRelativeUrlClientFull = (
  clientId: string,
  location?: Location,
) => {
  const { pathname } = location ?? window.location;

  const fragments = pathname.split('/');
  // /clients/:clientId
  // We want to replace clientId
  const potentialClientId = fragments.pop();

  const isClientsPath =
    potentialClientId !== undefined &&
    `/${fragments.at(-1)}` === PATH_FRAGMENT__CLIENTS;

  if (isClientsPath) {
    return fragments.join('/').concat('/', clientId);
  }

  if (pathname.endsWith(PATH_FRAGMENT__CLIENTS)) {
    // /clients root path
    return pathname.concat('/', clientId);
  }

  // /any path. /clients/:clientId will be added
  return pathname.concat(PATH_FRAGMENT__CLIENTS, '/', clientId);
};
