import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useCurrentPath from '../router/hooks/useCurrentPath';
import {
  getBackUrl as _getBackUrl,
  GetBackUrlOptions,
} from '../router/paths/back-paths';

const useBackNavigation = () => {
  const currentPath = useCurrentPath();
  const location = useLocation();
  const navigate = useNavigate();

  const getBackUrl = useCallback(
    (options?: GetBackUrlOptions) =>
      _getBackUrl(currentPath, location, options),
    [currentPath, location],
  );

  const goBack = useCallback(
    (options?: GetBackUrlOptions, replace: boolean = false) => {
      navigate(getBackUrl(options), { replace });
    },
    [getBackUrl, navigate],
  );

  return { getBackUrl, goBack };
};

export default useBackNavigation;
