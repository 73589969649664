import { Location } from 'react-router-dom';

import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { translate } from '../../i18n';
import { getBackUrl } from '../paths/back-paths';
import { PATH_FRAGMENT__WORKOUT_ID } from '../paths/path-fragments';

export const getBreadcrumbWorkoutFull = <
  T extends { id: string; name: string },
>(
  workout: null | T | undefined = null,
  currentPath: null | string,
  location: Location | null = null,
): BreadcrumbItem => {
  return {
    content: workout?.name,
    href: getBackUrl(currentPath, location, {
      toPathFragment: PATH_FRAGMENT__WORKOUT_ID,
    }),
    isLoading: workout === null,
    label: translate('workout.workouts_one'),
  };
};
