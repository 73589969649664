import { useMutation } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../../config/http';
import { isSuperAdmin } from '../../../../user/utils';
import { Exercise } from '../../../types';
import { upsertExercise } from '../cache/mutations';
import {
  cancelExercisesQuery,
  invalidateExercises,
} from '../queries/useExercises';

export async function duplicateExercise(exerciseId: string): Promise<Exercise> {
  const {
    data: { data: exercise },
  } = await http.get(`/exercises/${exerciseId}`);

  const newExercise = exercise;

  const fields: Array<keyof typeof newExercise> = [
    'id',
    'createdAt',
    'updatedAt',
    'deletedAt',
  ];

  if (newExercise.isPublic === true && isSuperAdmin() === false) {
    fields.push('isPublic');
  }

  fields.forEach(field => delete newExercise[field]);

  const { data } = await http.post<ApiResponse<Exercise>>('/exercises', {
    ...newExercise,
    name: `${newExercise.name} (copy)`,
  });
  return data.data;
}

export const useExerciseDuplicate = () => {
  return useMutation({
    mutationFn: duplicateExercise,
    mutationKey: ['ExerciseDuplicate'],
    onMutate() {
      cancelExercisesQuery();
    },
    onSettled() {
      invalidateExercises();
    },
    onSuccess: exercise => {
      upsertExercise(exercise);
    },
  });
};
