import { Location } from 'react-router-dom';

import { RoutineStatus } from '../features/routine/@client/types';
import { ExerciseCategory } from '../features/routine/types';
import { ErrorParam, ProxyParam } from '../types';
import { PaddleProductType } from '../types/product.types';
import {
  PATH__BUSINESS,
  PATH__BUSINESS__ADMINS,
  PATH__BUSINESS__CLIENTS,
  PATH__BUSINESS__COACHES,
  PATH__BUSINESS__FACILITIES,
  PATH__ERROR,
  PATH__ONBOARDING,
  PATH__PROXY,
  PATH__SETTINGS,
  PATH__SETTINGS__PROFILE,
  PATH__SETTINGS__SUBSCRIPTIONS,
} from './paths/paths';
import { SearchParam } from './types';

const getStringWithParams = (baseUrl: string, email: string) => {
  let base = String(baseUrl);

  const params = new URLSearchParams();

  if (email) {
    params.append(SearchParam.Email, email);
    return `${base}?${params.toString()}`;
  }

  return base;
};

const getByRoutineStatus = (routineStatus?: RoutineStatus) =>
  `${
    routineStatus !== undefined
      ? `?${SearchParam.RoutineStatus}=${routineStatus}`
      : ''
  }`;

const routes = {
  auth: {
    forgotPassword: () => '/auth/forgot-password',
    newPassword: (email = '') => {
      return getStringWithParams('/auth/new-password', email);
    },
    resetPassword: () => `/auth/reset-password`,
    signIn: (email = '') => {
      return getStringWithParams('/auth/sign-in', email);
    },
    signUp: () => '/auth/sign-up',
    verifyCode: () => '/auth/verify',
  },
  business: {
    admins: {
      byId: (userId: string) => `/business/admins/${userId}`,
      root: () => PATH__BUSINESS__ADMINS,
    },
    clients: {
      byId: (userId: string) => `/business/clients/${userId}`,
      root: () => PATH__BUSINESS__CLIENTS,
      routines: {
        byId: (userId: string, routineId: string) =>
          `/business/clients/${userId}/routines/${routineId}`,
        workouts: {
          byId: (userId: string, routineId: string, workoutId: string) =>
            `/business/clients/${userId}/routines/${routineId}/workouts/${workoutId}`,
        },
      },
    },
    coaches: {
      byId: (userId: string) => `/business/coaches/${userId}`,
      clients: {
        byId: (coachId: string, clientId: string) =>
          `/business/coaches/${coachId}/clients/${clientId}`,
        routines: {
          byId: (coachId: string, clientId: string, routineId: string) =>
            `/business/coaches/${coachId}/clients/${clientId}/routines/${routineId}`,
        },
      },
      root: () => PATH__BUSINESS__COACHES,
    },
    facilities: {
      byId: (facilityId: string) => `/business/facilities/${facilityId}`,
      root: () => PATH__BUSINESS__FACILITIES,
    },
    root: () => PATH__BUSINESS,
  },
  checkout: {
    byPriceId: (priceId: string) => `/checkout/${priceId}`,
  },
  clients: {
    byId: (id: string, routineStatus?: RoutineStatus) =>
      `/clients/${id}${getByRoutineStatus(routineStatus)}`,
    root: () => '/clients',
    routines: (clientId: string, routineId: string, workoutId?: string) =>
      `/clients/${clientId}/routines/${routineId}${workoutId ? '#workout-' + workoutId : ''}`,
    workouts: (clientId: string, routineId: string, workoutId: string) =>
      `/clients/${clientId}/routines/${routineId}/workouts/${workoutId}`,
  },
  error: {
    byErrorType: (errorType: ErrorParam) => `${PATH__ERROR}/${errorType}`,
    root: () => PATH__ERROR,
  },
  home: '/home',
  library: {
    exercises: {
      byId: (id: string) => `/library/exercises/${id}`,
      root: (category: ExerciseCategory) =>
        `/library/exercises?${SearchParam.ExercisesCategory}=${category}`,
    },
    root: () => '/library',
    routines: {
      byId: (id: string) => `/library/routines/${id}`,
      root: () => '/library/routines',
      workouts: (routineId: string, workoutId: string) =>
        `/library/routines/${routineId}/workouts/${workoutId}`,
    },
    workouts: {
      byId: (id: string) => `/library/workouts/${id}`,
      root: () => '/library/workouts',
    },
  },
  me: {
    root: (routineStatus?: RoutineStatus) =>
      `/me${getByRoutineStatus(routineStatus)}`,
    routine: (routineId: string, workoutId?: string) =>
      `/me/routines/${routineId}${workoutId ? '#workout-' + workoutId : ''}`,
    workouts: (routineId: string, workoutId: string) =>
      `/me/routines/${routineId}/workouts/${workoutId}`,
    workoutsDetails: (routineId: string, workoutId: string) =>
      `/me/routines/${routineId}/workouts/${workoutId}/details`,
  },
  more: {
    root: () => '/more',
  },
  onboarding: {
    byProductType: (productType: PaddleProductType) =>
      `${PATH__ONBOARDING}/${productType}`,
    root: () => PATH__ONBOARDING,
  },
  proxy: {
    checkoutSuccess: (priceId: string) =>
      `${PATH__PROXY}/${ProxyParam.CheckoutSuccess}?${SearchParam.EntityId}=${priceId}`,
    root: () => PATH__PROXY,
  },
  settings: {
    profile: () => PATH__SETTINGS__PROFILE,
    root: () => PATH__SETTINGS,
    subscriptions: () => PATH__SETTINGS__SUBSCRIPTIONS,
  },
};

export const createLocationState = (location?: Location) => {
  const { hash, pathname, search } = location ?? window.location;

  return {
    from: `${pathname}${search ? '?' + search : ''}${hash ? '#' + hash : ''}`,
  };
};

export { routes };
