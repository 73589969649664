import { InvalidateQueryFilters, useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../../config/http';
import { queryClient } from '../../../../../config/react-query';
import { QueryKey } from '../../../../../constants/react-query';
import { getDataById } from '../../../../../utils/array';
import { StandaloneTemplateWorkout } from '../../types';

async function getAll() {
  const { data } =
    await http.get<ApiResponse<StandaloneTemplateWorkout[]>>(
      '/template-workouts',
    );
  return data.data;
}

interface Args {
  enabled?: boolean;
}

export const useTemplateWorkoutsQuery = (arg?: Args) => {
  return useQuery({
    enabled: arg?.enabled,
    queryFn: getAll,
    queryKey: QueryKey.TemplateWorkouts(),
  });
};

export const useTemplateWorkoutQuery = (id: null | string | undefined) => {
  const query = useQuery({
    enabled: id !== null && id !== undefined,
    queryFn: getAll,
    queryKey: QueryKey.TemplateWorkouts(),
  });

  return { ...query, data: getDataById(query.data, id) };
};

export const invalidateTemplateWorkouts = (filters?: InvalidateQueryFilters) =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.TemplateWorkouts(),
    ...filters,
  });

export const cancelTemplateWorkoutsQuery = () =>
  queryClient.cancelQueries({
    queryKey: QueryKey.TemplateWorkouts(),
  });
