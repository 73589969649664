import { ActionIcon, ActionIconProps, Tooltip } from '@mantine/core';
import { IconSquareChevronDown } from '@tabler/icons-react';
import { ComponentProps, FC, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { translate } from '../../../i18n';
import { createLocationState } from '../../../router/routes';
import CustomMenu from '../../ui/menus/CustomMenu';
import { iconProps, iconsMap, iconStyle } from './config';
import { getActionIconProps } from './helpers';

interface CommonProps extends ActionIconProps {
  colorVariant?: 'dark' | 'light' | 'primary';
  tooltip?: null | string;
}

interface ActionIconLinkProps extends CommonProps {
  url: string;
}

interface ActionIconButtonProps extends CommonProps {
  onClick?: () => void;
  type?: 'button' | 'submit';
}

export type ActionIconName = keyof typeof iconsMap;

export const ActionIconLink = ({
  colorVariant = 'light',
  icon,
  tooltip = iconsMap[icon].tooltip,
  url,
  ...props
}: { icon: ActionIconName } & ActionIconLinkProps) => {
  const { Icon } = iconsMap[icon];
  const content = (
    <ActionIcon
      component={Link}
      to={url}
      {...getActionIconProps(colorVariant)}
      aria-label={tooltip ? tooltip : undefined}
      state={createLocationState()}
      {...props}
    >
      <Icon {...iconProps} />
    </ActionIcon>
  );

  return tooltip ? (
    <Tooltip keepMounted={false} label={tooltip}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export const ActionIconButton = forwardRef<
  HTMLDivElement,
  { icon: ActionIconName } & ActionIconButtonProps
>((props, ref) => {
  const {
    colorVariant = 'light',
    icon,
    onClick,
    tooltip = iconsMap[icon].tooltip,
    ...otherProps
  } = props;
  const { Icon } = iconsMap[icon];

  const content = (
    <ActionIcon
      onClick={onClick}
      {...getActionIconProps(colorVariant)}
      {...otherProps}
      aria-label={tooltip ? tooltip : undefined}
    >
      <Icon {...iconProps} />
    </ActionIcon>
  );

  return tooltip ? (
    <Tooltip keepMounted={false} label={tooltip} ref={ref}>
      {content}
    </Tooltip>
  ) : (
    content
  );
});

interface ActionIconMoveProps extends CommonProps {
  menuItems: ComponentProps<typeof CustomMenu>['menuItems'];
}

export const ActionIconMove = ({
  colorVariant,
  menuItems,
  tooltip = translate('common.move'),
  ...props
}: ActionIconMoveProps) => {
  return (
    <CustomMenu
      menuItems={menuItems}
      tooltip={tooltip ?? undefined}
      trigger={
        <ActionIconButton
          aria-label={tooltip}
          colorVariant={colorVariant}
          icon="move"
          tooltip={tooltip}
          {...props}
        />
      }
    />
  );
};

interface ActionIconCollapseProps extends ActionIconButtonProps {
  isOpen: boolean;
}

export const ActionIconCollapse = ({
  colorVariant = 'light',
  isOpen,
  ...props
}: ActionIconCollapseProps & CommonProps) => {
  const label = isOpen
    ? translate('common.show_less')
    : translate('common.show_more');
  return (
    <Tooltip keepMounted={false} label={label}>
      <ActionIcon
        {...getActionIconProps(colorVariant)}
        {...props}
        aria-label={label}
      >
        <IconSquareChevronDown
          stroke={iconProps.stroke}
          style={{
            ...iconStyle,
            transform: isOpen ? 'rotate(180deg)' : undefined,
          }}
        />
      </ActionIcon>
    </Tooltip>
  );
};

export const ActionIconHeaderActions: FC<{
  label?: string;
  loading?: boolean;
}> = ({ label, loading }) => {
  return (
    <ActionIconButton
      colorVariant="dark"
      icon="dotsHorizontal"
      loading={loading}
      tooltip={label ?? null}
      variant="subtle"
    />
  );
};
