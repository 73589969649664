import { CommonExerciseInstruction } from '../types';
import { StandaloneTemplateWorkout, TemplateRoutineWorkout } from './types';

export const getIsStandaloneTemplateWorkout = (
  workout: Pick<
    StandaloneTemplateWorkout | TemplateRoutineWorkout,
    'cycleId' | 'position' | 'routineId'
  >,
): workout is StandaloneTemplateWorkout => {
  const { cycleId, position, routineId } = workout;

  return routineId === null && cycleId === null && position === null;
};

/**
 * When we move the group up or down, we need to update other exercise instructions.
 * Here we want to specifically check whether we should decrement the groupId by 1.
 * @param groupId             The group we want to move
 * @param destinationGroupId  The destination where we want to move it
 * @param exerciseInstruction Exercise instruction object
 * @returns                   Whether we should decrement the groupId
 */
export const getShouldDecrementGroupId = (
  groupId: number,
  destinationGroupId: number,
  exerciseInstruction: CommonExerciseInstruction,
) => {
  return (
    exerciseInstruction.groupId > groupId &&
    exerciseInstruction.groupId <= destinationGroupId
  );
};

/**
 * When we move the group up or down, we need to update other exercise instructions.
 * Here we want to specifically check whether we should increment the groupId by 1.
 * @param groupId             The group we want to move
 * @param destinationGroupId  The destination where we want to move it
 * @param exerciseInstruction Exercise instruction object
 * @returns                   Whether we should increment the groupId
 */
export const getShouldIncrementGroupId = (
  groupId: number,
  destinationGroupId: number,
  exerciseInstruction: CommonExerciseInstruction,
) => {
  return (
    exerciseInstruction.groupId < groupId &&
    exerciseInstruction.groupId >= destinationGroupId
  );
};
