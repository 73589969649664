import { RoutineCycle } from '../types';

export const getRoutineDurationDays = <T extends { restHours: number }>(
  workouts: T[],
) => {
  return Math.round(
    workouts.reduce((current, workout) => current + workout.restHours, 0) / 24,
  );
};

export const getPreviousWorkout = <
  W extends { cycleId: number; name: string; position: number },
  T extends { workouts: W[] },
>(
  routine: null | T,
  workout: W,
) => {
  if (routine === null) {
    return null;
  }

  return (
    routine.workouts.findLast(workoutItem => {
      if (workoutItem.name !== workout.name) {
        return false;
      }

      return (
        workoutItem.cycleId < workout.cycleId ||
        workoutItem.position < workout.position
      );
    }) ?? null
  );
};


/**
 * Groups the workouts by cycle
 * @param routine Routine details object
 * @returns       Mapped workouts by cycle
 */
export const getCycleWorkouts = <
  W extends { cycleId: number; name: string; position: number },
  T extends { cycles: RoutineCycle[]; workouts: W[] },
>(
  routine: T | undefined,
): null | Record<number, T['workouts']> => {
  if (routine === undefined) {
    return null;
  }

  return routine.cycles.reduce(
    (mapped, _, i) => {
      mapped[i] = routine.workouts.filter(wo => wo.cycleId === i);
      return mapped;
    },
    {} as Record<number, T['workouts']>,
  );
};

export const isRoutineCycleOrderChanged = <
  W extends { cycleId: number },
  T extends { cycles: RoutineCycle[]; workouts: W[] },
>(
  routine: T,
  cycles: RoutineCycle[] | undefined,
) => {
  if (cycles === undefined || routine.cycles.length !== cycles.length) {
    return false;
  }

  return (
    routine.cycles.every((cycle, i) => cycle.id === cycles?.[i].id) === false
  );
};

/**
 * In order to know which workout.cycleId we need to update, we map the cycles
 * that changed order.
 * @param routine Current routine
 * @param cycles  Updated cycles
 * @returns       Mapped cycle position {oldPosition: newPosition}
 */
export const getChangedCyclePositions = <
  W extends { cycleId: number },
  T extends { cycles: RoutineCycle[]; workouts: W[] },
>(
  routine: T,
  cycles: RoutineCycle[] | undefined,
) => {
  // original position: new position
  const map: Record<number, number> = {};

  if (cycles === undefined) {
    return map;
  }

  routine.cycles.forEach((cycle, i) => {
    // Position changed
    if (cycle.id !== cycles[i]?.id) {
      map[i] = cycles.findIndex(editedCycle => cycle.id === editedCycle.id);
    }
  });

  return map;
};
